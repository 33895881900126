import {WinningBidFeesInMoney} from './types';
import {FeePriceDto} from '../dtos/FeePriceDto';
import {StorageTaxCalculator} from './storageTaxCalculator/StorageTaxCalculator';
import {FeesBreakdownCalculator} from './FeesBreakdownCalculator';
import {PercentPlatformFeeCalculator, PlatformFeeType} from './platformFeeCalculator/PercentPlatformFeeCalculator';
import {PercentBuyerFeeCalculator} from './PercentBuyerFeeCalculator';
import {SalesTaxCalculator} from './SalesTaxCalculator';
import {AdminFeeCalculator} from './AdminFeeCalculator';
import {Money} from '../rules/Money';
import {PerVehicleFee} from './PerVehicleFee';

/**
 *
 * bid = (X + af + X * bpf + fp) * (1 + pf)
 * X = ((bid/(1+pf)) - af - fp)/(1 + bpf)
 *
 * -- isPlatformFeeTakesOfSellerFee=true --
 * bid = (X + af + X*bpf + fp) * (1 + pf) + X*stPerc + storageTax(X)
 * -> bid = (X + af + X*bpf + fp) * (1 + pf) + (X + af + X * bpf + fp)⋅stPerc + storageTax(X)
 * if X >= total_unpaid_bill
 * bid = (X + af + X * bpf + fp) * (1 + pf) + (X + af + X * bpf + fp) * stPerc + impoundStorageTax
 * // calculate new
 * bid = (X + X * bpf + af + fp) * (1 + pf) + (X + X * bpf + af + fp) * stPerc + impoundStorageTax
 * bid = (X + X * bpf) * (1 + pf) + (af + fp) * (1 + pf) + (X + X * bpf) * stPerc + (af + fp) * stPerc + impoundStorageTax
 * bid = (X + X * bpf) * (1 + pf) + (X + X * bpf) * stPerc + (af + fp) * (1 + pf) + (af + fp) * stPerc + impoundStorageTax
 * bid = (X + X * bpf) * (1 + pf + stPerc) + (af + fp) * (1 + pf + stPerc) + impoundStorageTax
 * bid - ((af + fp) * (1 + pf + stPerc) + impoundStorageTax) = (X + X * bpf) * (1 + pf + stPerc)
 * bid - ((af + fp) * (1 + pf + stPerc) + impoundStorageTax) = X * (1 + bpf) * (1 + pf + stPerc)
 * X * (1 + bpf) * (1 + pf + stPerc) = bid - ((af + fp) * (1 + pf + stPerc) + impoundStorageTax)
 * X = (bid - ((af + fp) * (1 + pf + stPerc) + impoundStorageTax)) / (1 + bpf) * (1 + pf + stPerc)
 *
 * // final result
 * X = (bid - ((af + fp) * (1 + pf + stPerc) + impoundStorageTax)) / (1 + bpf) * (1 + pf + stPerc)
 *
 * // old
 * bid = (X + af + X*bpf + fp) * (1 + pf) + X * stPerc + impoundStorageTax
 * X = (bid - ((af + fp) * (1 + pf) + impoundStorageTax) / ((1 + pf)*(1 + bpf) + stPerc)
 * if X < total_unpaid_bill
 * bid = (X + af + X*bpf + fp) * (1 + pf) + (X + af + X * bpf + fp) * stPerc + X * impoundStorageTax/total_unpaid_bill
 * // calculate new
 * bid = (X + X * bpf + af + fp) * (1 + pf) + (X + X * bpf + af + fp) * stPerc + X * impoundStorageTax/total_unpaid_bill
 * bid = (X + X * bpf) * (1 + pf) + (af + fp) * (1 + pf) + (X + X * bpf) * stPerc + (af + fp) * stPerc + X * impoundStorageTax/total_unpaid_bill
 * bid = (X + X * bpf) * (1 + pf) + (X + X * bpf) * stPerc + X * impoundStorageTax/total_unpaid_bill + (af + fp) * (1 + pf) + (af + fp) * stPerc
 * bid = (X + X * bpf) * (1 + pf + stPerc) + X * impoundStorageTax/total_unpaid_bill + (af + fp) * (1 + pf + stPerc)
 * bid - ((af + fp) * (1 + pf + stPerc)) = (X + X * bpf) * (1 + pf + stPerc) + X * impoundStorageTax/total_unpaid_bill
 * (X + X * bpf) * (1 + pf + stPerc) + X * impoundStorageTax/total_unpaid_bill = bid - ((af + fp) * (1 + pf + stPerc))
 * X * (1 + bpf) * (1 + pf + stPerc) + X * impoundStorageTax/total_unpaid_bill = bid - ((af + fp) * (1 + pf + stPerc))
 * X * ((1 + bpf) * (1 + pf + stPerc) + impoundStorageTax/total_unpaid_bill) = bid - ((af + fp) * (1 + pf + stPerc))
 * X = (bid - ((af + fp) * (1 + pf + stPerc))) / ((1 + bpf) * (1 + pf + stPerc) + impoundStorageTax/total_unpaid_bill)
 *
 * // final result
 * X = (bid - (af + fp) * (1 + pf + stPerc)) / ((1 + bpf) * (1 + pf + stPerc) + impoundStorageTax/total_unpaid_bill)
 *
 * // old
 * X = (bid - (af + fp)*(1 + pf))/((1 + pf)*(1 + bpf) + stPerc + impoundStorageTax/total_unpaid_bill)
 *
 * pfValue = (X + af + X * bpf + fp) * pf
 *
 * bid = (X + af + X * bpf + fp) * (1 + pf) + X * stPerc
 * bid = (X + X * bpf + af + fp) * (1 + pf) + X * stPerc
 * bid = (X * (1 + bpf) + af + fp) * (1 + pf) + X * stPerc
 * bid = X * (1 + pf) * (1 + bpf) + (af + fp) * (1 + pf) + X * stPerc
 * bid = X * (1 + pf) * (1 + bpf) + X * stPerc + (af + fp) * (1 + pf)
 * bid = X * ((1 + pf) * (1 + bpf) + stPerc) + (af + fp) * (1 + pf)
 * X * ((1 + pf) * (1 + bpf) + stPerc) = bid - (af + fp) * (1 + pf)
 * X = (bid - (af + fp) * (1 + pf)) / ((1 + pf) * (1 + bpf) + stPerc)
 * X = (250 - (50 + 0) * (1 + 0.15)) / ((1 + 0.15) * (1 + 0.15) + 0)
 *
 * -- isPlatformFeeTakesOfSellerFee=false --
 * bid = (X + af + X * bpf + fp) + (X) * pfPerc + X*stPerc + storageTax(X)
 * if X >= total_unpaid_bill
 * bid = (X + af + X * bpf + fp) + (X) * pfPerc + (X + af + X * bpf + fp) * stPerc + impoundStorageTax
 * // calculate new
 * bid = (X + X * bpf + af + fp) + (X) * pfPerc + (X + X * bpf + af + fp) * stPerc + impoundStorageTax
 * bid = (X + X * bpf) + (af + fp) + (X) * pfPerc + (X + X * bpf) * stPerc + (af + fp) * stPerc + impoundStorageTax
 * bid = (X + X * bpf) + (X) * pfPerc + (X + X * bpf) * stPerc + impoundStorageTax + (af + fp) + (af + fp) * stPerc
 * bid = (X + X * bpf) * (1 + stPerc) + (X) * pfPerc + impoundStorageTax + (af + fp) * (1 + stPerc)
 * bid = X * (1 + bpf) * (1 + stPerc) + (X) * pfPerc + impoundStorageTax + (af + fp) * (1 + stPerc)
 * bid = X * ((1 + bpf) * (1 + stPerc) + pfPerc) + impoundStorageTax + (af + fp) * (1 + stPerc)
 * bid - ((af + fp) * (1 + stPerc) + impoundStorageTax) = X * ((1 + bpf) * (1 + stPerc) + pfPerc)
 * X * ((1 + bpf) * (1 + stPerc) + pfPerc) = bid - ((af + fp) * (1 + stPerc) + impoundStorageTax)
 * X = (bid - ((af + fp) * (1 + stPerc) + impoundStorageTax)) / ((1 + bpf) * (1 + stPerc) + pfPerc)
 *
 * // final result
 * X = (bid - ((af + fp) * (1 + stPerc) + impoundStorageTax)) / ((1 + bpf) * (1 + stPerc) + pfPerc)
 *
 * // old
 * X = (bid - (af + fp) - impoundStorageTax)/(1 +  bpf + pfPerc + stPerc)
 * if X < total_unpaid_bill
 * bid = (X + af + X * bpf + fp) + (X) * pfPerc + (X + af + X * bpf + fp) * stPerc + X * impoundStorageTax/total_unpaid_bill
 * // calculate new
 * bid = (X + X * bpf + af + fp) + (X) * pfPerc + (X + X * bpf + af + fp) * stPerc + X * impoundStorageTax/total_unpaid_bill
 * bid = (X + X * bpf) + (af + fp) + (X) * pfPerc + (X + X * bpf) * stPerc + (af + fp) * stPerc + X * impoundStorageTax/total_unpaid_bill
 * bid = (X + X * bpf) + X * pfPerc + (X + X * bpf) * stPerc + X * impoundStorageTax/total_unpaid_bill + (af + fp) + (af + fp) * stPerc
 * bid = X * (1 + bpf) + X * pfPerc + X * (1 + bpf) * stPerc + X * impoundStorageTax/total_unpaid_bill + (af + fp) * (1 + stPerc)
 * bid = X * (1 + bpf) + X * (1 + bpf) * stPerc + X * pfPerc  + X * impoundStorageTax/total_unpaid_bill + (af + fp) * (1 + stPerc)
 * bid = X * (1 + bpf) * (1 + stPerc) + X * pfPerc  + X * impoundStorageTax/total_unpaid_bill + (af + fp) * (1 + stPerc)
 * bid = X * ((1 + bpf) * (1 + stPerc) + pfPerc  + impoundStorageTax/total_unpaid_bill) + (af + fp) * (1 + stPerc)
 * bid - (af + fp) * (1 + stPerc)= X * ((1 + bpf) * (1 + stPerc) + pfPerc  + impoundStorageTax/total_unpaid_bill)
 * X * ((1 + bpf) * (1 + stPerc) + pfPerc  + impoundStorageTax/total_unpaid_bill) = bid - (af + fp) * (1 + stPerc)
 * X = (bid - (af + fp) * (1 + stPerc)) / ((1 + bpf) * (1 + stPerc) + pfPerc  + impoundStorageTax/total_unpaid_bill)
 *
 * // final result
 * X = (bid - (af + fp) * (1 + stPerc)) / ((1 + bpf) * (1 + stPerc) + pfPerc  + impoundStorageTax/total_unpaid_bill)
 *
 * // old
 * X = (bid - (af + fp))/(1 +  bpf + pfPerc + stPerc + impoundStorageTax/total_unpaid_bill)
 *
 * pfValue = (X + af + X * bpf + fp) * pf
 *
 *
 * X - sales price
 * af - admin fee
 * pf - platform fee
 * bpf - buyer percent fee
 * fp - fee price
 * stPerc - sales tax percent
 *
 */

export class BidIncludesFeesBuyerFeePercentBreakdownCalculator extends FeesBreakdownCalculator {
    calculate(bid: Money): WinningBidFeesInMoney {
        this.validate(bid);

        const adminFee = this.adminFeeCalculator.calculate();

        // Trying to calculate salePrice for the case when X < total_unpaid_bill
        // * -- isPlatformFeeTakesOfSellerFee=true --
        // isExcludeSellerFeesFromSalesTax = true
        // * X = (bid - (af + fp) * (1 + pf)) / ((1 + pf) * (1 + bpf) + stPerc + impoundStorageTax/unpaidBill)
        // a = (bid - (af + fp) * (1 + pf))
        // b = ((1 + pf) * (1 + bpf) + stPerc + impoundStorageTax/unpaidBill)
        // isExcludeSellerFeesFromSalesTax = false
        // * X = (bid - (af + fp) * (1 + pf + stPerc)) / ((1 + bpf) * (1 + pf + stPerc) + impoundStorageTax/total_unpaid_bill)
        // X = a / b
        // a = (bid - (af + fp) * (1 + pf + stPerc))
        // b = ((1 + bpf) * (1 + pf + stPerc) + impoundStorageTax/total_unpaid_bill)
        // * -- isPlatformFeeTakesOfSellerFee=false --
        // isExcludeSellerFeesFromSalesTax = true
        // * X = (bid - (af + fp))/((1 +  bpf) + pfPerc + stPerc + impoundStorageTax/unpaidBill)
        // isExcludeSellerFeesFromSalesTax = false
        //  * X = (bid - (af + fp) * (1 + stPerc)) / ((1 + bpf) * (1 + stPerc) + pfPerc  + impoundStorageTax/total_unpaid_bill)
        // X = a / b
        // a = (bid - (af + fp) * (1 + stPerc))
        // b = ((1 + bpf) * (1 + stPerc) + pfPerc  + impoundStorageTax/total_unpaid_bill)

        const a = this.platformFeeCalculator.isPlatformFeeTakesOfSellerFee
            ? // a = (bid - (af + fp) * (1 + pf + stPerc))
              this.options.isExcludeSellerFeesFromSalesTax
                ? Money.subtractOf(
                      bid,
                      Money.sumOf(adminFee, this.perVehicleFee.amount).takePercent(
                          1 + this.platformFeeCalculator.percent
                      )
                  )
                : Money.subtractOf(
                      bid,
                      Money.sumOf(adminFee, this.perVehicleFee.amount).takePercent(
                          1 + this.platformFeeCalculator.percent + this.salesTaxCalculator.percent
                      )
                  )
            : // a = (bid - (af + fp) * (1 + stPerc))
            this.options.isExcludeSellerFeesFromSalesTax
            ? Money.subtractOf(bid, Money.sumOf(adminFee, this.perVehicleFee.amount))
            : Money.subtractOf(
                  bid,
                  Money.sumOf(adminFee, this.perVehicleFee.amount).takePercent(1 + this.salesTaxCalculator.percent)
              );
        const b = this.platformFeeCalculator.isPlatformFeeTakesOfSellerFee
            ? // b = ((1 + bpf) * (1 + pf + stPerc) + impoundStorageTax/total_unpaid_bill)
              this.options.isExcludeSellerFeesFromSalesTax
                ? (1 + this.platformFeeCalculator.percent) * (1 + this.buyerFeeCalculator.percent) +
                  this.salesTaxCalculator.percent +
                  this.storageTaxCalculator.storageTaxPerc
                : (1 + this.buyerFeeCalculator.percent) *
                      (1 + this.platformFeeCalculator.percent + this.salesTaxCalculator.percent) +
                  this.storageTaxCalculator.storageTaxPerc
            : // b = ((1 + bpf) * (1 + stPerc) + pfPerc  + impoundStorageTax/total_unpaid_bill)
            this.options.isExcludeSellerFeesFromSalesTax
            ? 1 +
              this.buyerFeeCalculator.percent +
              this.platformFeeCalculator.percent +
              this.salesTaxCalculator.percent +
              this.storageTaxCalculator.storageTaxPerc
            : (1 + this.buyerFeeCalculator.percent) * (1 + this.salesTaxCalculator.percent) +
              this.platformFeeCalculator.percent +
              this.storageTaxCalculator.storageTaxPerc;

        let salePrice = a.takePercent(1 / b);

        if (salePrice.isGreaterThan(this.storageTaxCalculator?.totalUnpaidBill ?? Money.zero)) {
            // recalculate for the case X >= total_unpaid_bill
            // * -- isPlatformFeeTakesOfSellerFee=true --
            // old
            // * X = (bid - (af + fp) * (1 + pf) - impoundStorageTax) / ((1 + pf) * (1 + bpf) + stPerc)
            // new
            // * X = (bid - ((af + fp) * (1 + pf + stPerc) + impoundStorageTax)) / (1 + bpf) * (1 + pf + stPerc)
            // a = (bid - ((af + fp) * (1 + pf + stPerc) + impoundStorageTax))
            // b = (1 + bpf) * (1 + pf + stPerc)
            // * -- isPlatformFeeTakesOfSellerFee=false --
            // old
            // * X = (bid - (af + fp) - impoundStorageTax)/((1 +  bpf) + pfPerc + stPerc)
            // new
            // * X = (bid - ((af + fp) * (1 + stPerc) + impoundStorageTax)) / ((1 + bpf) * (1 + stPerc) + pfPerc)
            // a = (bid - ((af + fp) * (1 + stPerc) + impoundStorageTax))
            // b = ((1 + bpf) * (1 + stPerc) + pfPerc)
            const a = this.platformFeeCalculator.isPlatformFeeTakesOfSellerFee
                ? // a = (bid - ((af + fp) * (1 + pf + stPerc) + impoundStorageTax))
                  this.options.isExcludeSellerFeesFromSalesTax
                    ? Money.subtractOf(
                          bid,
                          Money.sumOf(adminFee, this.perVehicleFee.amount).takePercent(
                              1 + this.platformFeeCalculator.percent
                          ),
                          this.storageTaxCalculator.impoundStorageTax
                      )
                    : Money.subtractOf(
                          bid,
                          Money.sumOf(
                              Money.sumOf(adminFee, this.perVehicleFee.amount).takePercent(
                                  1 + this.platformFeeCalculator.percent + this.salesTaxCalculator.percent
                              ),
                              this.storageTaxCalculator.impoundStorageTax
                          )
                      )
                : // a = (bid - ((af + fp) * (1 + stPerc) + impoundStorageTax))
                this.options.isExcludeSellerFeesFromSalesTax
                ? Money.subtractOf(
                      bid,
                      Money.sumOf(adminFee, this.perVehicleFee.amount),
                      this.storageTaxCalculator.impoundStorageTax
                  )
                : Money.subtractOf(
                      bid,
                      Money.sumOf(
                          Money.sumOf(adminFee, this.perVehicleFee.amount).takePercent(
                              1 + this.salesTaxCalculator.percent
                          ),
                          this.storageTaxCalculator.impoundStorageTax
                      )
                  );
            const b = this.platformFeeCalculator.isPlatformFeeTakesOfSellerFee
                ? // b = (1 + bpf) * (1 + pf + stPerc)
                  this.options.isExcludeSellerFeesFromSalesTax
                    ? (1 + this.platformFeeCalculator.percent) * (1 + this.buyerFeeCalculator.percent) +
                      this.salesTaxCalculator.percent
                    : (1 + this.buyerFeeCalculator.percent) *
                          (1 + this.platformFeeCalculator.percent + this.salesTaxCalculator.percent) +
                      this.salesTaxCalculator.percent
                : // b = ((1 + bpf) * (1 + stPerc) + pfPerc)
                this.options.isExcludeSellerFeesFromSalesTax
                ? 1 +
                  this.buyerFeeCalculator.percent +
                  this.platformFeeCalculator.percent +
                  this.salesTaxCalculator.percent
                : (1 + this.buyerFeeCalculator.percent) * (1 + this.salesTaxCalculator.percent) +
                  this.platformFeeCalculator.percent;
            salePrice = a.takePercent(1 / b);
        }

        const variableFee = this.buyerFeeCalculator.calculate(salePrice);
        const buyerFee = this.options.isChargeBuyerFee
            ? Money.sumOf(variableFee, this.perVehicleFee.amount)
            : Money.zero;
        const {platformFee, rawPlatformFee} = this.platformFeeCalculator.calculate({
            amount: salePrice,
            bid,
            buyerFee,
            adminFee,
        });
        if (platformFee !== rawPlatformFee) {
            salePrice = Money.sumOf(salePrice, Money.subtractOf(rawPlatformFee, platformFee));
        }

        const salesTax = this.salesTaxCalculator.calculate(salePrice);
        const storageTax = this.storageTaxCalculator.calculateStorageTax(salePrice);

        const calculatedTotal = Money.sumOf(salePrice, buyerFee, platformFee, adminFee, salesTax, storageTax);
        const diff = Money.subtractOf(bid, calculatedTotal);
        salePrice = Money.sumOf(salePrice, diff);

        return {
            salePrice,
            total: bid,
            totalSellerDue: bid.minus(platformFee),
            bid,
            platformFee,
            adminFee,
            buyerFee,
            // TODO: remove adding of storage tax after implementing Fee Transparency
            salesTax: Money.sumOf(salesTax, storageTax),
            storageTax,
            variableFee,
            vehicleKeyFee: this.perVehicleFee.keeFee,
            vehicleOtherFixedFee: this.perVehicleFee.otherFee,
        };
    }
}

export function createBidIncludesFeesBreakdownCalculator({
    platformFeeData,
    adminFeeAmount,
    buyerFeePerc,
    feePrice,
    salesTax,
    isPlatformFeeTakesOfSellerFee,
    isChargeBuyerFee = true,
    storageTaxCalculator,
}: {
    platformFeeData: PlatformFeeType;
    adminFeeAmount: number;
    buyerFeePerc: number;
    feePrice?: FeePriceDto | null;
    salesTax: number;
    isPlatformFeeTakesOfSellerFee: boolean;
    isChargeBuyerFee?: boolean;
    storageTaxCalculator: StorageTaxCalculator;
}) {
    return new BidIncludesFeesBuyerFeePercentBreakdownCalculator(
        new PercentBuyerFeeCalculator(buyerFeePerc),
        new PercentPlatformFeeCalculator(platformFeeData, {isPlatformFeeTakesOfSellerFee}),
        storageTaxCalculator,
        new SalesTaxCalculator(salesTax),
        AdminFeeCalculator.getInstance(Money.fromDollars(adminFeeAmount)),
        new PerVehicleFee(feePrice),
        {
            isChargeBuyerFee,
        }
    );
}
