import {
    CollectionSnapSubscriptionCallback,
    CollectionSubscriptionCallback,
    Firestore as Service,
    QueryDocumentSnapshot,
    Query,
    toArray,
    CollectionCountSubscriptionCallback,
} from '@joyrideautos/ui-services/src/firebase/Firestore';
import {WithKey} from '@joyrideautos/ui-services/src/firebase/types';
import {
    doc,
    DocumentReference,
    DocumentSnapshot,
    getDoc,
    getDocs,
    getFirestore,
    onSnapshot,
    getCountFromServer,
    QuerySnapshot,
    Query as FirebaseFirestoreQuery,
    query,
    limit,
    startAfter,
    Firestore as FirebaseFirestore,
    connectFirestoreEmulator,
    Timestamp,
    DocumentData,
} from 'firebase/firestore';
import Logger from '@joyrideautos/auction-core/src/utils/logger/Logger';
import {Unsubscribe} from '@joyrideautos/ui-services/src/types';
import {FirestoreQuery} from './FirestoreQuery';
import {FirestoreCollectionGroupQuery} from './FirestoreCollectionGroupQuery';
import {AppConfig} from '@joyrideautos/ui-services/src/AppConfig';
import {FirebaseApp} from 'firebase/app';

const DEFAULT_CHUNK_SIZE = 2000;

function defaultTransformer<T, R>(snapshot: QueryDocumentSnapshot<T>): R | null {
    return snapshot.exists() ? ({...snapshot.data(), key: snapshot.id} as any) : null;
}

class Firestore implements Service {
    private subscriptions: {[key: string]: number | undefined} = {};

    constructor(private firestore: FirebaseFirestore, private appConfig: AppConfig, private logger?: Logger) {
        if (this.appConfig.emulatorsConfig.firestoreUrl && this.appConfig.isEmulatedEnv) {
            const firestoreUrl = new URL(this.appConfig.emulatorsConfig.firestoreUrl);
            const host = firestoreUrl.hostname;
            const port = parseInt(firestoreUrl.port || '8087');
            console.log(`        -- hitting local firestore emulator: ${host}/${port}`);
            connectFirestoreEmulator(firestore, host, port);
        }
    }

    async fetchOnce<T>(ref: DocumentReference<T>): Promise<WithKey<T> | null> {
        const snapshot = await this.fetchOnceDocument(ref);
        if (!snapshot) {
            return null;
        }
        const data = snapshot.data() as T;
        return data && {...data, key: snapshot.id};
    }

    async fetchOnceDocument<T>(ref: DocumentReference<T>): Promise<DocumentSnapshot<T> | null> {
        this.logger?.log('fetchOnceDocument', ref.path);
        return await getDoc<T, DocumentData>(ref);
    }

    async fetchOnceArray<T, R>(
        query: Query<T>,
        transform: (snapshot: QueryDocumentSnapshot<T>) => R | null = defaultTransformer
    ): Promise<R[]> {
        const result: R[] = [];
        const snapshot = await getDocs<T, DocumentData>(this.toFirestoreQuery(query));
        snapshot.forEach((snapshot) => {
            const data = transform(snapshot);
            data && result.push(data);
        });
        return result;
    }

    async fetchOnceDocuments<T>(query: Query<T>): Promise<DocumentSnapshot<T>[] | null> {
        const result = await getDocs<T, DocumentData>(this.toFirestoreQuery(query));
        return result.empty ? null : result.docs;
    }

    subscribeToDocument<T extends Record<string, any>>(
        ref: DocumentReference<T>,
        subscriber: (data: WithKey<T> | undefined, error: Error | undefined) => void
    ): Unsubscribe {
        if (process.env.NODE_ENV === 'development') {
            this.subscriptions[ref.path] = (this.subscriptions[ref.path] || 0) + 1;
            this.logger?.log('subscribed to ', ref.path);
            if ((this.subscriptions[ref.path] || 0) > 1) {
                this.logger?.log(
                    'subscribe a new leaked subscription to ',
                    ref.toString(),
                    this.subscriptions[ref.path]
                );
            }
        }
        const unsubscribe = onSnapshot(
            ref,
            (snapshot) => {
                subscriber(defaultTransformer(snapshot) ?? undefined, undefined);
            },
            (error) => {
                subscriber(undefined, error);
            }
        );
        return () => {
            if (process.env.NODE_ENV === 'development') {
                this.subscriptions[ref.path] = (this.subscriptions[ref.path] || 0) - 1;
                this.logger?.log('unsubscribed from ', ref.path);
                if ((this.subscriptions[ref.path] || 0) > 1) {
                    this.logger?.log(
                        'unsubscribe from leaked subscription to ',
                        ref.path,
                        this.subscriptions[ref.path]
                    );
                }
            }
            unsubscribe();
        };
    }

    subscribeToCollectionSnap<T>(query: Query<T>, subscriber: CollectionSnapSubscriptionCallback<T>): Unsubscribe {
        this.logger?.log('subscribeToCollectionSnap', {query: (this.toFirestoreQuery(query) as any)._query});
        return onSnapshot<T, DocumentData>(this.toFirestoreQuery(query), subscriber, (error) =>
            subscriber(undefined, error)
        );
    }

    subscribeToCollection<T>(query: Query<T>, subscriber: CollectionSubscriptionCallback<T>): Unsubscribe {
        this.logger?.log('subscribeToCollection', {query: (this.toFirestoreQuery(query) as any)._query});
        const unsubscribe = onSnapshot<T, DocumentData>(
            this.toFirestoreQuery<T>(query),
            (snapshot: QuerySnapshot<T>) => {
                if (snapshot.empty) {
                    subscriber(null);
                } else {
                    const results: {[key: string]: T} = {};
                    snapshot.forEach((result) => {
                        results[result.id] = result.data();
                    });
                    subscriber(results);
                }
            },
            (error) => {
                this.logger?.log((this.toFirestoreQuery(query) as any)._query, error);
                subscriber(null);
            }
        );
        return () => {
            this.logger?.log('unsubscribed from ', (this.toFirestoreQuery(query) as any)._query);
            unsubscribe();
        };
    }

    subscribeToCollectionCount<T>(query: Query<T>, subscriber: CollectionCountSubscriptionCallback): Unsubscribe {
        this.logger?.log('subscribeToCollection', {query: (this.toFirestoreQuery(query) as any)._query});
        const unsubscribe = onSnapshot<T, DocumentData>(
            this.toFirestoreQuery<T>(query),
            (snapshot: QuerySnapshot<T>) => {
                if (snapshot.empty) {
                    subscriber(0);
                } else {
                    subscriber(snapshot.size);
                }
            },
            (error) => {
                this.logger?.log((this.toFirestoreQuery(query) as any)._query, error);
                subscriber(0);
            }
        );
        return () => {
            this.logger?.log('unsubscribed from ', (this.toFirestoreQuery(query) as any)._query);
            unsubscribe();
        };
    }

    async fetchCollectionCount<T>(query: Query<T>): Promise<number> {
        try {
            // From https://firebase.google.com/docs/firestore/query-data/aggregation-queries
            // If an aggregation cannot resolve within 60 seconds, it returns a DEADLINE_EXCEEDED error.
            // Performance depends on your index configuration and on the size of the dataset.
            // Note: Most queries scale based on the on the size of the result set, not the dataset.
            // However, aggregation queries scale based on the size of the dataset and the number of index entries scanned.
            const result = await getCountFromServer<T, DocumentData>(this.toFirestoreQuery<T>(query));
            return result.data().count;
        } catch (error: any) {
            this.logger?.warn(error.message);
            return 0;
        }
    }

    queryIterator<T>(
        q: Query<T>,
        params?: {chunkSize?: number | undefined; startDocumentId?: string | undefined}
    ): AsyncIterableIterator<WithKey<T>[]> {
        const chunkSize = params?.chunkSize || DEFAULT_CHUNK_SIZE;

        let _query = query<T, DocumentData>(this.toFirestoreQuery(q), limit(chunkSize));

        const fetchAfter = async (lastDoc?: QueryDocumentSnapshot<T>) => {
            _query = lastDoc ? query(_query, startAfter(lastDoc)) : _query;
            const result = await getDocs(_query);
            return {result, lastFoundDoc: result.docs[chunkSize - 1]};
        };
        return (async function* () {
            let _lastFoundDoc: QueryDocumentSnapshot<T> | undefined;

            while (true) {
                const {result, lastFoundDoc} = await fetchAfter(_lastFoundDoc);
                _lastFoundDoc = lastFoundDoc;
                yield toArray<WithKey<T>, T>(result, (key, data) => ({key, ...data}));
                if (!_lastFoundDoc) {
                    return;
                }
            }
        })();
    }

    collectionRef<T>(path: string, ...segments: string[]): Query<T> {
        return new FirestoreQuery<T>(this.firestore, path, ...segments);
    }

    collectionGroupRef<T>(path: string): Query<T> {
        return new FirestoreCollectionGroupQuery<T>(this.firestore, path);
    }

    toFirestoreQuery<T>(query: Query<T>) {
        return (query as any).query as FirebaseFirestoreQuery<T>;
    }

    documentRef<T>(path: string, ...segments: string[]): DocumentReference<T> {
        return doc(this.firestore, path, ...segments) as DocumentReference<T>;
    }

    toFirestoreTimestamp(value: number | string | {seconds: number; nanoseconds: number}) {
        return toFirestoreTimestamp(value);
    }
}

export function toFirestoreTimestamp(value: number | string | {seconds: number; nanoseconds: number}) {
    if (typeof value === 'number') {
        return Timestamp.fromMillis(value);
    }
    if (typeof value === 'string') {
        return Timestamp.fromMillis(Date.parse(value));
    }
    return new Timestamp(value.seconds, value.nanoseconds);
}

export function createFirestore(firebaseApp: FirebaseApp, appConfig: AppConfig, logger?: Logger): Service {
    return new Firestore(getFirestore(firebaseApp), appConfig, logger);
}
