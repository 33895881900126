import {BaseService} from './BaseService';

export class ReleaseVersionService extends BaseService {
    subscribeToReleaseVersion(listener: (releaseVersion: string) => void): () => void {
        return this.firebase.database.subscribeToSnapshot(`/releaseVersion`, (snapshot) => {
            if (snapshot?.exists()) {
                listener(String(snapshot.val() ?? 0));
            }
        });
    }
}
