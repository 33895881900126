import {IDisposer, Instance, types} from 'mobx-state-tree';
import BaseStore from './BaseStore';
import {ClaimSession} from '../types/ClaimSession';
import {logError} from '@joyrideautos/ui-logger/src/utils';
import {LoadingStatus} from '../utils/LoadingStatus';
import {ClaimSessionDto} from '@joyrideautos/auction-core/src/dtos/ClaimSessionDto';

export const ClaimSessionsStore = BaseStore.named('ClaimSessionsStore')
    .props({
        claimSessions: types.map(ClaimSession),
    })
    .volatile(() => ({
        disposers: [] as IDisposer[],
        loadingStatus: new LoadingStatus(),
    }))
    .actions((self) => {
        return {
            setClaimSession(key: string, claimSession: ClaimSessionDto) {
                self.claimSessions.set(key, claimSession);
            },
            fetchClaimSession(key: string): void {
                const claimSession = self.claimSessions.get(key);
                if (!claimSession) {
                    self.loadingStatus.setInProgress();
                    self.rootStore.sellersService
                        .fetchClaimSession(key)
                        .then((claimSession): void => {
                            claimSession && this.setClaimSession(key, claimSession);
                            self.loadingStatus.setReady();
                        })
                        .catch((e) => {
                            self.loadingStatus.setError(e);
                            logError(e);
                        });
                }
            },
        };
    })
    .views((self) => ({
        getClaimSessionAsync(key: string) {
            const claimSession = self.claimSessions.get(key);
            if (!claimSession) {
                requestAnimationFrame(() => {
                    self.fetchClaimSession(key);
                });
            }
            return claimSession;
        },
    }));

export interface ClaimSessionsStoreType extends Instance<typeof ClaimSessionsStore> {}

export interface HasClaimSessionsStore {
    claimSessionsStore: ClaimSessionsStoreType;
}
