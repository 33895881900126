import {
    applySnapshot,
    flow,
    getRoot,
    Instance,
    onSnapshot,
    SnapshotOrInstance,
    types,
    getSnapshot,
    IDisposer,
} from 'mobx-state-tree';
import {EventPayloadType} from '@joyrideautos/ui-models/src/types/events/EventDto';
import {
    EventSubscription,
    EventSubscriptionDtoType,
    EventSubscriptionType,
} from '@joyrideautos/ui-models/src/types/events/EventSubscription';
import {notifyAuctionIsAboutToStart} from '@joyrideautos/ui-models/src/types/events/actions/auctionStart';
import {AuctionType} from '@joyrideautos/ui-models/src/types/auction/Auction';
import {AuctionsFetchStrategy} from '@joyrideautos/ui-models/src/types/events/actions/AuctionsFetchStrategy';
import itiriri from 'itiriri';
import {Event, EventSnapshotIn, EventType} from '@joyrideautos/ui-models/src/types/events/Event';
import {SortingEnum} from '@joyrideautos/auction-core/src/types/Sorting';
import {observable, reaction} from 'mobx';
import {chunkProcessor} from 'mobx-utils';
import {WithKey} from '@joyrideautos/auction-core/src/types/common';
import {
    LocalTransientEventKeyPrefix,
    NotificationItem,
    NotificationItemType,
    fromNotificationItemDto,
} from '@joyrideautos/ui-models/src/types/events/TransientEvent';
import {cloneDeep} from 'lodash';
import AsyncLoaderQueue from '@joyrideautos/auction-core/src/utils/AsyncLoaderQueue';
import {logError} from '@joyrideautos/ui-logger/src/utils';
import {TransientEventTypeEnum} from '@joyrideautos/auction-core/src/types/events/transient';
import BaseStore from '@joyrideautos/ui-models/src/stores/BaseStore';
import type {EventsService} from '@joyrideautos/ui-services/src/services/EventsService';
import {ONE_SEC} from '@joyrideautos/auction-utils/src/dateTimeUtils';
import {partition} from '@joyrideautos/auction-utils/src/arrayUtils';
import {NotificationFilterEnum, filterFactory, createNotificationTypeFilter} from './NotificationFilters';
import {Filter, andFilter, falseFilter} from '@joyrideautos/auction-utils/src/filters';
import LRUCache from '@joyrideautos/auction-utils/src/LRUCache';
import {Unsubscribe} from '@joyrideautos/ui-services/src/types';
import {
    NotificationState,
    NotificationEnum,
    UserNotificationPayload,
} from '@joyrideautos/auction-core/src/types/events/notifications';
import {NotificationKindEnum} from '@joyrideautos/ui-models/src/types/events/Notification';
import {createSubscribeToPersistedNotificationsCountViewModel} from './NotificationsCountViewModel';
import {createLastPersistedNotificationsViewModel} from './LastPersistedNotificationsViewModel';
import {mapPersistedNotificationDtoToModel, mapTransientNotificationToModel} from './mappers';
import {createPersistedNotificationsContainer} from './PersistedNotificationsContainer';

const LAST_UNREAD_PERSISTED_NOTIFICATIONS_LIMIT = 10;
// The badge in the menu can show only up to '99+' so we can optimize the live counter subscription
const UNREAD_PERSISTED_NOTIFICATIONS_COUNT_LIMIT = 100;

function toDto(proxyValue: any) {
    return cloneDeep(proxyValue);
}

export const notificationsSortingFactory = {
    [SortingEnum.DESC]: (a: EventType, b: EventType) => (b.timestamp ?? 0) - (a.timestamp ?? 0),
    [SortingEnum.ASC]: (a: EventType, b: EventType) => (a.timestamp ?? 0) - (b.timestamp ?? 0),
};

const filterEventGroup = (eventType: string, onEventSkipped: (e: EventSnapshotIn) => void) => {
    let isEventAdded = false;
    return (e: EventSnapshotIn) => {
        if (e.type !== eventType) {
            return true;
        }
        if (isEventAdded) {
            onEventSkipped(e);
            return false;
        } else {
            isEventAdded = true;
            return true;
        }
    };
};

export const EventsStore = BaseStore.named('EventsStore')
    .props({
        eventSubscriptions: types.array(EventSubscription),
        // TODO: (future) merge with persistedNotifications
        transientNotifications: types.array(NotificationItem),
        persistedNotifications: types.map(Event),
        unreadPersistedNotificationsCount: createSubscribeToPersistedNotificationsCountViewModel(
            [{name: 'state', value: NotificationState.POPPED}],
            UNREAD_PERSISTED_NOTIFICATIONS_COUNT_LIMIT
        ),
        lastUnreadPersistedNotifications: createLastPersistedNotificationsViewModel(
            NotificationState.POPPED,
            LAST_UNREAD_PERSISTED_NOTIFICATIONS_LIMIT
        ),
        gDriveFinishSuccess: createPersistedNotificationsContainer(
            createNotificationTypeFilter(NotificationFilterEnum.G_DRIVE_FINISH_SUCCESS)
        ),
    })
    .volatile(() => ({
        newUserEventsFilterFn: falseFilter as Filter<any>,
        newPersistedNotificationsDisposer: undefined as IDisposer | undefined,
        disposers: [] as IDisposer[],
    }))
    .views((self) => ({
        get popupNotifications() {
            return Array.from(self.persistedNotifications.values())
                .sort(notificationsSortingFactory[SortingEnum.ASC])
                .map((e) =>
                    Event.create(getSnapshot(e), {
                        services: {eventsService: self.eventsService},
                        rootStore: self.rootStore,
                    })
                );
        },
    }))
    .views((self) => ({
        hasSubscription(predicate: (s: EventSubscriptionType) => boolean): boolean {
            return self.eventSubscriptions.filter(predicate).length > 0;
        },
        get isToastNotificationsEnabled() {
            return self.authUserStore.userInfo?.isToastNotificationsEnabled;
        },
    }))
    .actions((self) => {
        const skippedUserEvents = new Map<string, LRUCache<EventSnapshotIn>>();

        function getSkippedUserEvents(uid: string) {
            if (!skippedUserEvents.has(uid)) {
                skippedUserEvents.set(uid, new LRUCache<EventSnapshotIn>(100));
            }
            return skippedUserEvents.get(uid)!;
        }

        return {
            setNewPersistedNotifications(notifications: EventSnapshotIn[]) {
                const {userInfo} = self.authUserStore;
                const filteredNotifications = notifications
                    .filter((e) => {
                        const {userId} = e.params as EventPayloadType;
                        return userId ? userInfo?.uid === userId : !userId;
                    })
                    .filter((e) => !getSkippedUserEvents(userInfo.uid).has(e.key))
                    .filter(
                        filterEventGroup(NotificationEnum.BID_LOSS, (e) => {
                            getSkippedUserEvents(userInfo.uid).set(e.key, e);
                            self.eventsService
                                .savePersistedNotifications({
                                    notifications: [
                                        {
                                            key: e.key,
                                            state: NotificationState.POPPED,
                                        },
                                    ],
                                })
                                .catch(logError(`mark BID_LOSS as popped: ${e.key}`));
                        })
                    );
                const filteredNotificationsMap = filteredNotifications.reduce<Record<string, EventSnapshotIn>>(
                    (notifications, event) => {
                        notifications[event.key] = event;
                        return notifications;
                    },
                    {}
                );
                applySnapshot(self.persistedNotifications, filteredNotificationsMap);
            },
        };
    })
    .actions((self) => {
        const {scheduler, auctionsStore} = getRoot(self) as any;
        const auctionsFetchStrategy = new AuctionsFetchStrategy(auctionsStore);

        const scheduleSubscription = (subscriptions: EventSubscriptionType[]) => {
            if (!subscriptions || subscriptions.length === 0) {
                return;
            }
            const promises: Promise<AuctionType[]>[] = subscriptions.map((subscription) =>
                auctionsFetchStrategy.fetchAuctions(subscription)
            );

            Promise.all(promises)
                .then((results) => {
                    const auctions = itiriri(results)
                        .flat((r) => r)
                        .distinct((a) => `${a.regionId}-${a.auctionId}`)
                        .toArray();
                    const jobs = auctionsToSchedulerJobs({eventsService: self.eventsService})(
                        auctions,
                        subscriptions[0].uid
                    );

                    scheduler.registerJobs(jobs);
                })
                .catch((e) => console.log(e));
        };

        const transientEventsQueue = new AsyncLoaderQueue<any>('EventsStore');

        return {
            setNewUserEventsFilterFn(fn?: Filter<EventType>) {
                self.newUserEventsFilterFn = fn
                    ? andFilter(filterFactory[NotificationFilterEnum.NEW], fn)
                    : falseFilter;
            },
            applyNewUserEventsFilterFn(fn: Filter<EventType>) {
                this.setNewUserEventsFilterFn(fn);
                return () => {
                    this.setNewUserEventsFilterFn(falseFilter);
                };
            },
            markEventsAsRead(events: EventType[]) {
                for (const event of events) {
                    event.markPoppedAndSave(event.params!.userId!);
                }
            },
            subscribeToNewPersistedNotifications(uid: string) {
                let cancel = false;
                let unsubscribe: Unsubscribe | null = null;
                (async () => {
                    await self.rootStore.eventsService.updateStateForAllUserPersistedNotifications({
                        from: NotificationState.NEW,
                        to: NotificationState.POPPED,
                    });
                    if (cancel) {
                        return;
                    }
                    unsubscribe = self.rootStore.eventsService.subscribeToPersistedNotifications(
                        uid,
                        NotificationState.NEW,
                        (notifications) => {
                            const [skippedNewNotifications, newNotifications] = partition(
                                notifications,
                                self.newUserEventsFilterFn
                            );
                            requestAnimationFrame(() => {
                                this.markEventsAsRead(
                                    skippedNewNotifications.map(mapPersistedNotificationDtoToModel).map((e) =>
                                        Event.create(e, {
                                            services: {eventsService: self.eventsService},
                                            rootStore: self.rootStore,
                                        })
                                    )
                                );
                            });
                            self.setNewPersistedNotifications(newNotifications.map(mapPersistedNotificationDtoToModel));
                        }
                    );
                })().catch(logError());
                return () => {
                    cancel = true;
                    unsubscribe && unsubscribe();
                };
            },
            subscribeToTransientNotifications(uid: string) {
                const transientEvents = observable.array<WithKey<NotificationItemType>>([]);

                const addTransientEventsProcessorDisposer = chunkProcessor(
                    transientEvents,
                    async (events) => {
                        transientEventsQueue.enqueue({
                            key: Symbol(`transientEvent-${uid}`),
                            loader: async () => {
                                this.addTransientEvents(
                                    events
                                        .map(({key, type, params}) =>
                                            fromNotificationItemDto({
                                                type: type as TransientEventTypeEnum,
                                                key,
                                                params: {...toDto(params), type},
                                            })
                                        )
                                        .map(mapTransientNotificationToModel)
                                );
                            },
                        });
                        transientEventsQueue.checkQueue();
                    },
                    ONE_SEC,
                    5
                );

                const newTransientEventsDisposer = self.rootStore.eventsService.subscribeToNewTransientEvents(
                    uid,
                    (event) => {
                        transientEvents.push(fromNotificationItemDto(event));
                    }
                );
                return () => {
                    newTransientEventsDisposer();
                    addTransientEventsProcessorDisposer();
                    transientEventsQueue.clear();
                };
            },
            subscribeToToastNotifications(uid: string) {
                if (!self.newPersistedNotificationsDisposer) {
                    self.newPersistedNotificationsDisposer = this.subscribeToNewPersistedNotifications(uid);
                }
            },
            unsubscribeFromToastNotifications() {
                self.newPersistedNotificationsDisposer && self.newPersistedNotificationsDisposer();
                self.newPersistedNotificationsDisposer = undefined;
            },
            subscribeToEvents: function (uid: string) {
                this.subscribeToToastNotifications(uid);
                const transientEventsDisposer = this.subscribeToTransientNotifications(uid);

                const eventSubscriptionsDisposer = self.rootStore.eventsService.listenEventSubscriptions(uid, (ss) => {
                    applySnapshot(self.eventSubscriptions, ss);
                });

                self.disposers.push(() => {
                    this.unsubscribeFromToastNotifications();
                    transientEventsDisposer();
                    eventSubscriptionsDisposer();
                });
            },

            addEventSubscription: flow(function* (
                s: SnapshotOrInstance<EventSubscriptionDtoType>
            ): Generator<Promise<any>, any, any> {
                yield self.rootStore.eventsService.createEventSubscription(s);
            }),

            unsubscribe() {
                self.disposers.forEach((d) => d());
                self.disposers = [];
            },

            addTransientEvents(events: NotificationItemType | NotificationItemType[]) {
                if (!self.isToastNotificationsEnabled) {
                    return;
                }
                Array.prototype.push.apply(
                    self.transientNotifications,
                    (Array.isArray(events) ? events : [events]).map((event) => ({
                        // if key is missing we generate it for local events
                        // @ts-ignore
                        key: `${LocalTransientEventKeyPrefix}${Math.random() * 1000}`,
                        ...event,
                        kind: 'transientEvent',
                        params: {type: event.type, ...event.params},
                    }))
                );
            },

            addPersistedNotifications: async (notifications: UserNotificationPayload[]) => {
                try {
                    await self.rootStore.eventsService.savePersistedNotifications({
                        notifications,
                    });
                } catch (error) {
                    self.logger.log('Failed to add persisted notifications', {error});
                }
            },

            showSuccess({message, description}: {message?: string; description: string}) {
                this.addTransientEvents({
                    kind: NotificationKindEnum.TRANSIENT,
                    key: `${LocalTransientEventKeyPrefix}${Math.random() * 1000}`,
                    type: TransientEventTypeEnum.DEFAULT_SUCCESS,
                    message: message ?? 'Success!',
                    description,
                    icon: '',
                    params: {
                        type: TransientEventTypeEnum.DEFAULT_SUCCESS,
                        tmpValue: undefined,
                        message,
                        description,
                        auctionSeries: undefined,
                        reason: undefined,
                        userEmail: undefined,
                    },
                });
            },

            showError({message, description}: {message?: string; description: string}) {
                this.addTransientEvents({
                    kind: NotificationKindEnum.TRANSIENT,
                    key: `${LocalTransientEventKeyPrefix}${Math.random() * 1000}`,
                    type: TransientEventTypeEnum.DEFAULT_ERROR,
                    message: message || 'Error!',
                    description,
                    icon: '',
                    params: {
                        type: TransientEventTypeEnum.DEFAULT_ERROR,
                        tmpValue: undefined,
                        message,
                        description,
                        auctionSeries: undefined,
                        reason: undefined,
                        userEmail: undefined,
                    },
                });
            },

            removeTransientEvents(keys: string[]) {
                if (keys.length === 0) {
                    return;
                }
                self.transientNotifications.replace(
                    Array.from(self.transientNotifications.values())
                        .filter(({key}) => !keys.includes(key))
                        .map((e) => getSnapshot(e as any))
                );
                self.rootStore.eventsService
                    .markForRemoveTransientEventState({
                        eventIds: keys.filter((key) => !key.startsWith(LocalTransientEventKeyPrefix)),
                    })
                    .catch(logError());
            },

            clearAllEvents() {
                self.transientNotifications.clear();
                self.eventSubscriptions.clear();
                self.persistedNotifications.clear();
            },
            clear() {
                this.unsubscribe();
                this.clearAllEvents();
            },

            afterCreate() {
                self.disposers.push(onSnapshot(self.eventSubscriptions, scheduleSubscription));
                self.disposers.push(
                    reaction(
                        () => ({
                            isToastNotificationsEnabled: self.isToastNotificationsEnabled,
                            uid: self.authUserStore.userInfo?.uid,
                        }),
                        ({isToastNotificationsEnabled, uid}: {isToastNotificationsEnabled?: boolean; uid?: string}) => {
                            if (isToastNotificationsEnabled == null || !uid) {
                                // skip anonymous users
                                return;
                            }
                            if (isToastNotificationsEnabled) {
                                this.subscribeToToastNotifications(uid);
                            } else {
                                this.unsubscribeFromToastNotifications();
                            }
                        }
                    )
                );
            },

            beforeDestroy() {
                this.unsubscribe();
            },
        };
    });

export interface EventsStoreType extends Instance<typeof EventsStore> {}

function makeFavoriteRegionSubscriptionId(regionId: string, auctionId: string, uid: string) {
    return `${regionId!}-${auctionId}-${uid}`;
}

const auctionsToSchedulerJobs =
    (services: {eventsService: EventsService}) => (auctions: AuctionType[], uid: string) => {
        return auctions
            .filter((auction) => typeof auction.settings.startByAuctionType !== 'undefined')
            .map((auction) => ({
                id: makeFavoriteRegionSubscriptionId(auction.regionId, auction.auctionId, uid),
                startTime: auction.settings.startByAuctionTypeAsDate as Date,
                executor: () => notifyAuctionIsAboutToStart(services)(auction.auctionPath, uid),
            }));
    };
