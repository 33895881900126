import {dollarsWithCommas} from '@joyrideautos/auction-utils/src/numberUtil';
import {Instance, types} from 'mobx-state-tree';

export const PriceBreakdownViewModel = types
    .model('PriceBreakdownViewModel', {
        total: 0,
        deposit: 0,
        bidAmount: 0,
        feesSubtotal: 0,
        platformFee: 0,
        platformFeeCollectedOnline: 0,
        salesTax: 0,
        adminFee: 0,
        variableFee: 0,
        vehicleKeyFee: 0,
        vehicleOtherFixedFee: 0,
        remediationFee: 0,
        collectedOnline: 0,
        dueAtPickup: 0,
        lineItemMessage: types.maybe(types.string),
    })
    .views((self) => ({
        get totalFormatted() {
            return dollarsWithCommas(self.total);
        },
        get depositFormatted() {
            return dollarsWithCommas(self.deposit);
        },
        get bidAmountFormatted() {
            return dollarsWithCommas(self.bidAmount);
        },
        get feesSubtotalFormatted() {
            return dollarsWithCommas(self.feesSubtotal);
        },
        get salesTaxFormatted() {
            return dollarsWithCommas(self.salesTax);
        },
        get adminFeeFormatted() {
            return dollarsWithCommas(self.adminFee);
        },
        get variableFeeFormatted() {
            return dollarsWithCommas(self.variableFee);
        },
        get vehicleKeyFeeFormatted() {
            return dollarsWithCommas(self.vehicleKeyFee);
        },
        get vehicleOtherFixedFeeFormatted() {
            return dollarsWithCommas(self.vehicleOtherFixedFee);
        },
        get remediationFeeFormatted() {
            return dollarsWithCommas(self.remediationFee);
        },
        get platformFeeFormatted() {
            return dollarsWithCommas(self.platformFee);
        },
        get platformFeeCollectedOnlineFormatted() {
            return dollarsWithCommas(self.platformFeeCollectedOnline);
        },
        get collectedOnlineFormatted() {
            return dollarsWithCommas(self.collectedOnline);
        },
        get dueAtPickupFormatted() {
            return dollarsWithCommas(self.dueAtPickup);
        },
        get isCollectedOnlineHasSubmenu() {
            return !!(self.platformFeeCollectedOnline || self.deposit);
        },
        get hasFees() {
            return (
                self.feesSubtotal > 0 ||
                self.salesTax > 0 ||
                self.adminFee > 0 ||
                self.variableFee > 0 ||
                self.vehicleKeyFee > 0 ||
                self.vehicleOtherFixedFee > 0 ||
                self.platformFee > 0
            );
        },
    }));

export interface PriceBreakdownViewModelType extends Instance<typeof PriceBreakdownViewModel> {}
