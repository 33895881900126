import * as s from 'superstruct';
import {OrderValidation} from '../types/validations/orderValidation';
export enum OrderStatusEnum {
    CREATED = 'CREATED',
    PAYMENT_PROCESSING = 'PAYMENT_PROCESSING',
    PAYMENT_FAILED = 'PAYMENT_FAILED',
    CANCELED = 'CANCELED',
    PAYMENT_SUCCESSFUL = 'PAYMENT_SUCCESSFUL',
}

export enum OrderErrorCodesEnum {
    PAYMENT_FAILED = 'PAYMENT_FAILED',
    UNHANDLED_ERROR = 'UNHANDLED_ERROR',
}

export type OrderDto = s.Infer<typeof OrderValidation>;
