import {getEnv, Instance, types} from 'mobx-state-tree';
import {IDisposer} from 'mobx-utils';
import {when} from 'mobx';
import {LoadingStatus} from '../utils/LoadingStatus';
import {toUserDisplayName} from '@joyrideautos/auction-core/src/rules/UserModel';
import {
    PaymentTypeEnum,
    StripeIdentityVerificationSessionStatusEnum,
} from '@joyrideautos/auction-core/src/dtos/PaymentsDto';
import {UserTypeEnum} from '@joyrideautos/auction-core/src/dtos/UserDto';
import {ItemPath, itemPathComparator} from '@joyrideautos/auction-core/src/dtos/ItemDto';
import {AuctionPath, auctionPathComparator} from '@joyrideautos/auction-core/src/dtos/AuctionOccurrenceDto';
import {AuthUserService} from '@joyrideautos/ui-services/src/services/AuthUserService';
import {PersistedItemReference} from './item/Refs';

export const PaymentMethodModel = types.model('PaymentMethod', {
    paymentMethodId: types.string,
    last_4: types.string,
    cardBrand: types.string,
    paymentMethodName: types.maybe(types.string),
    fingerprint: types.string,
    isDefault: types.maybe(types.boolean),
    createdAt: types.maybe(types.number),
    setupStatus: types.optional(types.maybe(types.string), undefined),
    setupError: types.optional(types.maybe(types.string), undefined),
    setupActionUrl: types.optional(types.maybe(types.string), undefined),
});

export interface PaymentMethodType extends Instance<typeof PaymentMethodModel> {}

const PaymentInfo = types.model('PaymentInfo', {
    paymentProvider: types.maybe(types.string),
    paymentCustomerId: types.maybe(types.string),
});

export const AuthUserAddress = types.model('AuthUserAddress', {
    city: types.string,
    state: types.string,
    street: types.string,
    apartment: types.maybe(types.string),
});

export type AuthUserAddressType = Instance<typeof AuthUserAddress>;

export const AuthUserSettings = types.model('AuthUserSettings', {
    address: types.maybe(AuthUserAddress),
    paymentInfo: types.maybe(PaymentInfo),
});

const IdentityVerificationStatusType = types.enumeration('IdentityVerificationStatusType', [
    StripeIdentityVerificationSessionStatusEnum.CANCELED,
    StripeIdentityVerificationSessionStatusEnum.CREATED,
    StripeIdentityVerificationSessionStatusEnum.PROCESSING,
    StripeIdentityVerificationSessionStatusEnum.REDACTED,
    StripeIdentityVerificationSessionStatusEnum.REQUIRES_INPUT,
    StripeIdentityVerificationSessionStatusEnum.DEPRECTATED_REQUIRES_ACTION,
    StripeIdentityVerificationSessionStatusEnum.VERIFIED,
]);

const IdentityVerifiedByAdmin = types.model('IdentityVerifiedByAdmin', {
    dateVerified: types.string,
    verifiedBy: types.string,
});

const IdentityVerifiedByStripe = types.model('IdentityVerifiedByStripe', {
    status: IdentityVerificationStatusType,
    verificationIntents: types.array(types.frozen()),
    lastVerificationError: types.maybe(types.string),
});

const FailedDeposit = types.model('FailedDeposit', {
    item: PersistedItemReference(),
    paymentResultKey: types.string,
});

const UserWinningItems = types.model('UserWinningItems', {
    inventoryItemKey: types.string,
    regionId: types.string,
    auctionId: types.string,
    itemId: types.string,
    bidId: types.string,
    amountDueOnlineCents: types.number,
});

const UserUnpaidItems = types.model('UserUnpaidItems', {
    inventoryItemKey: types.string,
    regionId: types.string,
    auctionId: types.string,
    itemId: types.string,
    amountDueOnlineCents: types.number,
    paymentType: types.maybe(types.enumeration('PaymentTypeEnum', Object.values(PaymentTypeEnum))),
});

export type AuthUserSettingsType = Instance<typeof AuthUserSettings>;

export enum PhoneVerificationStatusEnum {
    NO_PHONE = 'no_phone',
    NOT_VERIFIED = 'not_verified',
    VERIFIED = 'verified',
    REQUEST_SENT = 'request_sent',
    ERROR = 'error',
}

export const UserType = types.enumeration('UserType', [UserTypeEnum.BUYER, UserTypeEnum.SELLER]);

const AuctionWithBids = types.model('AuctionsWithBids', {
    regionId: types.string,
    auctionId: types.string,
    itemId: types.string,
    bidId: types.string,
});

const AuthUser = types
    .model('AuthUser', {
        uid: types.identifier,
        email: types.maybeNull(types.string),
        emailVerified: types.optional(types.boolean, false),
        emailVerifiedAt: types.maybe(types.string),
        phone: types.maybeNull(types.string),
        phoneVerificationDate: types.maybe(types.string),
        phoneVerificationRequestId: types.maybe(types.string),
        phoneVerificationFor: types.maybe(types.string),
        phoneVerifiedAt: types.maybe(types.string),
        isAnonymous: types.optional(types.boolean, false),
        isAdmin: types.optional(types.boolean, false),
        firstName: types.maybe(types.string),
        lastName: types.maybe(types.string),
        companyName: types.maybe(types.string),
        bidderNumber: types.maybe(types.number),
        zipcode: types.maybe(types.string),
        settings: types.maybe(AuthUserSettings),
        winningItems: types.maybe(types.map(UserWinningItems)),
        unpaidItems: types.maybe(types.map(UserUnpaidItems)),
        failedDeposits: types.maybe(types.array(FailedDeposit)),
        userType: types.maybe(UserType),
        dateCreated: types.maybe(types.string),
        dateModified: types.maybe(types.string),
        source: types.maybe(types.string),
        addressUpdatedAt: types.maybe(types.string),
        profileCompletedAt: types.maybe(types.string),
        paymentMethodsUpdatedAt: types.maybe(types.string),
        identityVerifiedByAdmin: types.maybe(IdentityVerifiedByAdmin),
        identityVerifiedByStripe: types.maybe(IdentityVerifiedByStripe),
        buyerTypeCode: types.maybe(types.string),
        businessRoleTypeCode: types.maybe(types.string),
        isTrusted: types.maybe(types.boolean),
        isSMSNotificationsEnabled: types.maybe(types.boolean),
        isToastNotificationsEnabled: types.maybe(types.boolean),
        hasMultiBidAccess: types.maybe(types.boolean),
        isExemptFromSalesTax: types.maybe(types.boolean),
        auctionsWithBids: types.array(AuctionWithBids),
        paymentMethods: types.array(PaymentMethodModel),
        adminEmail: types.maybe(types.string),
        adminUid: types.maybe(types.string),
        impersonatedUserEmail: types.maybe(types.string),
        impersonatedSessionId: types.maybe(types.string),
    })
    .volatile(() => ({
        status: new LoadingStatus(),
        providerData: [] as any[],
    }))
    .actions((self) => ({
        updateEmail(email: string | null) {
            if (email === self.email) {
                return;
            }
            self.email = email;
            self.emailVerified = false;
        },
        setPaymentMethods(paymentMethods: PaymentMethodType[]) {
            self.paymentMethods.replace(paymentMethods);
        },
    }))
    .views((self) => ({
        get phoneVerified() {
            return !!self.phoneVerifiedAt;
        },
        getActiveBidForItem(itemPath: ItemPath): string | undefined {
            return self.auctionsWithBids.filter((auctionWithBids) => itemPathComparator(auctionWithBids, itemPath))[0]
                ?.bidId;
        },
        getAllActiveBidsForAuction(auctionPath: AuctionPath) {
            return self.auctionsWithBids.filter((auctionWithBids) =>
                auctionPathComparator(auctionWithBids, auctionPath)
            );
        },
        get isVerifiedEmail() {
            return !self.isAnonymous && self.emailVerified;
        },
        get isVerifiedPhone() {
            return !self.isAnonymous && !!self.phoneVerifiedAt;
        },
        get paymentMethodProvided() {
            return self.paymentMethods.find((card) => card.isDefault) !== undefined;
        },
        get addressProvided() {
            return !self.isAnonymous && !!self.addressUpdatedAt;
        },
        get fullName() {
            return toUserDisplayName(self);
        },
        findPaymentMethod(paymentMethodId: string) {
            return self.paymentMethods.find((pm) => pm.paymentMethodId === paymentMethodId);
        },
        get addressInfoProvided() {
            return Boolean(
                self.zipcode &&
                    self.settings &&
                    self.settings.address &&
                    self.settings.address.city &&
                    self.settings.address.state &&
                    self.settings.address.street
            );
        },
        get identityVerified() {
            return (
                self.identityVerifiedByAdmin ||
                (self.identityVerifiedByStripe &&
                    self.identityVerifiedByStripe.status === StripeIdentityVerificationSessionStatusEnum.VERIFIED)
            );
        },
        get authUserService(): AuthUserService {
            const env = getEnv(self);
            return env?.services?.authUserService || env?.rootStore?.authUserService;
        },
    }))
    .actions((self) => {
        const disposers: IDisposer[] = [];
        return {
            afterCreate() {
                disposers.push(
                    when(
                        () => !self.isAnonymous && !!self.authUserService,
                        () => {
                            disposers.push(
                                self.authUserService.subscribeToPaymentMethods(self.uid, (paymentMethods) => {
                                    self.setPaymentMethods(
                                        paymentMethods.map((pm) => ({
                                            paymentMethodId: pm.paymentMethodId,
                                            last_4: pm.last_4,
                                            cardBrand: pm.cardBrand,
                                            paymentMethodName: pm.paymentMethodName,
                                            fingerprint: pm.fingerprint,
                                            isDefault: pm.isDefault,
                                            createdAt: pm.createdAt,
                                            setupStatus: pm.setupStatus,
                                            setupError: pm.setupError,
                                            setupActionUrl: pm.setupActionUrl,
                                        }))
                                    );
                                })
                            );
                        }
                    )
                );
            },
            beforeDestroy() {
                disposers.forEach((d) => d());
            },
        };
    });

export default AuthUser;

export interface AuthUserType extends Instance<typeof AuthUser> {}

export const AddressState = types.model('AddressState', {
    code: types.identifier,
    name: types.string,
});

export type AddressStateType = Instance<typeof AddressState>;

export const ActiveUserBid = types.model('ActiveUserBid', {
    regionId: types.string,
    auctionId: types.string,
    itemId: types.string,
    bidId: types.string,
});

export interface ActiveUserBidType extends Instance<typeof ActiveUserBid> {}
