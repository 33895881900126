import {SellerPayInFull} from '../../dtos/SellerDto';
import {UserDto} from '../../dtos/UserDto';

export class UserPayInFullConfigEntity {
    constructor(
        private authUser: UserDto,
        private isPreApprovedUser: boolean,
        private payInFullEnabledFor?: SellerPayInFull
    ) {}

    get isPayInFullEnabledForSeller() {
        if (this.isPreApprovedUser) {
            return !!this.payInFullEnabledFor?.vip;
        }

        if (this.authUser.isTrusted) {
            return !!this.payInFullEnabledFor?.trusted;
        }

        return !!this.payInFullEnabledFor?.regular;
    }
}
