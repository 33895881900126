import {IDisposer, Instance, types} from 'mobx-state-tree';
import BaseStore from './BaseStore';
import {makeSubscriber} from '@joyrideautos/auction-utils/src/subscriptions';
import {OrderDto, OrderStatusEnum} from '@joyrideautos/auction-core/src/dtos/OrderDto';
import {OrderModel, OrderModelType} from '../types/OrderModel';
import {WithKey} from '@joyrideautos/ui-services/src/firebase/types';
import {LoadingStatus} from '../utils/LoadingStatus';

export const OrdersStore = BaseStore.named('OrdersStore')
    .props({
        orders: types.map(OrderModel),
    })
    .volatile(() => ({
        disposers: [] as IDisposer[],
        loadingStatus: new LoadingStatus(),
    }))
    .views((self) => ({
        findOrder(orderKey?: string) {
            if (!orderKey) {
                return undefined;
            }
            return self.orders.get(orderKey);
        },
        findNotProcessedOrder(): WithKey<OrderModelType> | undefined {
            return Array.from(self.orders.entries())
                .filter(([, order]) => order.status === OrderStatusEnum.CREATED)
                .map(([key, order]) => ({key, ...order}))[0];
        },
        hasProcessingOrderForItem(itemKey: string) {
            return Array.from(self.orders.entries()).find(
                ([, order]) =>
                    order.status === OrderStatusEnum.PAYMENT_PROCESSING && order.inventoryItemKeys.includes(itemKey)
            );
        },
    }))
    .actions((self) => ({
        setOrders(orders: Record<string, OrderDto>) {
            self.orders.replace(orders);
        },
    }))
    .actions((self) => ({
        subscribe(uid?: string) {
            self.loadingStatus.setInProgress();
            self.disposers.push(
                makeSubscriber(`user-orders-${uid ?? 'loggedInUser'}`, () =>
                    self.ordersService.subscribeToUserOrders(uid, (orders) => {
                        self.setOrders(orders);
                        self.loadingStatus.setReady();
                    })
                )()
            );
        },
        unsubscribe() {
            self.disposers.forEach((d) => d());
            self.disposers = [];
        },
        cancelOrder(orderKey: string) {
            // optimistic update (needed for redirect on order summary step)
            self.orders.get(orderKey)?.cancel();
            return self.rootStore.paymentService.cancelOrder({orderKey});
        },
    }));

export interface OrdersStoreType extends Instance<typeof OrdersStore> {}

export interface HasOrdersStore {
    ordersStore: OrdersStoreType;
}
