import {ONE_HOUR, ONE_MIN} from '@joyrideautos/auction-utils/src/dateTimeUtils';
import RootStoreAwareViewModel from '@joyrideautos/web-common-components/src/models/RootStoreAwareViewModel';
import {getEnv, Instance} from 'mobx-state-tree';
import {now} from 'mobx-utils';
import {Routes} from '../../../Routes';

const restrictedRoutes = [
    Routes.CHECKOUT_SUMMARY(),
    Routes.CHECKOUT_PAYMENT({pathParams: {orderKey: ':orderKey'}}),
    Routes.CHECKOUT_PICK_UP_INSTRUCTIONS({pathParams: {orderKey: ':orderKey'}}),
];

export const CompletePurchaseBannerViewModel = RootStoreAwareViewModel.named('CompletePurchaseBannerViewModel')
    .props({})
    .views((self) => ({
        get env() {
            return getEnv(self);
        },
        get nearestOverdueItem() {
            return self.wonItemsStore.nearestOverdueItem;
        },
        get nearestOverdueDateMs() {
            return this.nearestOverdueItem?.overdueAt?.timestamp;
        },
        get isActive() {
            if (restrictedRoutes.includes(this.env.path)) {
                return false;
            }
            return this.nearestOverdueDateMs ? this.nearestOverdueDateMs > now(ONE_MIN) : false;
        },
        get time() {
            return Math.floor((this.nearestOverdueDateMs - now(ONE_MIN)) / ONE_HOUR);
        },
        get timeText() {
            return self.rootStore.languageStore.hoursCount(this.time);
        },
    }));

export interface CompletePurchaseBannerViewModelType extends Instance<typeof CompletePurchaseBannerViewModel> {}
