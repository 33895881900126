import {Instance, types} from 'mobx-state-tree';

export const VisibilityViewModel = types
    .model('VisiblityViewModel', {
        value: false,
    })
    .actions((self) => ({
        setVisible(value: boolean) {
            self.value = value;
        },
        hide() {
            self.value = false;
        },
        show() {
            self.value = true;
        },
    }));

export interface VisibilityViewModelType extends Instance<typeof VisibilityViewModel> {}
