import {getEnv, Instance, types} from 'mobx-state-tree';
import {AWSCredentialsProvider, MediaService, UploadMediaService} from '@joyrideautos/ui-services/src/types';
import type {SentryService} from '@joyrideautos/ui-services/src/services/SentryService';
import type {FullstoryService} from '@joyrideautos/ui-services/src/services/FullstoryService';
import type {SocketWrapper} from './SocketWrapper';
import type {ClockModelType} from '../utils/Clock';
import type {AuthUserService} from '@joyrideautos/ui-services/src/services/AuthUserService';
import type {AnalyticsService} from '@joyrideautos/ui-services/src/services/AnalyticsService';
import type {BidService} from '@joyrideautos/ui-services/src/services/BidService';
import type {AdminRoleService} from '@joyrideautos/ui-services/src/services/AdminRoleService';
import type {AuctionService} from '@joyrideautos/ui-services/src/services/AuctionService';
import type {BiddersService} from '@joyrideautos/ui-services/src/services/BiddersService';
import type {UserBansService} from '@joyrideautos/ui-services/src/services/UserBansService';
import type {EventsService} from '@joyrideautos/ui-services/src/services/EventsService';
import type {FavoritesService} from '@joyrideautos/ui-services/src/services/FavoritesService';
import type {GoogleDriveService} from '@joyrideautos/ui-services/src/services/GoogleDriveService';
import type {ItemPaymentService} from '@joyrideautos/ui-services/src/services/ItemPaymentService';
import type {ClockService} from '@joyrideautos/ui-services/src/services/ClockService';
import type {GlobalConfigService} from '@joyrideautos/ui-services/src/services/GlobalConfigService';
import type {ManagerRoleService} from '@joyrideautos/ui-services/src/services/ManagerRoleService';
import type {SellerRoleService} from '@joyrideautos/ui-services/src/services/SellerRoleService';
import type {PhoneValidationService} from '@joyrideautos/ui-services/src/services/PhoneValidationService';
import type {PaymentService} from '@joyrideautos/ui-services/src/services/PaymentService';
import type {RecaptchaService} from '@joyrideautos/ui-services/src/services/RecaptchaService';
import type {RefsService} from '@joyrideautos/ui-services/src/services/RefsService';
import type {SearchService} from '@joyrideautos/ui-services/src/services/SearchService';
import type {StorageService} from '@joyrideautos/ui-services/src/services/StorageService';
import type {RegionsService} from '@joyrideautos/ui-services/src/services/RegionsService';
import type {WhiteListService} from '@joyrideautos/ui-services/src/services/WhitelistService';
import type {WonItemsService} from '@joyrideautos/ui-services/src/services/WonItemsService';
import type {FirebaseTraceService} from '@joyrideautos/ui-services/src/services/FirebaseTraceService';
import type {UsersService} from '@joyrideautos/ui-services/src/services/UsersService';
import type {SellersService} from '@joyrideautos/ui-services/src/services/SellersService';
import type {ItemsService} from '@joyrideautos/ui-services/src/services/ItemsService';
import type {FirebaseService} from '@joyrideautos/ui-services/src/services/FirebaseService';
import type {PlatformFeeScheduleService} from '@joyrideautos/ui-services/src/services/PlatformFeeScheduleService';
import type {AuditService} from '@joyrideautos/ui-services/src/services/AuditService';
import type {ClaimSessionService} from '@joyrideautos/ui-services/src/services/ClaimSessionService';
import makeLogger, {LoggerStatusEnum} from '@joyrideautos/ui-logger/src/Logger';
import Logger from '@joyrideautos/auction-core/src/utils/logger/Logger';
import {OrdersService} from '@joyrideautos/ui-services/src/services/OrdersService';

export const UIServicesAwareViewModel = types
    .model('UIServicesAwareViewModel')
    .views((self) => ({
        findServiceByName<T>(serviceName: string): T {
            const service = getEnv(self).services[serviceName];
            if (!service) {
                console.error(`Missing service: ${serviceName}`);
                throw new Error(`Missing service: ${serviceName}`);
            }
            return service as T;
        },
    }))
    .views((self) => {
        return {
            get itemsService() {
                return self.findServiceByName<ItemsService>('itemsService');
            },
            get bidsService() {
                return self.findServiceByName<BidService>('bidsService');
            },
            get wonItemService() {
                return self.findServiceByName<WonItemsService>('wonItemService');
            },
            get whiteListService() {
                return self.findServiceByName<WhiteListService>('whiteListService');
            },
            get userService() {
                return self.findServiceByName<UsersService>('userService');
            },
            get biddersService() {
                return self.findServiceByName<BiddersService>('biddersService');
            },
            get sellersService() {
                return self.findServiceByName<SellersService>('sellersService');
            },
            get sellerRoleService() {
                return self.findServiceByName<SellerRoleService>('sellerRoleService');
            },
            get managerRoleService() {
                return self.findServiceByName<ManagerRoleService>('managerRoleService');
            },
            get regionsService() {
                return self.findServiceByName<RegionsService>('regionsService');
            },
            get refsService() {
                return self.findServiceByName<RefsService>('refsService');
            },
            get itemPaymentService() {
                return self.findServiceByName<ItemPaymentService>('itemPaymentService');
            },
            get paymentService() {
                return self.findServiceByName<PaymentService>('paymentService');
            },
            get globalConfigService() {
                return self.findServiceByName<GlobalConfigService>('globalConfigService');
            },
            get favoritesService() {
                return self.findServiceByName<FavoritesService>('favoritesService');
            },
            get firebaseTraceService() {
                return self.findServiceByName<FirebaseTraceService>('firebaseTraceService');
            },
            get userBansService() {
                return self.findServiceByName<UserBansService>('userBansService');
            },
            get auctionService() {
                return self.findServiceByName<AuctionService>('auctionService');
            },
            get adminRoleService() {
                return self.findServiceByName<AdminRoleService>('adminRoleService');
            },
            get authUserService() {
                return self.findServiceByName<AuthUserService>('authUserService');
            },
            get sentryService() {
                return self.findServiceByName<SentryService>('sentryService');
            },
            get fullstoryService() {
                return self.findServiceByName<FullstoryService>('fullstoryService');
            },
            get analyticsService() {
                return self.findServiceByName<AnalyticsService>('analyticsService');
            },
            get clockService() {
                return self.findServiceByName<ClockService>('clockService');
            },
            get storageService() {
                return self.findServiceByName<StorageService>('storageService');
            },
            get socketWrapper(): SocketWrapper {
                return getEnv(self).socketWrapper;
            },
            get clock(): ClockModelType {
                return getEnv(self).clock;
            },
            get eventsService() {
                return self.findServiceByName<EventsService>('eventsService');
            },
            get searchService() {
                return self.findServiceByName<SearchService>('searchService');
            },
            get mediaService() {
                return self.findServiceByName<MediaService>('mediaService');
            },
            get uploadMediaService() {
                return self.findServiceByName<UploadMediaService>('uploadMediaService');
            },
            get phoneValidationService() {
                return self.findServiceByName<PhoneValidationService>('phoneValidationService');
            },
            get awsCredentialsProvider() {
                return self.findServiceByName<AWSCredentialsProvider>('awsCredentialsProvider');
            },
            get firebaseService() {
                return self.findServiceByName<FirebaseService>('firebaseService');
            },
            get googleDriveService() {
                return self.findServiceByName<GoogleDriveService>('googleDriveService');
            },
            get recaptchaService() {
                return self.findServiceByName<RecaptchaService>('recaptchaService');
            },
            get platformFeeScheduleService() {
                return self.findServiceByName<PlatformFeeScheduleService>('platformFeeScheduleService');
            },
            get auditService() {
                return self.findServiceByName<AuditService>('auditService');
            },
            get claimSessionService() {
                return self.findServiceByName<ClaimSessionService>('claimSessionService');
            },
            get ordersService() {
                return self.findServiceByName<OrdersService>('ordersService');
            },
            getLogger(name: string, status = LoggerStatusEnum.ENABLED): Logger {
                return makeLogger(this.sentryService, {
                    loggerStatusEnabled: true,
                })(name, status);
            },
        };
    })
    .actions((self) => {
        return {
            verifyServices() {
                [
                    'itemsService',
                    'bidsService',
                    'wonItemService',
                    'whiteListService',
                    'userService',
                    'biddersService',
                    'sellersService',
                    'sellerRoleService',
                    'managerRoleService',
                    'regionsService',
                    'refsService',
                    'itemPaymentService',
                    'paymentService',
                    'globalConfigService',
                    'favoritesService',
                    'firebaseTraceService',
                    'userBansService',
                    'auctionService',
                    'adminRoleService',
                    'authUserService',
                    'sentryService',
                    'fullstoryService',
                    'analyticsService',
                    'clockService',
                    'storageService',
                    'eventsService',
                    'searchService',
                    'mediaService',
                    'uploadMediaService',
                    'phoneValidationService',
                    'awsCredentialsProvider',
                    'firebaseService',
                    'googleDriveService',
                    'recaptchaService',
                    'platformFeeScheduleService',
                    'auditService',
                    'ordersService',
                ].forEach((service) => self.findServiceByName(service));
                if (!self.socketWrapper) {
                    console.error('Missing socketWrapper');
                    throw new Error('Missing socketWrapper');
                }
                if (!self.clock) {
                    console.error('Missing clock');
                    throw new Error('Missing clock');
                }
            },
            afterCreate() {
                this.verifyServices();
            },
        };
    });

export interface UIServicesAwareViewModelType extends Instance<typeof UIServicesAwareViewModel> {}
