import React, {FC} from 'react';
import {Modal} from 'antd';
import {StripeAddPaymentMethodContext} from '../../components/payments/StripeAddPaymentMethodContext';
import {StripeAddPaymentMethodForm} from '../../components/payments/stripePaymentMethodForm/StripeAddPaymentMethodForm';
import {PaymentMethodTypeEnum} from '@joyrideautos/auction-core/src/dtos/PaymentsDto';

interface AddPaymentModalProps {
    handleCancel: () => void;
    isOpen: boolean;
    paymentMethodTypes: PaymentMethodTypeEnum[];
}

export const AddPaymentModal: FC<AddPaymentModalProps> = ({handleCancel, isOpen, paymentMethodTypes}) => {
    return (
        <Modal title="Add payment method" open={isOpen} footer={null} onCancel={handleCancel} destroyOnClose={true}>
            <StripeAddPaymentMethodContext paymentMethodTypes={paymentMethodTypes}>
                <StripeAddPaymentMethodForm onClose={() => handleCancel()} isRedirectAlways={false} />
            </StripeAddPaymentMethodContext>
        </Modal>
    );
};
