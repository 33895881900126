import {Instance, destroy, types} from 'mobx-state-tree';
import primitives from '@joyrideautos/ui-models/src/Primitives';
import {RouteModel} from '../../router/RouteModel';
import {BreadcrumbModelType} from '../../router/BreadcrumbModel';

const PresentationStore = types
    .model('PresentationStore')
    .props({
        footerTop: primitives.number(),
        headerHeight: primitives.number(),
        // can be updated in the page presenter if needed
        containerClassName: primitives.string('container'),
        isMobile: primitives.boolean(),
        liveChatVisible: primitives.boolean(true),
        liveChatVerticalOffset: primitives.number(0),
        footerVisible: primitives.boolean(false),
        needScrollToTop: primitives.boolean(),
        routeModel: types.optional(RouteModel, {}),
    })
    .volatile(() => ({
        breadcrumb: null as BreadcrumbModelType | null,
    }))
    .actions((self) => ({
        setBreadcrumbModel(model?: BreadcrumbModelType) {
            if (self.breadcrumb) {
                const breadcrumb = self.breadcrumb;
                self.breadcrumb = null;
                requestAnimationFrame(() => {
                    destroy(breadcrumb);
                });
            }
            self.breadcrumb = model ?? null;
        },
    }));

export default PresentationStore;
export interface PresentationStoreType extends Instance<typeof PresentationStore> {}

export interface HasPresentationStore {
    presentationStore: PresentationStoreType;
}
