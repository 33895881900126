import {FeeSchedule} from '../dtos/SellerDto';
import {validateMoney} from './utils';
import {Money} from '../rules/Money';

export class AdminFeeCalculator {
    private constructor(private adminFeeAmount: Money) {}

    static getInstance(adminFeeAmount: Money) {
        return new AdminFeeCalculator(adminFeeAmount);
    }

    static getEmptyInstance() {
        return new AdminFeeCalculator(Money.fromCents(0));
    }

    static getInstanceWithFeeSchedule(
        feeSchedule: FeeSchedule,
        options: {
            isLicensedBuyer: boolean;
        }
    ) {
        const adminFee =
            options.isLicensedBuyer && feeSchedule.licensedBuyerExcludeFixedFee
                ? 0
                : (feeSchedule && feeSchedule.fixedFee) || 0;
        if (!isFinite(adminFee)) {
            throw new Error(`wrong format: adminFee ${adminFee}`);
        }
        return new AdminFeeCalculator(Money.fromDollars(adminFee));
    }

    calculate(): Money {
        return this.adminFeeAmount;
    }

    validate() {
        validateMoney(this.adminFeeAmount, 'adminFeeAmountInCent');
    }
}
