import {types} from 'mobx-state-tree';
import AuthUserStore from '../AuthUserStore';
import {RegionsStore} from '../RegionsStore';
import {AuctionSeriesStore} from '../AuctionSeriesStore';
import {AuctionsStore} from '../AuctionsStore';
import {SellersStore} from '../SellersStore';
import {BidderStatusStore} from '../BidderStatusStore';
import {FavoritesStore} from '../FavoritesStore';
import {LiveAuctionStateStore} from '../LiveAuctionStateStore';
import {WinningBidStore} from '../WinningBidStore';
import {ManagerRoleStore} from '../ManagerRoleStore';
import {SellerRoleStore} from '../SellerRoleStore';
import {AdminRoleStore} from '../AdminRoleStore';
import {UserStore} from '../UserStore';
import {FeatureFlagStore} from '../FeatureFlagStore';
import RefsStore from '../refsStore/RefsStore';
import {ItemsStore} from '../ItemsStore';
import {WonItemsStore} from '../WonItemsStore';
import {MediaStore} from '../MediaStore';
import {CcHoldStore} from '../CcHoldStore';
import {ItemPaymentsStore} from '../ItemPaymentsStore';
import {GlobalConfigStore} from '../GlobalConfigStore';
import {AppStore} from '../AppStore';
import {OrdersStore} from '../OrdersStore';
import {ClaimSessionsStore} from '../ClaimSessionsStore';

const CommonRootStore = types.model('CommonRootStore', {
    itemsStore: ItemsStore,
    wonItemsStore: WonItemsStore,
    authUserStore: AuthUserStore,
    featureFlagStore: FeatureFlagStore,
    regionsStore: RegionsStore,
    favoritesStore: FavoritesStore,
    auctionSeriesStore: AuctionSeriesStore,
    auctionsStore: AuctionsStore,
    sellersStore: SellersStore,
    winningBidStore: WinningBidStore,
    biddersStatuses: BidderStatusStore,
    managerRoleStore: ManagerRoleStore,
    sellerRoleStore: SellerRoleStore,
    adminRoleStore: AdminRoleStore,
    liveAuctionStateStore: LiveAuctionStateStore,
    userStore: UserStore,
    refsStore: RefsStore,
    mediaStore: MediaStore,
    // TODO: (future) fix typescript errors
    // bidsStore: BidsStore,
    ccHoldStore: CcHoldStore,
    paymentsStore: ItemPaymentsStore,
    // presentationStore: PresentationStore,
    globalConfigStore: GlobalConfigStore,
    appStore: AppStore,
    ordersStore: OrdersStore,
    claimSessionsStore: ClaimSessionsStore,
});

export default CommonRootStore;
