import * as s from 'superstruct';
import {WinningBidDto} from './WinningBidDto';
import {ItemStatusEnum, PersistedItemStatusEnum} from '../types/ItemTypes';
import {FeePriceDto} from './FeePriceDto';
import {Timestamp, WithKey} from '../types/common';
import {lodashGroupBy} from '@joyrideautos/auction-utils/src/arrayUtils';
import {toUpper} from '@joyrideautos/auction-utils/src/stringUtils';
import {toNumber} from '@joyrideautos/auction-utils/src/numberUtil';
import {ItemImageInfo} from './ItemMediaDto';
import {ItemInfoValidation} from '../types/validations/itemValidation';
import {AuctionOccurrenceDto} from './AuctionOccurrenceDto';

export const UNKNOWN_ITEM_VALUE = 'unknown';
export const UNKNOWN_YEAR_VALUE = 0;

export function toStoredYearValue(year: string | number | null | undefined) {
    return year ?? UNKNOWN_YEAR_VALUE;
}

export function isUnknownYearValue(year: number | string | null | undefined) {
    return (toNumber(year) ?? UNKNOWN_YEAR_VALUE) === UNKNOWN_YEAR_VALUE;
}

export function toFormattedYearValue(year: number | null | undefined) {
    return toUpper(year === UNKNOWN_YEAR_VALUE ? UNKNOWN_ITEM_VALUE : year, UNKNOWN_ITEM_VALUE);
}

export type ItemPath = {
    regionId: string;
    auctionId: string;
    itemId: string;
};

export function itemPathComparator(a: ItemPath, b: ItemPath) {
    return a.regionId === b.regionId && a.auctionId === b.auctionId && a.itemId === b.itemId;
}

export function isItemPath(input: any): input is ItemPath {
    return input.regionId !== undefined && input.auctionId !== undefined && input.itemId !== undefined;
}

export type ItemInfo = s.Infer<typeof ItemInfoValidation>;

export enum ArchiveReasonEnum {
    RELEASED = 'released',
    RELISTED = 'relisted',
    SOLD_OFFLINE = 'sold offline',
    ABSENT_IN_DATA_FEED = 'absent_in_data_feed', // = absent in the data feed from original provider (already sold in Copart)
}

export enum AuctionItemImportSourceEnum {
    G_DRIVE = 'G_DRIVE',
    ARIES = 'ARIES',
    REST_API = 'REST_API',
    WEB_UI_VIN_LIST = 'WEB_UI_VIN_LIST',
    WEB_UI_FILE_UPLOAD = 'WEB_UI_FILE_UPLOAD',
    WEB_UI_FILE_IMPORT = 'WEB_UI_FILE_IMPORT_TO_AUCTION',
    CLONED_ITEM = 'CLONED_ITEM',
}

export enum ImpoundFeeCategoryEnum {
    STORAGE = 'STORAGE',
    TOW = 'TOW',
    LIEN = 'LIEN',
}

export type ImpoundFeeItem = {
    feeDate: string;
    feeAmountInCents: number;
    taxAmountInCents?: number;
};

export type ImpoundFees = Partial<Record<ImpoundFeeCategoryEnum, ImpoundFeeItem[]>>;

export interface ItemCustomField {
    key: string;
    value: string;
}

export interface BaseItemDto extends ItemImageInfo {
    itemId: string;
    sellerId: string;
    info: ItemInfo;
    feePrice?: FeePriceDto | null;
    reservePrice?: number | null;
    startingBid?: number | null;
    buyNowAmount?: number | null;
    locationId: string;
    transId?: string;
    archiveReason?: ArchiveReasonEnum;
    relisted?: boolean;
    isAcceptingOffers?: boolean;
    importedFrom?: AuctionItemImportSourceEnum;
    importGroupId?: string;
    impoundFees?: ImpoundFees;
    customFields?: Record<string, ItemCustomField>;
}

export interface ItemStatusTimestamp<T = Timestamp> {
    publishedAt?: T;
    storedAt?: T;
    candidateAt?: T;
    soldAt?: T;
    unsoldAt?: T;
    paidAt?: T;
    claimedAt?: T;
    archivedAt?: T;
    deletedAt?: T;
}

export interface ItemMarkedAsStatusBy<T = string> {
    markedAsUnsoldBy?: T;
    markedAsArchivedBy?: T;
    markedAsClaimedBy?: T;
    markedAsPublishedBy?: T;
}

export type ItemStatusTimestampFieldsType = keyof Required<ItemStatusTimestamp>;

export enum SoldStatusMetaEnum {
    AWATING_DEPOSIT = 'awating deposit',
    DEPOSIT_FAILED = 'failed deposit',
    AWAITING_PNF_PAYMENT = 'awaiting pnf payment',
    PAYMENT_PROCESSING = 'payment processing',
    PAYMENT_OVERDUE = 'payment overdue',
    PAYMENT_EXPIRED = 'payment expired',
}

export interface PersistedStatusMetadata {
    reason: ArchiveReasonEnum | SoldStatusMetaEnum | string;
}

export interface BuyerInfoDto {
    firstName: string;
    lastName: string;
    companyName?: string;
    email: string;
    streetAddress: string;
    apartmentOrSuite?: string;
    city?: string;
    state?: string;
    zip?: string;
    phone: string;
}

export interface BuyerInfoWithUid extends BuyerInfoDto {
    uid?: string;
}

export function isBuyerInfo(data: any): data is BuyerInfoDto {
    return data.firstName && data.lastName && data.email && data.streetAddress && data.phone;
}

export interface PersistedItem extends BaseItemDto, ItemStatusTimestamp, ItemMarkedAsStatusBy {
    idx: number;
    regionId?: string;
    auctionId?: string;
    // result is synced to PersistedItem only after auction is ended
    result?: WinningBidDto;
    // WARNING! currentResult is updated in triggers and may be outdated; don't use when consistency is required and
    // fetch the data from RTDB instead
    currentResult?: WinningBidDto;
    status: PersistedItemStatusEnum;
    statusMeta?: PersistedStatusMetadata;
    sellerVehicleId?: string;
    sellerNotes?: string;
    totalBids?: number;
    buyerInfo?: BuyerInfoDto;
    reserveUpdatedBy?: string;
    reserveUpdatedAt?: Timestamp;
    startingBidUpdatedBy?: string;
    startingBidUpdatedAt?: Timestamp;
    forbiddenAuctionsIds?: string[];
    claimSessionKey?: string;
    // The following field are specified only during REST API import and preserved for history (not used anywhere):
    strictAuctionDate?: boolean;
    auctionSeries?: string;
    requestedAuctionDate?: Timestamp;
    publishAtDate?: Timestamp;
    paymentDueWindowHours?: number;
    paymentExpirationTimeHours?: number;
    paymentOverdueAt?: Timestamp;
    paymentExpiredAt?: Timestamp;
    orderKey?: string;
}

export interface ItemDto extends BaseItemDto {
    idx: number;
    prev?: string;
    next?: string;
    persistenceKey?: string;
    publishedAt?: string;
    status: ItemStatusEnum;
    result?: WinningBidDto;
}

export const groupItemsBy = (fieldName: keyof WithKey<PersistedItem>) => (items: WithKey<PersistedItem>[]) => {
    return lodashGroupBy(items, (item: {[x: string]: any}) => item[fieldName]);
};

export function getItemBuyerId(item: PersistedItem): string | undefined {
    return item?.result?.billingUid || item?.result?.uid || undefined;
}

export function getMinimumBidAmount(auction?: AuctionOccurrenceDto, item?: BaseItemDto): number {
    return item?.startingBid || auction?.settings.minimumBid || 0;
}
