import {flow, getParent, getSnapshot, types, applySnapshot, Instance} from 'mobx-state-tree';
import RootStoreType from './Shared';
import {LoadingStatus} from '../utils/LoadingStatus';
import BaseStore from './BaseStore';
import {DatabaseErrorEnum} from '@joyrideautos/ui-services/src/firebase/Database';

export const AdminRoleStore = BaseStore.named('AdminRoleStore')
    .props({
        adminRoleUsers: types.array(types.string),
    })
    .volatile(() => ({
        disposer: null as null | (() => void),
        loadingStatus: new LoadingStatus(),
    }))
    .views((self) => {
        return {
            get authUserStore() {
                return (getParent(self) as RootStoreType).authUserStore;
            },
            get auctionsStore() {
                return (getParent(self) as RootStoreType).auctionsStore;
            },
            getUserId() {
                const userInfo = this.authUserStore.userInfo;
                return userInfo && userInfo.uid;
            },
            get allAdmins() {
                return getSnapshot(self.adminRoleUsers);
            },
            isUserAdmin(uid: string) {
                return Boolean(self.adminRoleUsers.find((adminUid) => adminUid === uid));
            },
        };
    })
    .actions((self) => {
        return {
            load: flow(function* () {
                try {
                    self.loadingStatus.setInProgress();
                    const userIds: string[] | undefined = yield self.adminRoleService.fetchRoleAdmin();
                    if (!userIds) {
                        return;
                    }
                    applySnapshot(self.adminRoleUsers, userIds);
                    self.loadingStatus.setReady();
                } catch (error: any) {
                    if (error.code === DatabaseErrorEnum.PERMISSION_DENIED) {
                        self.loadingStatus.setReady();
                    } else {
                        self.loadingStatus.setError(error);
                    }
                }
            }),
        };
    })
    .actions((self) => {
        return {
            beforeDestroy: function () {
                self.disposer && self.disposer();
            },
        };
    });

export interface AdminRoleStoreType extends Instance<typeof AdminRoleStore> {}

export interface HasAdminRoleStore {
    adminRoleStore: AdminRoleStoreType;
}
