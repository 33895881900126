import {PaymentMethodTypeEnum} from '@joyrideautos/auction-core/src/dtos/PaymentsDto';
import {useViewModel} from '@joyrideautos/ui-models/src/hooks/useViewModel';
import {Loading} from '@joyrideautos/web-common-components/src/components/loading/Loading';
import {Elements} from '@stripe/react-stripe-js';
import {observer} from 'mobx-react-lite';
import {FC, PropsWithChildren} from 'react';
import {StripeAddPaymentMethodContextViewModel} from './StripeAddPaymentMethodContextViewModel';

interface StripeAddPaymentMethodContextProps extends PropsWithChildren {
    paymentMethodTypes: PaymentMethodTypeEnum[];
}

export const StripeAddPaymentMethodContext: FC<StripeAddPaymentMethodContextProps> = observer(
    ({children, paymentMethodTypes}) => {
        const {viewModel} = useViewModel(StripeAddPaymentMethodContextViewModel, {
            paymentMethodTypes,
            clientSecret: '',
        });

        if (!viewModel || viewModel.loading) {
            return <Loading />;
        }

        return (
            // @ts-ignore
            <Elements stripe={viewModel.stripePromise} options={viewModel.stripeElementsOptions}>
                {children}
            </Elements>
        );
    }
);
