import React, {ReactNode} from 'react';
import loadable from '@loadable/component';
import {Routes} from './Routes';
import Auth from '@layouts/Auth';
import {BreadcrumbModel, BreadcrumbModelType} from '@joyrideautos/web-common-components/src/router/BreadcrumbModel';
import {BreadcrumbLabels} from '@joyrideautos/web-common-components/src/layout/BreadcrumbLabels';
import {types} from 'mobx-state-tree';
import AuctionRoutesAwareViewModel from '@pageComponents/models/AuctionRoutesAwareViewModel';
import RootStoreType from '@joyrideautos/ui-models/src/stores/Shared';
import {when} from 'mobx';
import RootStoreAwareViewModel from '@joyrideautos/web-common-components/src/models/RootStoreAwareViewModel';
import {PersistedItemStatusEnum} from '@joyrideautos/auction-core/src/types/ItemTypes';
import {MyInventoryTabEnum} from './pages/myInventory/MyInventoryTabEnum';
import {PageContainer} from '@joyrideautos/web-common-components/src/components/PageContainer';

function makeBreadcrumbForStatus(status: PersistedItemStatusEnum, countValue: string) {
    const sections: {[status in PersistedItemStatusEnum]: {label: string; to?: string} | null} = {
        [PersistedItemStatusEnum.STORED]: {
            label: `${countValue} Stored vehicles`,
            to: Routes.MY_INVENTORY({hash: MyInventoryTabEnum.STORED}),
        },
        [PersistedItemStatusEnum.CANDIDATE]: {
            label: `${countValue} Candidate vehicles`,
            to: Routes.MY_INVENTORY({hash: MyInventoryTabEnum.CANDIDATE}),
        },
        [PersistedItemStatusEnum.PUBLISHED]: {
            label: `${countValue} Scheduled vehicles`,
            to: Routes.MY_INVENTORY({hash: MyInventoryTabEnum.PUBLISHED}),
        },
        [PersistedItemStatusEnum.SOLD]: {
            label: `${countValue} Sold vehicles`,
            to: Routes.MY_INVENTORY({hash: MyInventoryTabEnum.SOLD}),
        },
        [PersistedItemStatusEnum.UNSOLD]: {
            label: `${countValue} Unsold vehicles`,
            to: Routes.MY_INVENTORY({hash: MyInventoryTabEnum.UNSOLD}),
        },
        [PersistedItemStatusEnum.PAID]: {
            label: `${countValue} Paid vehicles`,
            to: Routes.MY_INVENTORY({hash: MyInventoryTabEnum.PAYMENT_RECEIVED}),
        },
        [PersistedItemStatusEnum.CLAIMED]: {
            label: `${countValue} Picked Up vehicles`,
            to: Routes.MY_INVENTORY({hash: MyInventoryTabEnum.CLAIMED}),
        },
        [PersistedItemStatusEnum.ARCHIVED]: null,
    };
    return sections[status];
}

interface AppRoute {
    path: string;
    render: () => ReactNode;
    exact?: boolean;
}

function createAppRoute({
    path,
    load,
    allowAnonymous,
    getBreadcrumbModel,
}: {
    path: string;
    load: () => Promise<any>;
    allowAnonymous?: boolean;
    getBreadcrumbModel?: (rootStore: RootStoreType, params?: any) => BreadcrumbModelType;
}) {
    return {
        exact: true,
        path,
        render: ((load: () => Promise<any>, path: string, allowAnonymous = true) => {
            return () => {
                const Page = loadable(async () => (await load()).default);
                return (
                    <Auth allowAnonymous={allowAnonymous}>
                        <PageContainer
                            path={path}
                            getBreadcrumbModel={
                                getBreadcrumbModel ?? ((rootStore) => BreadcrumbModel.create(undefined, {rootStore}))
                            }>
                            <Page />
                        </PageContainer>
                    </Auth>
                );
            };
        })(load, path, allowAnonymous ?? true),
    };
}

export const staticRoutes: AppRoute[] = [
    createAppRoute({path: Routes.LANDING(), load: () => import('./pages/landingPage/LandingPage')}),
    createAppRoute({path: Routes.IMPERSONATE(), load: () => import('./pages/impersonate/ImpersonatePage')}),
    createAppRoute({path: Routes.SIGN_IN(), load: () => import('./pages/auth/SignInPage')}),
    createAppRoute({path: Routes.SIGN_UP(), load: () => import('./pages/auth/SignUpPage')}),
    createAppRoute({
        path: Routes.RESET_PASSWORD(),
        load: () => import('./pages/auth/resetPasswordPage/ResetPasswordPage'),
    }),
    createAppRoute({
        path: Routes.FORGOT_PASSORD(),
        load: () => import('./pages/auth/forgotPasswordPage/ForgotPasswordPage'),
    }),
    createAppRoute({path: Routes.VERIFY_USER_INFO(), load: () => import('./pages/auth/VerifyUserInfo')}),
];

export const anonymousRoutes: AppRoute[] = [
    createAppRoute({path: Routes.HOME(), load: () => import('./pages/home/HomePage')}),
    createAppRoute({
        path: Routes.VEHICLE_PROFILE({
            pathParams: {regionId: ':regionId', auctionId: ':auctionId', itemId: ':itemId'},
        }),
        load: () => import('./pages/vehicleProfile/liveAuction/VehicleProfilePage'),
        getBreadcrumbModel: (rootStore, params) =>
            types
                .compose(BreadcrumbModel, AuctionRoutesAwareViewModel)
                .named('VehicleProfilePageBreadcrumbModel')
                .views((self) => ({
                    get auctionTitle() {
                        if (self.auction?.isListingAuctionType) {
                            return self.auction.name || self.endDateListing;
                        }
                        return self.startDate;
                    },
                }))
                .actions((self) => ({
                    afterCreate() {
                        self.disposers.push(
                            when(
                                () => Boolean(self.auction) && !self.isMobile,
                                () => {
                                    self.setSections([
                                        {label: BreadcrumbLabels.AUCTIONS, to: Routes.HOME()},
                                        {label: self.stateName, to: self.stateRoutePath},
                                        {label: self.regionTitle, to: self.regionRoutePath},
                                        {label: self.seriesTitle, to: self.seriesRoutePath},
                                        {label: self.auctionTitle, to: self.auctionRoutePath},
                                    ]);
                                }
                            )
                        );
                    },
                }))
                .create(
                    {
                        regionId: params?.regionId,
                        auctionId: params?.auctionId,
                    },
                    {rootStore}
                ),
    }),
    createAppRoute({
        path: Routes.AUCTION_OCCURRENCE({pathParams: {regionId: ':regionId', auctionId: ':auctionId'}}),
        load: () => import('./pages/auction/AuctionViewPage'),
        getBreadcrumbModel: (rootStore, params) =>
            types
                .compose(BreadcrumbModel, AuctionRoutesAwareViewModel)
                .named('AuctionPageBreadcrumbModel')
                .views((self) => ({
                    get auctionTitle() {
                        if (self.auction?.isListingAuctionType) {
                            return self.auction.name || self.endDateListing;
                        }
                        return self.startDate;
                    },
                }))
                .actions((self) => ({
                    afterCreate() {
                        self.disposers.push(
                            when(
                                () => !!self.auction && !self.isMobile,
                                () => {
                                    self.setSections([
                                        {label: BreadcrumbLabels.AUCTIONS, to: Routes.HOME()},
                                        {label: self.stateName, to: self.stateRoutePath},
                                        {label: self.regionTitle, to: self.regionRoutePath},
                                        {label: self.seriesTitle, to: self.seriesRoutePath},
                                        {label: self.auctionTitle},
                                    ]);
                                }
                            )
                        );
                    },
                }))
                .create(
                    {
                        regionId: params?.regionId,
                        auctionId: params?.auctionId,
                    },
                    {rootStore}
                ),
    }),
    createAppRoute({
        path: Routes.AUCTION_INFO({pathParams: {regionId: ':regionId', auctionId: ':auctionId'}}),
        load: () => import('./pages/auction/auctionInfo/AuctionInfoPage'),
        getBreadcrumbModel: (rootStore, params) =>
            types
                .compose(BreadcrumbModel, AuctionRoutesAwareViewModel)
                .named('AuctionInfoPageBreadcrumbModel')
                .views((self) => ({
                    get auctionTitle() {
                        if (self.auction?.isListingAuctionType) {
                            return self.auction.name || self.endDateListing;
                        }
                        return self.startDate;
                    },
                }))
                .actions((self) => ({
                    afterCreate() {
                        self.disposers.push(
                            when(
                                () => !!self.auction && !self.isMobile,
                                () => {
                                    self.setSections([
                                        {label: BreadcrumbLabels.AUCTIONS, to: Routes.HOME()},
                                        {label: self.stateName, to: self.stateRoutePath},
                                        {label: self.regionTitle, to: self.regionRoutePath},
                                        {label: self.seriesTitle, to: self.seriesRoutePath},
                                        {label: self.auctionTitle},
                                    ]);
                                }
                            )
                        );
                    },
                }))
                .create(
                    {
                        regionId: params?.regionId,
                        auctionId: params?.auctionId,
                    },
                    {rootStore}
                ),
    }),
    createAppRoute({
        path: Routes.AUCTION_SERIES({pathParams: {regionId: ':regionId', seriesId: ':seriesId'}}),
        load: () => import('./pages/auctionSeries/AuctionSeriesPage'),
        getBreadcrumbModel: (rootStore) =>
            types
                .compose(BreadcrumbModel, RootStoreAwareViewModel)
                .named('SeriesPageBreadcrumbModel')
                .views((self) => ({
                    get regionId() {
                        return (self.presentationStore.routeModel.getRouteParamByName('regionId') || '').toUpperCase();
                    },
                    get region() {
                        return self.regionsStore.findRegion(this.regionId);
                    },
                    get regionTitle() {
                        return this.region?.renderedDisplayName ?? '';
                    },
                    get stateId() {
                        return this.region?.stateId ?? '';
                    },
                    get stateName() {
                        return this.region?.state ?? '';
                    },
                    get auctionSeriesId() {
                        const seriesId = self.presentationStore.routeModel.getRouteParamByName('seriesId');
                        if (!seriesId) {
                            return;
                        }
                        return Number.parseInt(seriesId);
                    },
                    get auctionSeries() {
                        if (!this.auctionSeriesId) {
                            return;
                        }
                        return self.auctionSeriesStore.findSeriesForRegion(this.regionId, this.auctionSeriesId);
                    },
                    get seriesTitle() {
                        return this.auctionSeries?.title ?? '';
                    },
                }))
                .actions((self) => ({
                    afterCreate() {
                        self.disposers.push(
                            when(
                                () => self.region !== undefined && self.auctionSeries !== undefined,
                                () => {
                                    self.setSections([
                                        {label: BreadcrumbLabels.AUCTIONS, to: Routes.AUCTIONS()},
                                        {
                                            label: self.stateName,
                                            to: Routes.AUCTIONS_BY_STATE({
                                                pathParams: {stateId: self.stateId},
                                            }),
                                        },
                                        {
                                            label: self.regionTitle,
                                            to: Routes.AUCTION_REGION({pathParams: {regionId: self.regionId}}),
                                        },
                                        {label: self.seriesTitle},
                                    ]);
                                }
                            )
                        );
                    },
                }))
                .create({}, {rootStore}),
    }),
    createAppRoute({
        path: Routes.AUCTION_REGION({pathParams: {regionId: ':regionId'}}),
        load: () => import('./pages/auctionRegion/AuctionRegionPage'),
        getBreadcrumbModel: (rootStore) =>
            types
                .compose(BreadcrumbModel, RootStoreAwareViewModel)
                .named('RegionPageBreadcrumbModel')
                .views((self) => ({
                    get regionId() {
                        return (self.presentationStore.routeModel.getRouteParamByName('regionId') || '').toUpperCase();
                    },
                    get region() {
                        return self.regionsStore.findRegion(this.regionId);
                    },
                    get regionName() {
                        return this.region?.renderedDisplayName ?? '';
                    },
                    get stateId() {
                        return this.region?.stateId ?? '';
                    },
                    get stateName() {
                        return this.region?.state ?? '';
                    },
                }))
                .actions((self) => ({
                    afterCreate() {
                        self.disposers.push(
                            when(
                                () => self.region !== undefined,
                                () => {
                                    self.setSections([
                                        {label: BreadcrumbLabels.AUCTIONS, to: Routes.HOME()},
                                        {
                                            label: self.stateName,
                                            to: Routes.AUCTIONS_BY_STATE({pathParams: {stateId: self.stateId}}),
                                        },
                                        {label: self.regionName},
                                    ]);
                                }
                            )
                        );
                    },
                }))
                .create({}, {rootStore}),
    }),
    createAppRoute({
        path: Routes.AUCTION_STREAMS({pathParams: {regionId: ':regionId', seriesId: ':seriesId'}}),
        load: () => import('./pages/liveVideo/youtube/AuctionStreamsPage'),
        getBreadcrumbModel: (rootStore) =>
            types
                .compose(BreadcrumbModel, RootStoreAwareViewModel)
                .named('AuctionStreamsPageBreadcrumbModel')
                .views((self) => ({
                    get regionId() {
                        return (self.presentationStore.routeModel.getRouteParamByName('regionId') || '').toUpperCase();
                    },
                    get auctionSeriesId() {
                        const seriesId = self.presentationStore.routeModel.getRouteParamByName('seriesId');
                        if (!seriesId) {
                            return;
                        }
                        return Number.parseInt(seriesId);
                    },
                    get auctionSeries() {
                        if (!this.auctionSeriesId) {
                            return;
                        }
                        return self.auctionSeriesStore.findSeriesForRegion(this.regionId, this.auctionSeriesId);
                    },
                    get seriesTitle() {
                        return this.auctionSeries?.title ?? '';
                    },
                }))
                .actions((self) => ({
                    afterCreate() {
                        self.disposers.push(
                            when(
                                () => self.auctionSeries !== undefined,
                                () => {
                                    self.setSections([
                                        {
                                            label: self.seriesTitle,
                                            to: Routes.AUCTION_SERIES({
                                                pathParams: {
                                                    regionId: self.regionId,
                                                    seriesId: self.auctionSeriesId!,
                                                },
                                            }),
                                        },
                                        {label: 'Live Stream'},
                                    ]);
                                }
                            )
                        );
                    },
                }))
                .create({}, {rootStore}),
    }),
    createAppRoute({
        path: Routes.AUCTIONS(),
        load: () => import('./pages/auctions/AuctionsPage'),
    }),
    createAppRoute({
        path: Routes.MY_VEHICLES_FAVORITES(),
        load: () => import('./pages/favorites/FavoritesPage'),
    }),
    createAppRoute({
        path: Routes.MY_VEHICLES_BUY_NOW(),
        load: () => import('./pages/activeOffersWon/ActiveOffersWonPage'),
    }),
    createAppRoute({
        path: Routes.MY_VEHICLES_AUCTIONS(),
        load: () => import('./pages/myVehiclesAuctions/MyVehiclesAuctionsPage'),
    }),
    createAppRoute({
        path: Routes.MY_VEHICLES_AUCTION_WON({pathParams: {auctionId: ':auctionId', regionId: ':regionId'}}),
        load: () => import('./pages/myVehiclesAuctionWon/MyVehiclesAuctionWonPage'),
        getBreadcrumbModel: (rootStore) =>
            types
                .compose(BreadcrumbModel, RootStoreAwareViewModel)
                .named('NyVehiclesAuctionWonBreadcrumbModel')
                .actions((self) => ({
                    afterCreate() {
                        self.setSections([
                            {
                                label: BreadcrumbLabels.MY_VEHICLES_AUCTIONS,
                                to: Routes.MY_VEHICLES_AUCTIONS(),
                            },
                            {
                                label: BreadcrumbLabels.MY_VEHICLES_AUCTION_WON,
                                to: Routes.MY_VEHICLES_AUCTIONS({
                                    hash: 'Won',
                                }),
                            },
                        ]);
                    },
                }))
                .create({}, {rootStore}),
    }),
    createAppRoute({
        path: Routes.AUCTIONS_BY_STATE({pathParams: {stateId: ':stateId'}}),
        load: () => import('./pages/auctionState/AuctionStatePage'),
        getBreadcrumbModel: (rootStore) =>
            types
                .compose(BreadcrumbModel, RootStoreAwareViewModel)
                .named('StatePageBreadcrumbModel')
                .views((self) => ({
                    get stateId() {
                        return (self.presentationStore.routeModel.getRouteParamByName('stateId') ?? '').toUpperCase();
                    },
                    get stateName() {
                        return self.regionsStore.getStateById(this.stateId) ?? '';
                    },
                }))
                .actions((self) => ({
                    afterCreate() {
                        self.setSections([
                            {label: BreadcrumbLabels.AUCTIONS, to: Routes.AUCTIONS()},
                            {label: self.stateName},
                        ]);
                    },
                }))
                .create({}, {rootStore}),
    }),
    createAppRoute({
        path: Routes.SEARCH(),
        load: () => import('./pages/search/SearchPage'),
        getBreadcrumbModel: (rootStore) =>
            BreadcrumbModel.named('SearchPageBreadcrumbModel')
                .actions((self) => ({
                    afterCreate() {
                        self.setSections([
                            {label: BreadcrumbLabels.HOME, to: Routes.HOME()},
                            {label: BreadcrumbLabels.SEARCH},
                        ]);
                    },
                }))
                .create({}, {rootStore}),
    }),
];

export const authUserRoutes: AppRoute[] = [
    createAppRoute({
        path: Routes.PROFILE(),
        load: () => import('./pages/profile/accountPage/AccountPage'),
        allowAnonymous: false,
    }),
    createAppRoute({
        path: Routes.PROFILE_SETTINGS(),
        load: () => import('./pages/profile/accountSettingsPage/AccountSettingsPage'),
        allowAnonymous: false,
    }),
    createAppRoute({
        path: Routes.MY_INVENTORY(),
        load: () => import('./pages/myInventory/MyInventoryPage'),
        allowAnonymous: false,
    }),
    createAppRoute({
        path: Routes.MY_AUCTIONS(),
        load: () => import('./pages/myAuctions/MyAuctionsPage'),
        allowAnonymous: false,
    }),
    createAppRoute({
        path: Routes.MY_VEHICLES_PROFILE_RESULT_BUYER({pathParams: {itemKey: ':itemKey'}}),
        load: () => import('./pages/vehicleProfile/postAuction/VehicleProfilePageSold'),
        allowAnonymous: false,
    }),
    createAppRoute({
        path: Routes.MY_INVENTORY_VEHICLE_PROFILE({pathParams: {itemKey: ':itemKey'}}),
        load: () => import('./pages/vehicleProfile/sellerVehicleDetails/SellerVehicleDetailsPage'),
        getBreadcrumbModel: (rootStore) =>
            types
                .compose(BreadcrumbModel, RootStoreAwareViewModel)
                .named('MyInventoryVehicleProfilePageBreadcrumbModel')
                .views((self) => ({
                    get itemKey() {
                        return self.presentationStore.routeModel.getRouteParamByName('itemKey');
                    },
                    get itemsStore() {
                        return self.itemsStoreFactory.persistentItemsStore;
                    },
                    get persistedItem() {
                        if (!this.itemKey) {
                            return;
                        }
                        return this.itemsStore.getItemByPersistenceKey(this.itemKey);
                    },
                    get status(): PersistedItemStatusEnum | undefined {
                        return this.persistedItem?.status;
                    },
                    get sellerId() {
                        return self.sellerSessionStorage.sellerId;
                    },
                }))
                .actions((self) => ({
                    afterCreate() {
                        self.disposers.push(
                            when(
                                () => !!self.sellerId,
                                () => {
                                    self.disposers.push(
                                        self.rootStore.itemsService.subscribeToItemsCount(
                                            self.sellerId!,
                                            (itemsCount) => {
                                                if (itemsCount) {
                                                    if (!itemsCount || !self.status || !itemsCount[self.status]) {
                                                        return;
                                                    }
                                                    const breadcrumb = makeBreadcrumbForStatus(
                                                        self.status,
                                                        String(itemsCount[self.status] || 0)
                                                    );
                                                    if (breadcrumb) {
                                                        self.setSections([
                                                            {
                                                                label: BreadcrumbLabels.MY_INVENTORY,
                                                                to: Routes.MY_INVENTORY(),
                                                            },
                                                            breadcrumb,
                                                        ]);
                                                    }
                                                }
                                            }
                                        )
                                    );
                                }
                            )
                        );
                    },
                }))
                .create({}, {rootStore}),
        allowAnonymous: false,
    }),
    createAppRoute({
        path: Routes.MY_INVENTORY_VEHICLE_PROFILE_WITH_STATUS({pathParams: {itemKey: ':itemKey', status: ':status'}}),
        load: () => import('./pages/vehicleProfile/sellerVehicleDetails/SellerVehicleDetailsPage'),
        getBreadcrumbModel: (rootStore) =>
            types
                .compose(BreadcrumbModel, RootStoreAwareViewModel)
                .named('MyInventoryVehicleProfilePageBreadcrumbModel')
                .views((self) => ({
                    get itemKey() {
                        return self.presentationStore.routeModel.getRouteParamByName('itemKey');
                    },
                    get status(): PersistedItemStatusEnum | undefined {
                        return self.presentationStore.routeModel.getRouteParamByName<PersistedItemStatusEnum>('status');
                    },
                    get sellerId() {
                        return self.sellerSessionStorage.sellerId;
                    },
                }))
                .actions((self) => ({
                    afterCreate() {
                        if (self.status) {
                            const breadcrumb = makeBreadcrumbForStatus(self.status, '...');
                            if (breadcrumb) {
                                self.setSections([
                                    {
                                        label: BreadcrumbLabels.MY_INVENTORY,
                                        to: Routes.MY_INVENTORY(),
                                    },
                                    breadcrumb,
                                ]);
                            }
                        }
                        self.disposers.push(
                            when(
                                () => !!self.sellerId,
                                () => {
                                    self.disposers.push(
                                        self.rootStore.itemsService.subscribeToItemsCount(
                                            self.sellerId!,
                                            (itemsCount) => {
                                                if (itemsCount) {
                                                    if (!itemsCount || !self.status || !itemsCount[self.status]) {
                                                        return;
                                                    }
                                                    const breadcrumb = makeBreadcrumbForStatus(
                                                        self.status,
                                                        String(itemsCount[self.status] || 0)
                                                    );
                                                    if (breadcrumb) {
                                                        self.setSections([
                                                            {
                                                                label: BreadcrumbLabels.MY_INVENTORY,
                                                                to: Routes.MY_INVENTORY(),
                                                            },
                                                            breadcrumb,
                                                        ]);
                                                    }
                                                }
                                            }
                                        )
                                    );
                                }
                            )
                        );
                    },
                }))
                .create({}, {rootStore}),
        allowAnonymous: false,
    }),
    createAppRoute({
        path: Routes.MY_INVENTORY_VEHICLE_PROFILE_PREVIEW({pathParams: {itemKey: ':itemKey', status: ':status'}}),
        load: () => import('./pages/vehicleProfile/sellerVehicleDetails/preview/SellerVehiclePreviewPage'),
        getBreadcrumbModel: (rootStore) =>
            types
                .compose(BreadcrumbModel, RootStoreAwareViewModel)
                .named('MyInventoryVehiclePreviewPageBreadcrumbModel')
                .views((self) => ({
                    get itemKey() {
                        return self.presentationStore.routeModel.getRouteParamByName('itemKey');
                    },
                    get itemsStore() {
                        return self.itemsStoreFactory.persistentItemsStore;
                    },
                    get persistedItem() {
                        if (!this.itemKey) {
                            return;
                        }
                        return this.itemsStore.getItemByPersistenceKey(this.itemKey);
                    },
                    get status(): PersistedItemStatusEnum | undefined {
                        return self.presentationStore.routeModel.getRouteParamByName<PersistedItemStatusEnum>('status');
                    },
                }))
                .actions((self) => ({
                    afterCreate() {
                        if (self.itemKey) {
                            self.setSections([
                                {label: BreadcrumbLabels.MY_VEHICLES, to: Routes.MY_VEHICLES_AUCTIONS()},
                                {
                                    label: self.persistedItem?.info.vin ?? 'NO VIN',
                                    to: Routes.MY_INVENTORY_VEHICLE_PROFILE_WITH_STATUS({
                                        pathParams: {
                                            itemKey: self.itemKey,
                                            status: PersistedItemStatusEnum.CANDIDATE,
                                        },
                                    }),
                                },
                                {label: 'Preview'},
                            ]);
                        }
                    },
                }))
                .create({}, {rootStore}),
        allowAnonymous: false,
    }),
    createAppRoute({
        path: Routes.NOTIFICATIONS(),
        load: () => import('./pages/notifications/NotificationsPage'),
        allowAnonymous: false,
    }),
    createAppRoute({
        path: Routes.PAYMENT_RESULT_SUCCESS({pathParams: {}}),
        load: () => import('./pages/payments/PaymentResultSuccessPage'),
        getBreadcrumbModel: (rootStore) =>
            types
                .compose(BreadcrumbModel, RootStoreAwareViewModel)
                .named('PaymentResultSuccessPageBreadcrumbModel')
                .actions((self) => ({
                    afterCreate() {
                        self.setSections([{label: BreadcrumbLabels.HOME, to: Routes.HOME()}]);
                    },
                }))
                .create({}, {rootStore}),
        allowAnonymous: false,
    }),
    createAppRoute({
        path: Routes.PAYMENT_RESULT_FAILED(),
        load: () => import('./pages/payments/PaymentResultFailedPage'),
        getBreadcrumbModel: (rootStore) =>
            types
                .compose(BreadcrumbModel, RootStoreAwareViewModel)
                .named('PaymentResultFailedPageBreadcrumbModel')
                .actions((self) => ({
                    afterCreate() {
                        self.setSections([{label: BreadcrumbLabels.HOME, to: Routes.HOME()}]);
                    },
                }))
                .create({}, {rootStore}),
        allowAnonymous: false,
    }),
    createAppRoute({
        path: Routes.PAYMENT_SUMMARY(), //string
        load: () => import('./pages/payments/paymentSummaryPage/PaymentSummaryPage'),
        getBreadcrumbModel: (rootStore) =>
            types
                .compose(BreadcrumbModel, RootStoreAwareViewModel)
                .named('PaymentSummaryPageBreadcrumbModel')
                .actions((self) => ({
                    afterCreate() {
                        self.setSections([
                            {
                                label: BreadcrumbLabels.BACK,
                                onClick: (router) => {
                                    router.goBack();
                                },
                            },
                        ]);
                    },
                }))
                .create({}, {rootStore}),
        allowAnonymous: false,
    }),
    createAppRoute({
        path: Routes.CHANGE_SELLER(),
        load: () => import('./pages/changeSeller/ChangeSellerPage'),
        allowAnonymous: false,
    }),
    createAppRoute({
        path: Routes.INCOMPLETE_PROFILE(),
        load: () => import('./pages/IncompleteProfilePage'),
    }),
    // Seller Release Flow
    createAppRoute({
        path: Routes.SELLER_RELEASE_FLOW_START(),
        load: () => import('./pages/sellerReleaseFlow/start/SellerReleaseFlowStartPage'),
        allowAnonymous: false,
    }),
    createAppRoute({
        path: Routes.SELLER_RELEASE_FLOW_VERIFY_BUYER({pathParams: {claimSessionKey: ':claimSessionKey'}}),
        load: () => import('./pages/sellerReleaseFlow/verifyBuyerInfo/SellerReleaseFlowVerifyBuyerInfoPage'),
        allowAnonymous: false,
    }),
    createAppRoute({
        path: Routes.SELLER_RELEASE_FLOW_ITEMS({pathParams: {claimSessionKey: ':claimSessionKey'}}),
        load: () => import('./pages/sellerReleaseFlow/items/SellerReleaseFlowItemsPage'),
    }),
    createAppRoute({
        path: Routes.CHECKOUT_SUMMARY(),
        load: () => import('./pages/checkout/checkoutSummary/CheckoutSummaryPage'),
        allowAnonymous: false,
    }),
    createAppRoute({
        path: Routes.CHECKOUT_PAYMENT({pathParams: {orderKey: ':orderKey'}}),
        load: () => import('./pages/checkout/checkoutPayment/CheckoutPaymentPage'),
        allowAnonymous: false,
    }),
    createAppRoute({
        path: Routes.CHECKOUT_PICK_UP_INSTRUCTIONS({pathParams: {orderKey: ':orderKey'}}),
        load: () => import('./pages/checkout/checkoutPickUpInstructions/CheckoutPickUpInstructionsPage'),
        allowAnonymous: false,
    }),
];

export function renderNotFoundPage() {
    const Page = loadable(async () => (await import('./pages/NotFoundPage')).default);
    return () => <Page />;
}
