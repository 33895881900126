import {flow, types} from 'mobx-state-tree';
import {observable} from 'mobx';
import i18n from '@joyrideautos/ui-common/src/i18n/i18next.config';

export const LanguageStore = types
    .model('LanguageStore', {
        currentLanguage: types.optional(types.string, i18n.language),
    })
    .volatile(() => ({
        tFn: observable.box(i18n.t),
    }))
    .views((self) => ({
        t(...params: Parameters<typeof i18n.t>) {
            return self.tFn.get()(...params);
        },
        hoursCount(time: number) {
            return time >= 1
                ? `${time} ${this.t(time === 1 ? 'common.hour' : 'common.hours')}`
                : this.t('common.lessThanHour');
        },
    }))
    .actions((self) => ({
        changeLanguage: flow(function* (language: string) {
            yield i18n.changeLanguage(language);
            self.currentLanguage = i18n.language;
            self.tFn.set(i18n.t);
        }),
    }));
