import {OrderErrorCodesEnum, OrderStatusEnum} from '@joyrideautos/auction-core/src/dtos/OrderDto';
import {PaymentTypeEnum} from '@joyrideautos/auction-core/src/dtos/PaymentsDto';
import {Instance, types} from 'mobx-state-tree';

const StripePaymentMethod = types.model('StripePaymentMethod', {
    card: types.maybe(
        types.model({
            brand: types.string,
            last4: types.string,
        })
    ),
    us_bank_account: types.maybe(
        types.model({
            bank_name: types.string,
            last4: types.string,
        })
    ),
    type: types.string,
});

const OrderError = types.model('OrderError', {
    code: types.enumeration(Object.values(OrderErrorCodesEnum)),
    description: types.maybe(types.string),
});

const StripeCharge = types.model('LatestCharge', {
    id: types.string,
    payment_method_details: types.maybe(StripePaymentMethod),
});

interface StripeChargeType extends Instance<typeof StripeCharge> {}

const PaymentIntentModel = types.model('PaymentIntentDtoValidation', {
    id: types.string,
    amount: types.number,
    currency: types.string,
    status: types.string,
    created: types.number,
    payment_method: types.maybeNull(types.string),
    last_payment_error: types.maybeNull(
        types.model({
            code: types.maybe(types.string),
            decline_code: types.maybe(types.string),
            message: types.maybe(types.string),
        })
    ),
    latest_charge: types.union(types.maybeNull(StripeCharge), types.maybeNull(types.string)),
});

export const OrderPriceBreakdownModel = types.model('OrderPriceBreakdownModel', {
    totalBid: types.number,
    remediationFees: types.maybe(types.number),
    taxes: types.number,
    adminFee: types.number,
    variableFee: types.number,
    vehicleKeyFee: types.number,
    vehicleOtherFixedFee: types.number,
    platformFee: types.number,
    feesSubtotal: types.number,
    subtotal: types.number,
    passThroughFee: types.maybe(types.number),
    totalDue: types.number,
});

export const OrderModel = types
    .model('OrderModel', {
        inventoryItemKeys: types.array(types.string),
        sellerIds: types.array(types.string),
        paymentType: types.enumeration(Object.values(PaymentTypeEnum)),
        amountInCents: types.maybe(types.number),
        priceBreakdownCents: OrderPriceBreakdownModel,
        status: types.enumeration(Object.values(OrderStatusEnum)),
        error: types.maybeNull(OrderError),
        createdBy: types.string,
        createdAt: types.string,
        updatedAt: types.string,
        paymentIntentsHistory: types.maybe(types.array(PaymentIntentModel)),
    })
    .views((self) => ({
        get isCreated() {
            return self.status === OrderStatusEnum.CREATED;
        },
        get isProcessing() {
            return self.status === OrderStatusEnum.PAYMENT_PROCESSING;
        },
        get isSuccessful() {
            return self.status === OrderStatusEnum.PAYMENT_SUCCESSFUL;
        },
        get isFailed() {
            return self.status === OrderStatusEnum.PAYMENT_FAILED;
        },
        get isCanceled() {
            return self.status === OrderStatusEnum.CANCELED;
        },
        get lastPaymentIntent() {
            return self.paymentIntentsHistory?.at(-1);
        },
        get paidAt() {
            return this.lastPaymentIntent?.created ? this.lastPaymentIntent.created * 1000 : undefined;
        },
        get paymentDetails() {
            return (this.lastPaymentIntent?.latest_charge as StripeChargeType)?.payment_method_details;
        },
        get isCardPaymentType() {
            return this.paymentDetails?.type === 'card';
        },
        get paymentBrand() {
            return this.isCardPaymentType
                ? this.paymentDetails?.card?.brand
                : this.paymentDetails?.us_bank_account?.bank_name;
        },
        get paymentLast4() {
            return this.isCardPaymentType
                ? this.paymentDetails?.card?.last4
                : this.paymentDetails?.us_bank_account?.last4;
        },
    }))
    .actions((self) => ({
        cancel() {
            self.status = OrderStatusEnum.CANCELED;
        },
    }));

export interface OrderModelType extends Instance<typeof OrderModel> {}
