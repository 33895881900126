import {makeRoute, makeRouteWithParams} from '@joyrideautos/web-common-components/src/router/Router';

export const Routes = {
    LANDING: makeRoute('/'),
    IMPERSONATE: makeRoute('/impersonate'),
    SIGN_UP: makeRoute('/signup'),
    SIGN_UP_SELLER: makeRoute('/signup-seller'),
    SIGN_IN: makeRoute('/signin'),
    VERIFY_USER_INFO: makeRoute('/verifyUserInfo'),
    RESET_PASSWORD: makeRoute('/reset-password'),
    FORGOT_PASSORD: makeRoute('/forgot-password'),
    HOME: makeRoute('/home'),
    PROFILE: makeRoute('/profile'),
    CHANGE_SELLER: makeRoute('/changeSeller'),
    PROFILE_SETTINGS: makeRoute('/profile/settings'),
    VEHICLES: makeRoute('/vehicles'),
    ACCOUNT: makeRoute('/account'),
    NOTIFICATIONS: makeRoute('/notifications'),
    STREAM: makeRouteWithParams('/stream/:regionId/:auctionId'),
    PAYMENT_RESULT_SUCCESS: makeRouteWithParams('/paymentResult/success/:persistenceKey?'),
    PAYMENT_RESULT_FAILED: makeRoute('/paymentResult/failed'),
    PAYMENT_SUMMARY: makeRoute('/paymentSummary'),
    INCOMPLETE_PROFILE: makeRoute('/incomplete-profile'),

    MY_VEHICLES_PROFILE_RESULT_BUYER: makeRouteWithParams('/my-vehicles/purchased/:itemKey'),

    // my-inventory
    MY_INVENTORY_VEHICLE_PROFILE: makeRouteWithParams('/my-inventory/:itemKey'),
    MY_INVENTORY_VEHICLE_PROFILE_WITH_STATUS: makeRouteWithParams('/my-inventory/:status/:itemKey'),
    MY_INVENTORY_VEHICLE_PROFILE_PREVIEW: makeRouteWithParams('/my-inventory/:status/:itemKey/preview'),

    // My Vehicles
    MY_VEHICLES_AUCTIONS: makeRoute('/my-vehicles/auctions'),
    MY_VEHICLES_AUCTION_WON: makeRouteWithParams('/my-vehicles/auctions/:regionId/:auctionId'),
    MY_VEHICLES_BUY_NOW: makeRoute('/my-vehicles/buy-now'),
    MY_VEHICLES_FAVORITES: makeRoute('/my-vehicles/favorites'),

    // Seller Tools
    MY_INVENTORY: makeRoute('/my-inventory'),
    MY_AUCTIONS: makeRoute('/my-auctions'),

    // auction
    VEHICLE_PROFILE: makeRouteWithParams('/auction/:regionId/:auctionId/vehicle/:itemId'),
    AUCTION_OCCURRENCE: makeRouteWithParams('/auction/:regionId/:auctionId'),
    AUCTION_INFO: makeRouteWithParams('/auction/:regionId/:auctionId/info'),

    CHECKOUT_SUMMARY: makeRoute('/checkout/summary'),
    CHECKOUT_PAYMENT: makeRouteWithParams('/checkout/payment/:orderKey'),
    CHECKOUT_PICK_UP_INSTRUCTIONS: makeRouteWithParams('/checkout/result/:orderKey'),

    // auctions
    AUCTIONS: makeRoute('/auctions'),
    AUCTIONS_BY_STATE: makeRouteWithParams('/auctions/by-state/:stateId'),
    AUCTION_REGION: makeRouteWithParams('/auctions/:regionId'),
    AUCTION_SERIES: makeRouteWithParams('/auctions/series/:regionId/:seriesId'),
    AUCTION_STREAMS: makeRouteWithParams('/streams/:regionId/:seriesId'),

    SEARCH: makeRoute('/search'),

    // Seller Release Flow
    SELLER_RELEASE_FLOW_START: makeRoute('/seller/claim-session/start'),
    SELLER_RELEASE_FLOW_VERIFY_BUYER: makeRouteWithParams('/seller/claim-session/:claimSessionKey/buyer-info'),
    SELLER_RELEASE_FLOW_ITEMS: makeRouteWithParams('/seller/claim-session/:claimSessionKey/items'),

    // external link
    SUPPORT_LINK: makeRoute('https://support.joyrideautos.com/'),
};

export type Route = typeof Routes[keyof typeof Routes];

export enum MyVehiclesAuctionPageHashEnum {
    ACTIVE_BIDS = 'ActiveBids',
    WON = 'Won',
    FAILED_DEPOSITS = 'FailedDeposits',
}

export enum UserProfileSettingsHashEnum {
    PERSONAL_CONTACT_INFO = 'personal_contact_info',
    EMAIL_AND_PHONE = 'email_and_phone',
    PASSWORD = 'password',
    PAYMENT_METHODS = 'payment_methods',
    TRANSACTION_HISTORY = 'transaction_history',
    NOTIFICATION_PREFERENCES = 'notification_preferences',
    BIDDING_STATUS = 'bidding_status',
}

export const allUserProfileSettingsHashes = Object.values(UserProfileSettingsHashEnum);

export interface AuctionViewRouteParam extends AuctionsOccurrenceRouteParams, WithDeviceDetectParams {
    optionalItemId?: string;
}

export type AuctionsRegionRouteParams = {
    regionId: string;
};

export type AuctionsOccurrenceRouteParams = {
    regionId: string;
    auctionId: string;
};
export type WithRoutePath<T> = T & {path: string};

export interface WithDeviceDetectParams {
    isMobile: boolean;
}

export interface VehicleProfilePageRouteParam extends AuctionsOccurrenceRouteParams, WithDeviceDetectParams {
    itemId: string;
}
