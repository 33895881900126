import React, {useState, useEffect, FC} from 'react';
import {useStripe} from '@stripe/react-stripe-js';
import {Alert} from 'antd';
import {logError} from '@joyrideautos/ui-logger/src/utils';
import {useRouter} from '@joyrideautos/web-common-components/src/router/Router';

const StripePaymentMethodStatus: FC<{onFailed: () => void}> = ({onFailed}) => {
    const stripe = useStripe();
    const router = useRouter();
    const [message, setMessage] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = router.getSearchParam('setup_intent_client_secret');
        router.clearSearchParams();
        if (!clientSecret) {
            return;
        }

        stripe
            .retrieveSetupIntent(clientSecret)
            .then(({setupIntent}) => {
                // https://stripe.com/docs/payments/payment-methods#payment-notification
                switch (setupIntent?.status) {
                    case 'requires_payment_method':
                        onFailed();
                        setMessage('Failed to process payment details. Please try another payment method.');
                        break;
                }
            })
            .catch(logError());
    }, [router, stripe, onFailed]);

    return message ? <Alert className="mb-20" type="error" message={message} /> : null;
};

export default StripePaymentMethodStatus;
