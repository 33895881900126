import {flow, types} from 'mobx-state-tree';
import BaseViewModel from '@joyrideautos/ui-models/src/BaseViewModel';
import {StripeElementsOptions} from '@stripe/stripe-js';
import {PaymentMethodTypeEnum} from '@joyrideautos/auction-core/src/dtos/PaymentsDto';
import {logError} from '@joyrideautos/ui-logger/src/utils';

export const StripeAddPaymentMethodContextViewModel = BaseViewModel.named('StripeAddPaymentMethodContextViewModel')
    .props({
        paymentMethodTypes: types.array(
            types.optional(
                types.enumeration([PaymentMethodTypeEnum.ACH, PaymentMethodTypeEnum.CARD]),
                PaymentMethodTypeEnum.CARD
            )
        ),
        clientSecret: '',
    })
    .views((self) => ({
        get stripePromise() {
            return self.rootStore.stripe;
        },
        get stripeElementsOptions() {
            return {
                clientSecret: self.clientSecret,
            } as StripeElementsOptions;
        },
        get loading() {
            return !this.stripePromise || !self.clientSecret;
        },
    }))
    .actions((self) => {
        const fetchClientSecret = flow(function* () {
            const secret = yield self.rootStore.paymentService.createPaymentMethodSetupIntent({
                paymentMethodTypes: self.paymentMethodTypes,
            });
            self.clientSecret = secret;
        });
        return {
            afterCreate: function () {
                fetchClientSecret().catch(logError());
            },
        };
    });
