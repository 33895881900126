import {Money} from '../../rules/Money';
import {validateMoney} from '../utils';
import {PlatformFeeScheduleDto, PlatformFeeTypeEnum} from '../../dtos/PlatformFeeScheduleDto';
import {PlatformFeeCalculator} from './types';

export type PlatformFeeScheduleStepInMoney = {
    from: Money;
    to?: Money;
    amountInCents: Money;
};

type PlatformFeeScheduleInMoney = {
    steps: PlatformFeeScheduleStepInMoney[];
};

export class StepFunctionPlatformFeeCalculator implements PlatformFeeCalculator {
    percent = 0;
    isPlatformFeeTakesOfSellerFee = true;
    type = PlatformFeeTypeEnum.SCHEDULE;

    constructor(private feeSchedule: PlatformFeeScheduleInMoney) {}

    static getInstance(feeSchedule: PlatformFeeScheduleDto) {
        return new StepFunctionPlatformFeeCalculator({
            steps: feeSchedule.steps.map(({from, to, amountInCents}) => ({
                from: Money.fromDollars(from),
                to: to ? Money.fromDollars(to) : undefined,
                amountInCents: Money.fromCents(amountInCents),
            })),
        });
    }

    calculate({bid}: {amount?: Money; adminFee?: Money; buyerFee?: Money; bid?: Money}): {
        platformFee: Money;
        rawPlatformFee: Money;
    } {
        this.validate(bid);
        const sortedFees = this.feeSchedule.steps.slice().sort((a, b) => Money.getSorting()(a.from, b.from));
        const index = sortedFees.findIndex((sf) =>
            bid!.isGreaterThanOrEqual(sf.from) && sf.to ? bid!.isLessThan(sf.to) : true
        );

        if (index === -1) {
            return {
                platformFee: Money.zero,
                rawPlatformFee: Money.zero,
            };
        } else {
            const platformFee = sortedFees[index].amountInCents;
            return {
                platformFee,
                rawPlatformFee: platformFee,
            };
        }
    }

    validate(bid?: Money) {
        validateMoney(bid, 'bid');
        if (!isFinite(bid!.cents) || bid!.cents < 0) {
            throw Error(`Wrong argument: amount ${bid}`);
        }
        this.feeSchedule.steps.forEach(({from, to, amountInCents}) => {
            validateMoney(amountInCents, 'amountInCents');
            validateMoney(from, 'from');
            validateMoney(to, 'to');
        });
    }
}
