import {AliasToken} from 'antd/es/theme/interface';

export const CUSTOM_THEME: Partial<AliasToken> = {
    colorPrimary: '#232323',
    colorPrimaryHover: '#767676',
    colorIcon: '#232323',
    colorIconHover: '#767676',
    colorPrimaryActive: '#1F1F1F',
    colorPrimaryBg: '#EEEDE8',
    colorPrimaryBorder: '#C7C7C7',
    colorPrimaryText: '#1B1B1B',
    colorTextSecondary: '#1F1F1F',
    colorTextDisabled: '#A4A4A4',
    colorBorder: '#767676',
    colorInfo: '#1677ff',
    colorInfoHover: '#4096ff',
    colorInfoBorder: '#91caff',
    colorInfoBg: '#E6F7FF',
    colorSuccess: '#52c41a',
    colorSuccessHover: '#73d13d',
    colorSuccessBorder: '#b7eb8f',
    colorSuccessBg: '#f6ffed',
    colorWarning: '#F3A60C',
    colorWarningHover: '#FFC53D',
    colorWarningBorder: '#FFE58F',
    colorWarningBg: '#FFFBE6',
    colorError: '#F5222D',
    colorErrorHover: '#FF4D4F',
    colorErrorBorder: '#FFA39E',
    colorErrorBg: '#FFF1F0',
    fontSize: 14,
    borderRadius: 4,
    fontFamily: 'Roboto',
    colorLink: '#00A85D',
    colorLinkHover: '#004B2A',
    zIndexPopupBase: 1002,
};
