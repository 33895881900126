import BaseViewModel from '@joyrideautos/ui-models/src/BaseViewModel';
import {Stripe, StripeElements, StripePaymentElementOptions} from '@stripe/stripe-js';
import {Routes} from '../../../Routes';
import {PaymentMethodTypeEnum} from '@joyrideautos/auction-core/src/dtos/PaymentsDto';
import primitives from '@joyrideautos/ui-models/src/Primitives';
import {LoadingStatus} from '@joyrideautos/ui-models/src/utils/LoadingStatus';
import {toJS} from 'mobx';
import {types} from 'mobx-state-tree';
import {VisibilityViewModel} from '@joyrideautos/ui-models/src/common/VisibilityViewModel';

export const StripeAddPaymentMethodFormViewModel = BaseViewModel.named('StripeAddPaymentMethodFormViewModel')
    .props({
        isReady: primitives.boolean(),
        stripeFormVisibility: types.optional(VisibilityViewModel, {value: true}),
    })
    .volatile(() => ({
        onSuccess: () => {},
        submitHandlerLoadingStatus: new LoadingStatus(),
    }))
    .views((self) => ({
        get returnUrl() {
            return `${self.rootStore.appConfig.commonConfig.publicAuctionUrl}${Routes.PROFILE_SETTINGS({
                hash: 'payment_methods',
            })}`;
        },
        get stripePaymentElementsOptions(): StripePaymentElementOptions {
            return {
                paymentMethodOrder: [PaymentMethodTypeEnum.ACH, PaymentMethodTypeEnum.CARD],
                business: {name: 'Autura Marketplace'},
                defaultValues: {
                    billingDetails: {
                        name: self.authUser?.fullName,
                        email: self.authUser?.email || undefined,
                        address: {
                            country: 'US',
                            postal_code: self.authUser?.zipcode,
                        },
                    },
                },
                terms: {
                    usBankAccount: 'never',
                    card: 'never',
                },
            };
        },
        get hasPayment() {
            return self.authUserStore.userInfo && toJS(self.authUserStore.userInfo?.paymentMethods).length > 0;
        },
    }))
    .actions((self) => ({
        setOnSuccess(handler?: () => void) {
            self.onSuccess = handler ?? (() => {});
        },
        getSubmitHandler: (stripe: Stripe | null, elements: StripeElements | null, isRedirectAlways = true) => {
            const handler = async (event: any) => {
                self.submitHandlerLoadingStatus.setInProgress();
                event.preventDefault();
                if (!stripe || !elements) {
                    return null;
                }

                const {error, setupIntent} = await stripe.confirmSetup({
                    elements,
                    confirmParams: {
                        return_url: self.returnUrl,
                    },
                    redirect: isRedirectAlways ? 'if_required' : undefined,
                } as any);

                if (error) {
                    self.logger.error('stripe submission error', error);
                } else {
                    self.onSuccess();
                }

                self.submitHandlerLoadingStatus.setReady();
                return {setupIntent, error};
            };

            return handler;
        },
    }));
