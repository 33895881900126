// WARNING! in case you need to change max length, it requires altering column in pg

import * as s from 'superstruct';
import {PaymentIntentDtoValidation, StripeChargeDtoValidation} from '../types/validations/paymentIntentValidation';

// Consider moving into validations/paymentValidation.ts when will be need to create such file
export const PAYMENT_FIELDS_LENGTH = {
    PAYMENT_METHOD_NAME: 32,
};

export interface PaymentMethodDto {
    paymentMethodId: string; // Non-unique Stripe internal id, e.g. "pm_1IibxrExeRpJWih55l7POYRs"
    last_4: string;
    cardBrand: string; // used to display logo in userProfile. Possible values: VISA, MASTERCARD, AMEX, DISCOVER, US_BANK_ACCOUNT
    // US_BANK_ACCOUNT is not card but use this field to avoid creation of new field (requires migration script etc...)
    fingerprint: string; // Unique Stripe internal id, e.g. "SFo3KN5sCGS68ZJq"
    paymentMethodName: string | undefined;
    isDefault: boolean | undefined;
    createdAt: number | undefined;
    setupStatus?: PaymentMethodSetupStatusEnum;
    setupError?: string;
    setupActionUrl?: string;
}

export enum StripeIdentityVerificationSessionStatusEnum { // same as in type Stripe.Identity.VerificationSession.Status;
    VERIFIED = 'verified',
    CREATED = 'created',
    REQUIRES_INPUT = 'requires_input',
    DEPRECTATED_REQUIRES_ACTION = 'requires_action',
    PROCESSING = 'processing',
    REDACTED = 'redacted',
    CANCELED = 'canceled',
}

export enum IdentityVerificationReportStatusEnum {
    CREATED = 'created',
    UPDATED = 'updated',
    VERIFIED = 'verified',
    UNVERIFIED = 'unverified',
    PENDING = 'pending', // this is actual status
}

export enum IdentityVerificationLastVerificationErrorTypeEnum {
    DEVICE_UNSUPPORTED = 'device_unsupported',
    CONSENT_DECLINED = 'consent_declined',
    UNVERIFIED = 'unverified',
}

export enum PaymentTypeEnum {
    AUTOMATIC_DEPOSIT = 'deposit', // We don't rename the value for backwards compatibility
    DEPOSIT_CHECKOUT = 'deposit_checkout', // = automatic deposit payment failed and user performs it manually again
    PAY_IN_FULL = 'pay_in_full', // Pay in full can be performed only through checkout now
    BUY_NOW = 'buy_now', // Checkout only
    DEPOSIT_OFFER = 'deposit_offer',
}

export enum PaymentErrorCodeEnum {
    ALREADY_PAID = 'ALREADY_PAID',
}

export enum PaymentMethodTypeEnum {
    ACH = 'us_bank_account',
    CARD = 'card',
}

export enum PaymentMethodSetupStatusEnum {
    PENDING = 'pending',
    FAILED = 'failed',
}

/**
 * Used for cases when we don't care how the deposit was paid: automatically (PaymentTypeEnum.deposit_invoice) or manually (PaymentTypeEnum.deposit_checkout)
 */
export const isAnyDepositPayment = (paymentType?: PaymentTypeEnum): boolean =>
    !!paymentType &&
    [
        PaymentTypeEnum.AUTOMATIC_DEPOSIT,
        PaymentTypeEnum.DEPOSIT_CHECKOUT,
        PaymentTypeEnum.DEPOSIT_OFFER,
        PaymentTypeEnum.BUY_NOW,
    ].includes(paymentType);

export type PaymentIntentDto = s.Infer<typeof PaymentIntentDtoValidation>;
export type PaymentIntentChargeDto = s.Infer<typeof StripeChargeDtoValidation>;

// It is a copy of Stripe.Response from 'stripe' package
// created to avoid adding stripe package to core lib
export type StripeResponseDto<T> = T & {
    lastResponse: {
        headers: {[key: string]: string};
        requestId: string;
        statusCode: number;
        apiVersion?: string;
        idempotencyKey?: string;
        stripeAccount?: string;
    };
};

// It is a copy of Stripe.Identity.VerificationSession
// In case you need some missing prop, check directly in 'stripe' package for latest version
export type IdentityVerificationIntentDto = {
    id: string;
    object: string;
    client_secret: string | null;
    client_reference_id: string | null;
    created: number;
    last_error?: {
        code: string;
        reason: string;
    };
    last_verification_report: string;
    livemode: boolean;
    metadata: {uid: string};
    options: {document: {require_matching_selfie: boolean}};
    redaction: null;
    status: StripeIdentityVerificationSessionStatusEnum;
    type: string;
    url: string;
};

export enum PaymentStatusEnum {
    CANCELED = 'canceled',
    PENDING = 'pending',
    PROCESSING = 'processing',
    REQUIRES_ACTION = 'requires_action',
    REQUIRES_CAPTURE = 'requires_capture',
    REQUIRES_CONFIRMATION = 'requires_confirmation',
    REQUIRES_PAYMENT_METHOD = 'requires_payment_method',
    SUCCEEDED = 'succeeded',
    ERROR = 'error',
}

export interface PaymentInfoDto {
    amount: number;
    currency: string;
    amount_received?: number | null;
    status: string;
    created: number;
    canceled_at: number | null;
    cancellation_reason: string | null;
    client_secret: string;
    confirmation_method: string;
    customer: string;
    payment_method?: string | null;
    description: string | null;
    last_payment_error: string | null;
    statement_descriptor: string | null;
}

export interface ItemPaymentDto {
    uid: string;
    regionId: string;
    auctionId: string;
    success: boolean;
    amount: number;
    paymentInfo: PaymentInfoDto;
    itemId: number | string;
    sellerId?: string;
    timestamp: string;
    status?: PaymentStatusEnum;
    error?: any;
    invoiceId?: string;
    type?: PaymentTypeEnum;
}

export interface PaymentStatisticsDto {
    success?: number;
    failed?: number;
}

// TODO (Future): this data structure should be reviewed: do we need success property?
export type VehiclePaymentResultDto = {
    success: boolean;
    status: PaymentStatusEnum;
    amount: number;
    timestamp: string;
    itemId: string; // RTDB key
    sellerId: string;
    paymentInfo?: PaymentIntentDto;
    error?: Record<string, any>;
    invoiceId?: string;
    info?: unknown;
    type?: PaymentTypeEnum;
};

export type PersistedVehiclePaymentResultDto = VehiclePaymentResultDto & {
    uid: string;
    regionId: string;
    auctionId: string;
    type: PaymentTypeEnum;
};

export const DEFAULT_PAYMENT_DUE_WINDOW_HOURS = 24;
export const DEFAULT_PAYMENT_EXPIRATION_HOURS = 48;
