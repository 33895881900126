import {isLocalHost} from '@joyrideautos/auction-utils/src/common';
import RoutingService from './RoutingService';

export enum FeReqRouteEnum {
    AWS_WEBHOOK_UPLOAD_MEDIA = 'aws-upload-media-callback',
    AWS_GET_OPEN_ID_TOKEN_FOR_DEVELOPER_IDENTITY = 'aws-getOpenIdTokenForDeveloperIdentity',

    API_SELLERS_CREATE = 'seller-createSeller',
    API_SELLERS_REGIONS_UPDATE = 'seller-updateSellerRegions',
    API_SELLERS_GOOGLE_DRIVE_FOLDER_ID_UPDATE = 'seller-updateSellerGoogleFolderId',
    API_SELLERS_ADDRESS_UPDATE = 'seller-updateSellerAddress',
    API_SELLERS_LOCATION_UPDATE = 'seller-updateSellerLocation',
    API_SELLERS_DOCUMENTS_CREATE = 'seller-addDocumentToSeller',
    API_SELLERS_DOCUMENTS_DELETE = 'seller-deleteDocumentToSeller',
    API_SELLERS_FEE_UPDATE = 'seller-saveSellerFee',
    API_SELLERS_INFO_UPDATE = 'seller-updateSellerInfo',
    API_SELLERS_CREATE_CUSTOM_FIELD = 'seller-createSellerCustomField',
    API_SELLERS_UPDATE_CUSTOM_FIELD = 'seller-updateSellerCustomField',
    API_SELLERS_DELETE_CUSTOM_FIELD = 'seller-deleteSellerCustomField',
    API_SELLERS_ITEMS_BY_STATUS = 'seller-sellerItemsByStatus',
    API_SELLERS_SELLER_RELEASE_FLOW_VALIDATE_GATE_CODE = 'seller-validateGateCode',
    API_SELLERS_SELLER_RELEASE_FLOW_GET_CLAIM_SESSION_PAYMENT_INFO = 'seller-getClaimSessionPaymentInfo',
    API_SELLERS_SELLER_RELEASE_FLOW_GET_CLAIM_SESSION = 'seller-getClaimSession',
    API_SELLERS_SELLER_RELEASE_FLOW_GET_CLAIM_SESSION_BUYER_INFO = 'seller-getClaimSessionBuyerInfo',
    API_SELLERS_SELLER_RELEASE_FLOW_UPDATE_CLAIM_SESSION = 'seller-updateClaimSession',
    API_SELLERS_SELLER_RELEASE_FLOW_CONFIRM_CLAIM_SESSION = 'seller-confirmClaimSession',
    API_SELLERS_GENERATE_CLAIM_SESSION = 'seller-generateClaimSession',

    API_SEARCH_VEHICLES_BY_TEXT = 'searchEngine-searchVehiclesByText',
    API_SEARCH_INVENTORY_VEHICLES = 'searchEngine-searchInventoryVehicles',
    API_SEARCH_GET_VEHICLES_SUGGESTIONS = 'searchEngine-getVehiclesSearchSuggestions',
    API_SEARCH_GET_PUBLISHED_VEHICLES_FOR_FILTERS = 'searchEngine-getPublishedVehiclesForFilters',

    API_AUCTIONS_GET_AUCTION_SERIES = 'auction-getAuctionSeries',
    API_AUCTIONS_UPDATE_AUCTION_PAUSE = 'auction-updateAuctionPause',
    API_AUCTIONS_END_AUCTION_VEHICLE = 'auction-endAuctionVehicle',
    API_AUCTIONS_SKIP_AUCTION_VEHICLE = 'auction-skipAuctionVehicle',
    API_AUCTIONS_CHECK_NEXT_ITEM_UPDATED = 'auction-checkNextItemUpdated',
    API_AUCTIONS_UPDATE_AUCTION_EXPIRATION = 'auction-updateAuctionExpiration',
    API_AUCTIONS_UPDATE_AUCTION_EVENT_START = 'auction-updateAuctionEventStart',
    API_AUCTIONS_UPDATE_AUCTION_START = 'auction-updateAuctionStart',
    API_AUCTIONS_UPDATE_AUCTION_SMS_NOTIFICATIONS = 'auction-updateAuctionSmsNotifications',
    API_AUCTIONS_UPDATE_AUCTION_SMS_LEADTIME = 'auction-updateAuctionSmsLeadTime',
    API_AUCTIONS_UPDATE_AUCTION_SERIES = 'auction-updateAuctionSeries',
    API_AUCTIONS_UPDATE_AUCTION_OCCURRENCE = 'auction-updateAuctionOccurrence',
    API_AUCTIONS_RESCHEDULE_AUCTION_OCCURRENCE = 'auction-rescheduleAuctionOccurrence',
    API_AUCTIONS_EXTEND_BIDDING_EXPIRATION = 'auction-extendBiddingExpiration',
    API_AUCTIONS_ADD_SELLER_TO_AUCTION_SERIES = 'auction-addSellerToAuctionSeries',
    API_AUCTIONS_REMOVE_SELLER_FROM_AUCTION_SERIES = 'auction-removeSellerFromAuctionSeries',
    API_AUCTIONS_CLEAN_AUCTIONS = 'auction-cleanAuctions',
    API_AUCTIONS_ENABLE_PLACING_BIDS = 'auction-enablePlacingBids',
    API_AUCTIONS_GET_AUCTION_SCHEDULE = 'auction-getAuctionSchedule',
    API_AUCTIONS_SAVE_AUCTION_SCHEDULE = 'auction-saveAuctionSchedule',
    API_AUCTIONS_DELETE_AUCTION_SCHEDULE = 'auction-deleteAuctionSchedule',
    API_AUCTIONS_DELETE_AUCTION = 'auction-deleteAuction',
    API_AUCTIONS_DELETE_ALL_AUCTIONS_DATA = 'auction-deleteAllAuctionsData',
    API_AUCTIONS_VALIDATE_CONFIGURATION = 'auction-validateActiveAuctionsConfiguration',
    API_AUCTIONS_SAVE_BID_INCREMENT = 'auction-saveBidIncrement',
    API_AUCTIONS_CREATE_OR_UPDATE_PLATFORM_FEE_SCHEDULE = 'auction-createOrUpdatePlatformFeeSchedule',
    API_AUCTIONS_GET_LIVE_AUCTIONS_FOR_SELLER = 'auction-getLiveAuctionsForSeller',

    API_ITEMS_FIX_ITEMS_EXPIRATION_IN_AUCTION = 'item-fixItemsExpirationInAuction',
    API_ITEMS_FETCH_VEHICLE_CSV_DATA = 'item-fetchVehicleCSVData',
    API_ITEMS_FETCH_AUCTION_CSV_DATA = 'item-fetchAuctionCSVData',
    API_ITEMS_FETCH_VEHICLE_EXCEL_DATA = 'item-fetchVehicleExcelData',
    API_ITEMS_FETCH_VEHICLES_STATUSES_STATISTIC = 'item-fetchVehiclesStatusesStatistic',
    API_ITEMS_FETCH_UPCOMING_AUCTIONS_DATA = 'item-fetchUpcomingAuctionsData',
    API_ITEMS_UPLOAD_ITEMS_CSV = 'item-uploadItemsCsv',
    API_ITEMS_UPDATE_VEHICLES_IN_FIREBASE = 'item-updateVehiclesInFirebase',
    API_ITEMS_UPDATE_VEHICLE_STATUS_IN_FIREBASE = 'item-updateVehicleStatusInFirebase',
    API_ITEMS_UPDATE_VEHICLE_STATUS = 'item-updateVehicleStatus',
    API_ITEMS_UPDATE_VEHICLES_STATUS = 'item-updateVehiclesStatus',
    API_ITEMS_CLONE_VEHICLE = 'item-cloneVehicle',
    API_ITEMS_GET_ITEMS_FOR_SELLER = 'item-getItemsForSeller',
    API_ITEMS_GET_ITEMS_FOR_BUYER = 'item-getItemsForBuyer',
    API_ITEMS_GET_INVENTORY_ITEMS_FOR_AUCTION = 'item-getInventoryItemsForAuction',
    API_ITEMS_GET_WON_ITEMS_FOR_BUYER = 'item-getWonItemsForBuyer',
    API_ITEMS_GET_ITEM_SALE_DATA = 'item-getItemSaleData',
    API_ITEMS_GET_ITEMS_BY_IDS = 'item-getItemsByIds',
    API_ITEMS_UPDATE_PERSISTED_ITEM = 'item-updatePersistedItem',
    API_ITEMS_GET_ITEM_MEDIA = 'item-getItemMedia',
    API_ITEMS_REMOVE_ITEM_MEDIA = 'item-removeItemMedia',
    API_ITEMS_UPDATE_ITEM_MEDIA_ORDER = 'item-updateItemMediaOrder',
    API_ITEMS_UPDATE_ITEM_IMAGES_COUNT = 'item-updateItemImagesCount',
    API_ITEMS_CREATE_LOADING_ITEM_MEDIA = 'item-createLoadingItemMedia',
    API_ITEMS_SCHEDULE_VEHICLES = 'item-scheduleVehicles',
    API_ITEMS_RESCHEDULE_VEHICLES = 'item-rescheduleVehicles',
    API_ITEMS_REORDER_VEHICLES = 'item-reorderVehicles',
    API_ITEMS_CURRENT_USER_PAID_ITEMS = 'item-currentUserPaidItems',
    API_ITEMS_FIND_SELLER_VEHICLES_BY_SELLER_VEHICLE_ID = 'item-findSellerVehiclesBySellerVehicleId',
    API_ITEMS_PERFORM_SELL_VEHICLE_OFFLINE = 'item-performSellVehicleOffline',
    API_ITEMS_CALCULATE_ITEMS_COUNT = 'item-calculateItemsCount',
    API_ITEMS_UNSCHEDULE_VEHICLE = 'item-unscheduleVehicle',

    API_PAYMENT_ADD_PAYMENT_METHOD = 'payment-addPaymentMethod',
    API_PAYMENT_REMOVE_PAYMENT_METHOD = 'payment-removePaymentMethod',
    API_PAYMENT_UPDATE_PAYMENT_METHOD = 'payment-updatePaymentMethod',
    API_PAYMENT_GET_FEES_BREAKDOWN_FOR_AMOUNT = 'bid-getFeesBreakdownForAmount',
    API_PAYMENT_GENERATE_DEPOSIT_RECEIPT = 'payment-generateDepositReceipt',
    API_PAYMENT_GENERATE_PAY_IN_FULL_BUYER_RECEIPT = 'payment-generatePayInFullBuyerReceipt',
    API_PAYMENT_GENERATE_PAY_IN_FULL_SELLER_RECEIPT = 'payment-generatePayInFullSellerReceipt',
    API_PAYMENT_STORE_PDF_RECEIPTS = 'payment-storePDFReceipts',
    API_PAYMENT_GENERATE_PDF_BULK_RECEIPT = 'payment-generatePDFBulkReceiptOnCall',
    API_PAYMENT_CREATE_PAYMENT_METHOD_SETUP_INTENT = 'payment-createPaymentMethodSetupIntent',
    API_PAYMENT_CHECK_IF_USER_IS_ABLE_TO_PAY_FOR_ITEMS = 'payment-checkIfUserIsAbleToPayForItems',
    API_PAYMENT_GENERATE_CHECKOUT = 'payment-generateCheckout',
    API_PAYMENT_CONFIRM_CC_HOLD = 'payment-confirmCcHold',
    API_PAYMENT_CONFIRM_CC_HOLD_AND_PLACE_CC_HOLD_FOR_BIDDER = 'payment-confirmCcHoldAndPlaceCcHoldForBidder',
    API_PAYMENT_PLACE_CC_HOLD_FOR_BIDDER = 'payment-placeCcHoldForBidder',
    API_PAYMENT_GET_STRIPE_IDENTITY_VERIFICATION_SESSION = 'payment-getStripeIdentityVerificationSession',
    API_PAYMENT_SET_STRIPE_IDENTITY_VERIFICATION_AS_PROCESSING = 'payment-setStripeIdentityVerificationAsProcessing',
    API_PAYMENT_CREATE_ORDER = 'payment-createOrder',
    API_PAYMENT_CANCEL_ORDER = 'payment-cancelOrder',
    API_PAYMENT_CREATE_ORDER_PAYMENT_INTENT = 'payment-createOrderPaymentIntent',
    API_PAYMENT_APPLY_ADDITIONAL_ORDER_FEE = 'payment-applyAdditionalOrderFee',
    API_PAYMENT_GET_ORDER_PAYMENT_INTENT_DETAILS = 'payment-getOrderPaymentIntentDetails',

    API_USER_CREATE_USER = 'user-createUser',
    API_USER_BAN_USERS = 'user-banUsers',
    API_USER_GRANT_RESTRICTED_ACCESS_TO_AUCTION_SERIES_FOR_USER = 'user-grantRestrictedAccessToAuctionSeriesForUser',
    API_USER_GET_USER_ACTIVE_RESTRICTED_ACCESS_AUCTION_SERIES = 'user-getUserActiveRestrictedAccessAuctionSeries',
    API_USER_BAN_USER_FOR_SELLERS = 'user-banUserForSellers',
    API_USER_IS_USER_BANNED = 'user-isUserBanned',
    API_USER_BAN_USER_AS_SELLER = 'user-banUserAsSeller',
    API_USER_BAN_USERS_FOR_AUCTION_SELLER = 'user-banUsersForAuctionSeller',
    API_USER_UNBAN_USERS_FOR_SELLERS = 'user-unbanUserForSellers',
    API_USER_FIND_SELLERS_FOR_BANNED_USER = 'user-findSellersForBannedUser',
    API_USER_SEND_BUYER_COMMUNICATION = 'user-sendBuyerCommunication',
    API_USER_FETCH_BUYER_COMMUNICATIONS = 'user-fetchBuyerCommunications',
    API_USER_SEND_NOTIFICATION_BIDDING_OPEN = 'user-sendNotificationBiddingOpen',
    API_USER_TOGGLE_FAVORITE_REGION = 'user-toggleFavoriteRegion',
    API_USER_TOGGLE_FAVORITE_AUCTION_SERIES = 'user-toggleFavoriteAuctionSeries',
    API_USER_TOGGLE_FAVORITE_AUCTION = 'user-toggleFavoriteAuction',
    API_USER_TOGGLE_FAVORITE_ITEM = 'user-toggleFavoriteItem',
    API_USER_FETCH_SELLERS_FOR_PREAPPROVED_USER = 'user-fetchSellersForPreapprovedUser',
    API_USER_SAVE_SELLERS_FOR_PREAPPROVED_USER = 'user-saveSellersForPreapprovedUser',
    API_USER_SEND_EMAIL_VERIFICATION_FOR_USER = 'user-sendEmailVerificationForUser',
    API_USER_UPDATE_EMAIL_FOR_USER = 'user-updateEmailForUser',
    API_USER_SAVE_USER_NOTE = 'user-saveUserNote',
    API_USER_SAVE_CONTACT_DETAILS = 'user-saveContactDetails',
    API_USER_UPDATE_VERIFICATION_STATUS_BY_ADMIN_FOR_USER = 'user-updateVerificationStatusByAdminForUser',
    API_USER_UPDATE_USER = 'user-updateUser',
    API_USER_FIND_USER_BY_EMAIL = 'user-findUserByEmail',
    API_USER_FIND_USER_BY_ID = 'user-findUserById',
    API_USER_GET_WINNING_BUYER_INFO = 'user-getWinningBuyerInfo',
    API_USER_GET_USER_ACTIVITY = 'user-getUserActivity',
    API_USER_SEARCH_USERS = 'user-searchUsers',
    API_USER_GET_VEHICLES_WITH_FAILED_DEPOSIT = 'user-getVehiclesWithFailedDeposit',
    API_USER_CHECK_IS_USER_BLACKLISTED = 'user-checkIsUserBlacklisted',
    API_USER_BAN_USER_GLOBALLY = 'user-banUserGlobally',
    API_USER_GET_GLOBALLY_BANNED_USER = 'user-getGloballyBannedUser',
    API_USER_BYPASS_EMAIL_VERIFICATION_FOR_USER = 'user-bypassEmailVerificationForUser',
    API_USER_BYPASS_PHONE_VERIFICATION_FOR_USER = 'user-bypassPhoneVerificationForUser',
    API_USER_GET_ZIP_CODE_BY_COORDINATES = 'user-getZipCodeByCoordinates',
    API_USER_GET_COORDINATES_BY_ZIP_CODE = 'user-getCoordinatesByZipCode',
    API_USER_USER_WON_ITEMS = 'user-userWonItems',
    API_USER_MARK_VEHICLE_AS_PICKED_UP = 'user-markVehicleAsPickedUp',
    API_USER_CURRENT_USER_WON_ITEMS = 'user-currentUserWonItems',
    API_USER_CURRENT_USER_CLAIMED_ITEMS = 'user-currentUserClaimedItems',
    API_USER_MARK_FOR_REMOVE_TRANSIENT_EVENT_STATE = 'user-markForRemoveTransientEventState',
    API_USER_UPDATE_STATE_FOR_ALL_USER_PERSISTED_NOTIFICATIONS = 'user-updateStateForAllUserPersistedNotifications',
    API_USER_SAVE_PERSISTED_NOTIFICATIONS = 'user-savePersistedNotifications',
    API_USER_GET_ZIP_CODE_BY_IP = 'user-getZipCodeByIP',
    API_CHECKOUT_SUMMARY_DATA = 'user-getCheckoutSummaryData',

    API_BID_CHECK_AUCTION_BIDDING_ENDED = 'bid-checkAuctionBiddingEnded',
    API_BID_CANCEL_AUTO_BID = 'bid-cancelAutobid',
    API_BID_REVERT_BID = 'bid-revertBid',
    API_BID_GET_PERSISTED_BIDS = 'bid-getPersistedBids',
    API_BIDS_FETCH_ALL_ITEMS_WITH_OFFERS = 'bid-fetchAllItemsWithOffers',
    API_BIDS_HANDLE_HIGHEST_OFFER_BY_SELLER = 'bid-handleHighestOfferBySeller',
    API_BIDS_REJECTY_ALL_ACTIVE_OFFERS = 'bid-rejectAllActiveOffers',

    API_PHONE_SEND_VERIFICATION_CODE = 'phone-sendVerificationCode',
    API_PHONE_CHECK_VERIFICATION_CODE = 'phone-checkVerificationCode',
    API_PHONE_CANCEL_PHONE_VERIFICATION = 'phone-cancelPhoneVerification',
    API_PHONE_VALIDATE_PHONE_NUMBER = 'phone-validatePhoneNumber',

    API_GOOGLE_VALIDATE_GOOGLE_DRIVE_FOLDER = 'google-validateGoogleDriveFolder',
    API_GOOGLE_SET_GOOGLE_DRIVE_QUEUE = 'google-setGoogleDriveQueue',
    API_GOOGLE_GOOGLE_IMPORT_MANUAL = 'google-importManual',

    API_USER_ROLES_ADD_ROLE_TO_USER = 'userRoles-addRoleToUser',
    API_USER_ROLES_REMOVE_ROLE_FOR_USER = 'userRoles-removeRoleForUser',
    API_USER_ROLES_FIND_ASSOCIATED_USERS_FOR_SELLER = 'userRoles-findAssociatedUsersForSeller',

    API_WHITELIST_GET_WHITELIST_PHONES = 'whitelist-getWhitelistPhones',
    API_WHITELIST_CREATE_WHITELIST_PHONE = 'whitelist-createWhitelistPhone',
    API_WHITELIST_REMOVE_WHITE_LIST_PHONE = 'whitelist-removeWhitelistPhone',

    API_AUDIT_CREATE_EVENT = 'audit-createEvent',
    API_UPDATE_GLOBAL_CONFIG = 'app-updateGlobalConfig',
}

export enum FeRequestHandlerEnum {
    APP_HTTP = 'app-http',
    SELLERS_HTTP = 'sellers-http',
    MEDIA_HTTP = 'media-http',
    BIDDING_WS = 'bidding-ws',
    CLOUD_FUNCTIONS = 'cloud-functions',
    FIREBASE_HOSTING = 'firebase-hosting',
    SEARCH_HTTP = 'search-http',
    AUCTIONS_HTTP = 'auctions-http',
    ITEMS_HTTP = 'items-http',
    PAYMENTS_HTTP = 'payments-http',
    USERS_HTTP = 'users-http',
    PHONES_HTTP = 'phones-http',
    BIDS_HTTP = 'bids-http',
    MIGRATIONS_HTTP = 'migrations-http',
}

export const FALLBACK_FE_REQUEST_HANDLER = FeRequestHandlerEnum.APP_HTTP;

export const DEFAULT_PROJECT_REGION = 'us-central1';

export interface MicroservicesConfig {
    defaultRequestHandler?: string;
    appHttpUrl?: string;
    sellersHttpUrl?: string;
    searchHttpUrl?: string;
    auctionsHttpUrl?: string;
    itemsHttpUrl?: string;
    mediaHttpUrl?: string;
    paymentsHttpUrl?: string;
    usersHttpUrl?: string;
    phonesHttpUrl?: string;
    bidsHttpUrl?: string;
    biddingWsUrl?: string;
    migrationsHttpUrl?: string;
    restApiHttpUrl?: string;
}

export interface FERoutingConfig {
    projectId: string;
    functionUrl?: string;
    microservices: MicroservicesConfig;
}

function getCloudFunctionsUrl(config: FERoutingConfig) {
    if (config.functionUrl) {
        return config.functionUrl;
    }
    const requestHandler =
        config.microservices.defaultRequestHandler ??
        process.env.REACT_APP_DEFAULT_REQUEST_HANDLER ??
        FeRequestHandlerEnum.CLOUD_FUNCTIONS;
    if (isLocalHost() && requestHandler === FeRequestHandlerEnum.CLOUD_FUNCTIONS) {
        throw new Error('config.functionUrl is empty');
    }
    return `https://${DEFAULT_PROJECT_REGION}-${config.projectId}.cloudfunctions.net`;
}

export const feHandlersURLs = (config: FERoutingConfig): {[key in FeRequestHandlerEnum]: string | undefined} => ({
    [FeRequestHandlerEnum.CLOUD_FUNCTIONS]: getCloudFunctionsUrl(config),
    [FeRequestHandlerEnum.APP_HTTP]: config.microservices.appHttpUrl,
    [FeRequestHandlerEnum.SELLERS_HTTP]: config.microservices.sellersHttpUrl,
    [FeRequestHandlerEnum.SEARCH_HTTP]: config.microservices.searchHttpUrl,
    [FeRequestHandlerEnum.AUCTIONS_HTTP]: config.microservices.auctionsHttpUrl,
    [FeRequestHandlerEnum.ITEMS_HTTP]: config.microservices.itemsHttpUrl,
    [FeRequestHandlerEnum.PAYMENTS_HTTP]: config.microservices.paymentsHttpUrl,
    [FeRequestHandlerEnum.USERS_HTTP]: config.microservices.usersHttpUrl,
    [FeRequestHandlerEnum.PHONES_HTTP]: config.microservices.phonesHttpUrl,
    [FeRequestHandlerEnum.BIDS_HTTP]: config.microservices.bidsHttpUrl,
    // MIGRATIONS_HTTP is used by migrations module that uses FE Routing service too
    [FeRequestHandlerEnum.MIGRATIONS_HTTP]: config.microservices.migrationsHttpUrl,
    // Right now we are using this as a webhook endpoint for AWS.
    [FeRequestHandlerEnum.MEDIA_HTTP]: config.microservices.mediaHttpUrl || globalThis?.location?.origin,
    [FeRequestHandlerEnum.BIDDING_WS]: config.microservices.biddingWsUrl,
    // Note, FERoutingService is also used in test to simulate requests from WebSockets, but window won't be available
    //  in jest, so we use globalThis here
    [FeRequestHandlerEnum.FIREBASE_HOSTING]: globalThis?.location?.origin,
});

// handlersByRoute contains only overrides; other routes will be handled by the default request handler
export const feHandlersByRoute: Partial<Record<FeReqRouteEnum, FeRequestHandlerEnum>> = {
    [FeReqRouteEnum.AWS_WEBHOOK_UPLOAD_MEDIA]: FeRequestHandlerEnum.MEDIA_HTTP,
    [FeReqRouteEnum.AWS_GET_OPEN_ID_TOKEN_FOR_DEVELOPER_IDENTITY]: FeRequestHandlerEnum.APP_HTTP,
    [FeReqRouteEnum.API_SELLERS_CREATE]: FeRequestHandlerEnum.SELLERS_HTTP,
    [FeReqRouteEnum.API_SELLERS_REGIONS_UPDATE]: FeRequestHandlerEnum.SELLERS_HTTP,
    [FeReqRouteEnum.API_SELLERS_GOOGLE_DRIVE_FOLDER_ID_UPDATE]: FeRequestHandlerEnum.SELLERS_HTTP,
    [FeReqRouteEnum.API_SELLERS_ADDRESS_UPDATE]: FeRequestHandlerEnum.SELLERS_HTTP,
    [FeReqRouteEnum.API_SELLERS_LOCATION_UPDATE]: FeRequestHandlerEnum.SELLERS_HTTP,
    [FeReqRouteEnum.API_SELLERS_DOCUMENTS_CREATE]: FeRequestHandlerEnum.SELLERS_HTTP,
    [FeReqRouteEnum.API_SELLERS_DOCUMENTS_DELETE]: FeRequestHandlerEnum.SELLERS_HTTP,
    [FeReqRouteEnum.API_SELLERS_FEE_UPDATE]: FeRequestHandlerEnum.SELLERS_HTTP,
    [FeReqRouteEnum.API_SELLERS_INFO_UPDATE]: FeRequestHandlerEnum.SELLERS_HTTP,
    [FeReqRouteEnum.API_SELLERS_ITEMS_BY_STATUS]: FeRequestHandlerEnum.SELLERS_HTTP,
    [FeReqRouteEnum.API_SELLERS_CREATE_CUSTOM_FIELD]: FeRequestHandlerEnum.SELLERS_HTTP,
    [FeReqRouteEnum.API_SELLERS_UPDATE_CUSTOM_FIELD]: FeRequestHandlerEnum.SELLERS_HTTP,
    [FeReqRouteEnum.API_SELLERS_DELETE_CUSTOM_FIELD]: FeRequestHandlerEnum.SELLERS_HTTP,
    [FeReqRouteEnum.API_SELLERS_SELLER_RELEASE_FLOW_VALIDATE_GATE_CODE]: FeRequestHandlerEnum.SELLERS_HTTP,
    [FeReqRouteEnum.API_SELLERS_SELLER_RELEASE_FLOW_GET_CLAIM_SESSION_PAYMENT_INFO]: FeRequestHandlerEnum.SELLERS_HTTP,
    [FeReqRouteEnum.API_SELLERS_SELLER_RELEASE_FLOW_GET_CLAIM_SESSION]: FeRequestHandlerEnum.SELLERS_HTTP,
    [FeReqRouteEnum.API_SELLERS_SELLER_RELEASE_FLOW_GET_CLAIM_SESSION_BUYER_INFO]: FeRequestHandlerEnum.SELLERS_HTTP,
    [FeReqRouteEnum.API_SELLERS_SELLER_RELEASE_FLOW_UPDATE_CLAIM_SESSION]: FeRequestHandlerEnum.SELLERS_HTTP,
    [FeReqRouteEnum.API_SELLERS_SELLER_RELEASE_FLOW_CONFIRM_CLAIM_SESSION]: FeRequestHandlerEnum.SELLERS_HTTP,
    [FeReqRouteEnum.API_SELLERS_GENERATE_CLAIM_SESSION]: FeRequestHandlerEnum.SELLERS_HTTP,

    [FeReqRouteEnum.API_SEARCH_VEHICLES_BY_TEXT]: FeRequestHandlerEnum.SEARCH_HTTP,
    [FeReqRouteEnum.API_SEARCH_INVENTORY_VEHICLES]: FeRequestHandlerEnum.SEARCH_HTTP,
    [FeReqRouteEnum.API_SEARCH_GET_VEHICLES_SUGGESTIONS]: FeRequestHandlerEnum.SEARCH_HTTP,
    [FeReqRouteEnum.API_SEARCH_GET_PUBLISHED_VEHICLES_FOR_FILTERS]: FeRequestHandlerEnum.SEARCH_HTTP,

    [FeReqRouteEnum.API_AUCTIONS_GET_AUCTION_SERIES]: FeRequestHandlerEnum.AUCTIONS_HTTP,
    [FeReqRouteEnum.API_AUCTIONS_UPDATE_AUCTION_PAUSE]: FeRequestHandlerEnum.AUCTIONS_HTTP,
    [FeReqRouteEnum.API_AUCTIONS_END_AUCTION_VEHICLE]: FeRequestHandlerEnum.AUCTIONS_HTTP,
    [FeReqRouteEnum.API_AUCTIONS_SKIP_AUCTION_VEHICLE]: FeRequestHandlerEnum.AUCTIONS_HTTP,
    [FeReqRouteEnum.API_AUCTIONS_CHECK_NEXT_ITEM_UPDATED]: FeRequestHandlerEnum.AUCTIONS_HTTP,
    [FeReqRouteEnum.API_AUCTIONS_UPDATE_AUCTION_EXPIRATION]: FeRequestHandlerEnum.AUCTIONS_HTTP,
    [FeReqRouteEnum.API_AUCTIONS_UPDATE_AUCTION_EVENT_START]: FeRequestHandlerEnum.AUCTIONS_HTTP,
    [FeReqRouteEnum.API_AUCTIONS_UPDATE_AUCTION_START]: FeRequestHandlerEnum.AUCTIONS_HTTP,
    [FeReqRouteEnum.API_AUCTIONS_UPDATE_AUCTION_SMS_NOTIFICATIONS]: FeRequestHandlerEnum.AUCTIONS_HTTP,
    [FeReqRouteEnum.API_AUCTIONS_UPDATE_AUCTION_SMS_LEADTIME]: FeRequestHandlerEnum.AUCTIONS_HTTP,
    [FeReqRouteEnum.API_AUCTIONS_UPDATE_AUCTION_SERIES]: FeRequestHandlerEnum.AUCTIONS_HTTP,
    [FeReqRouteEnum.API_AUCTIONS_UPDATE_AUCTION_OCCURRENCE]: FeRequestHandlerEnum.AUCTIONS_HTTP,
    [FeReqRouteEnum.API_AUCTIONS_RESCHEDULE_AUCTION_OCCURRENCE]: FeRequestHandlerEnum.AUCTIONS_HTTP,
    [FeReqRouteEnum.API_AUCTIONS_EXTEND_BIDDING_EXPIRATION]: FeRequestHandlerEnum.AUCTIONS_HTTP,
    [FeReqRouteEnum.API_AUCTIONS_ADD_SELLER_TO_AUCTION_SERIES]: FeRequestHandlerEnum.AUCTIONS_HTTP,
    [FeReqRouteEnum.API_AUCTIONS_REMOVE_SELLER_FROM_AUCTION_SERIES]: FeRequestHandlerEnum.AUCTIONS_HTTP,
    [FeReqRouteEnum.API_AUCTIONS_CLEAN_AUCTIONS]: FeRequestHandlerEnum.AUCTIONS_HTTP,
    [FeReqRouteEnum.API_AUCTIONS_ENABLE_PLACING_BIDS]: FeRequestHandlerEnum.AUCTIONS_HTTP,
    [FeReqRouteEnum.API_AUCTIONS_GET_AUCTION_SCHEDULE]: FeRequestHandlerEnum.AUCTIONS_HTTP,
    [FeReqRouteEnum.API_AUCTIONS_SAVE_AUCTION_SCHEDULE]: FeRequestHandlerEnum.AUCTIONS_HTTP,
    [FeReqRouteEnum.API_AUCTIONS_DELETE_AUCTION_SCHEDULE]: FeRequestHandlerEnum.AUCTIONS_HTTP,
    [FeReqRouteEnum.API_AUCTIONS_DELETE_AUCTION]: FeRequestHandlerEnum.AUCTIONS_HTTP,
    [FeReqRouteEnum.API_AUCTIONS_DELETE_ALL_AUCTIONS_DATA]: FeRequestHandlerEnum.AUCTIONS_HTTP,
    [FeReqRouteEnum.API_AUCTIONS_VALIDATE_CONFIGURATION]: FeRequestHandlerEnum.AUCTIONS_HTTP,
    [FeReqRouteEnum.API_AUCTIONS_SAVE_BID_INCREMENT]: FeRequestHandlerEnum.AUCTIONS_HTTP,
    [FeReqRouteEnum.API_AUCTIONS_CREATE_OR_UPDATE_PLATFORM_FEE_SCHEDULE]: FeRequestHandlerEnum.AUCTIONS_HTTP,
    [FeReqRouteEnum.API_AUCTIONS_GET_LIVE_AUCTIONS_FOR_SELLER]: FeRequestHandlerEnum.AUCTIONS_HTTP,

    [FeReqRouteEnum.API_ITEMS_FIX_ITEMS_EXPIRATION_IN_AUCTION]: FeRequestHandlerEnum.ITEMS_HTTP,
    [FeReqRouteEnum.API_ITEMS_FETCH_VEHICLE_CSV_DATA]: FeRequestHandlerEnum.ITEMS_HTTP,
    [FeReqRouteEnum.API_ITEMS_FETCH_AUCTION_CSV_DATA]: FeRequestHandlerEnum.ITEMS_HTTP,
    [FeReqRouteEnum.API_ITEMS_FETCH_VEHICLE_EXCEL_DATA]: FeRequestHandlerEnum.ITEMS_HTTP,
    [FeReqRouteEnum.API_ITEMS_FETCH_VEHICLES_STATUSES_STATISTIC]: FeRequestHandlerEnum.ITEMS_HTTP,
    [FeReqRouteEnum.API_ITEMS_FETCH_UPCOMING_AUCTIONS_DATA]: FeRequestHandlerEnum.ITEMS_HTTP,
    [FeReqRouteEnum.API_ITEMS_UPLOAD_ITEMS_CSV]: FeRequestHandlerEnum.ITEMS_HTTP,
    [FeReqRouteEnum.API_ITEMS_UPDATE_VEHICLES_IN_FIREBASE]: FeRequestHandlerEnum.ITEMS_HTTP,
    [FeReqRouteEnum.API_ITEMS_UPDATE_VEHICLE_STATUS_IN_FIREBASE]: FeRequestHandlerEnum.ITEMS_HTTP,
    [FeReqRouteEnum.API_ITEMS_UPDATE_VEHICLE_STATUS]: FeRequestHandlerEnum.ITEMS_HTTP,
    [FeReqRouteEnum.API_ITEMS_UPDATE_VEHICLES_STATUS]: FeRequestHandlerEnum.ITEMS_HTTP,
    [FeReqRouteEnum.API_ITEMS_CLONE_VEHICLE]: FeRequestHandlerEnum.ITEMS_HTTP,
    [FeReqRouteEnum.API_ITEMS_GET_ITEMS_FOR_SELLER]: FeRequestHandlerEnum.ITEMS_HTTP,
    [FeReqRouteEnum.API_ITEMS_GET_ITEMS_FOR_BUYER]: FeRequestHandlerEnum.ITEMS_HTTP,
    [FeReqRouteEnum.API_ITEMS_GET_WON_ITEMS_FOR_BUYER]: FeRequestHandlerEnum.ITEMS_HTTP,
    [FeReqRouteEnum.API_ITEMS_GET_INVENTORY_ITEMS_FOR_AUCTION]: FeRequestHandlerEnum.ITEMS_HTTP,
    [FeReqRouteEnum.API_ITEMS_GET_ITEM_SALE_DATA]: FeRequestHandlerEnum.ITEMS_HTTP,
    [FeReqRouteEnum.API_ITEMS_GET_ITEMS_BY_IDS]: FeRequestHandlerEnum.ITEMS_HTTP,
    [FeReqRouteEnum.API_ITEMS_UPDATE_PERSISTED_ITEM]: FeRequestHandlerEnum.ITEMS_HTTP,
    [FeReqRouteEnum.API_ITEMS_GET_ITEM_MEDIA]: FeRequestHandlerEnum.ITEMS_HTTP,
    [FeReqRouteEnum.API_ITEMS_REMOVE_ITEM_MEDIA]: FeRequestHandlerEnum.ITEMS_HTTP,
    [FeReqRouteEnum.API_ITEMS_UPDATE_ITEM_MEDIA_ORDER]: FeRequestHandlerEnum.ITEMS_HTTP,
    [FeReqRouteEnum.API_ITEMS_UPDATE_ITEM_IMAGES_COUNT]: FeRequestHandlerEnum.ITEMS_HTTP,
    [FeReqRouteEnum.API_ITEMS_CREATE_LOADING_ITEM_MEDIA]: FeRequestHandlerEnum.ITEMS_HTTP,
    [FeReqRouteEnum.API_ITEMS_SCHEDULE_VEHICLES]: FeRequestHandlerEnum.ITEMS_HTTP,
    [FeReqRouteEnum.API_ITEMS_RESCHEDULE_VEHICLES]: FeRequestHandlerEnum.ITEMS_HTTP,
    [FeReqRouteEnum.API_ITEMS_REORDER_VEHICLES]: FeRequestHandlerEnum.ITEMS_HTTP,
    [FeReqRouteEnum.API_ITEMS_CURRENT_USER_PAID_ITEMS]: FeRequestHandlerEnum.ITEMS_HTTP,
    [FeReqRouteEnum.API_ITEMS_FIND_SELLER_VEHICLES_BY_SELLER_VEHICLE_ID]: FeRequestHandlerEnum.ITEMS_HTTP,
    [FeReqRouteEnum.API_ITEMS_PERFORM_SELL_VEHICLE_OFFLINE]: FeRequestHandlerEnum.ITEMS_HTTP,
    [FeReqRouteEnum.API_ITEMS_CALCULATE_ITEMS_COUNT]: FeRequestHandlerEnum.ITEMS_HTTP,
    [FeReqRouteEnum.API_ITEMS_UNSCHEDULE_VEHICLE]: FeRequestHandlerEnum.ITEMS_HTTP,

    [FeReqRouteEnum.API_PAYMENT_ADD_PAYMENT_METHOD]: FeRequestHandlerEnum.PAYMENTS_HTTP,
    [FeReqRouteEnum.API_PAYMENT_REMOVE_PAYMENT_METHOD]: FeRequestHandlerEnum.PAYMENTS_HTTP,
    [FeReqRouteEnum.API_PAYMENT_UPDATE_PAYMENT_METHOD]: FeRequestHandlerEnum.PAYMENTS_HTTP,
    [FeReqRouteEnum.API_PAYMENT_GET_FEES_BREAKDOWN_FOR_AMOUNT]: FeRequestHandlerEnum.PAYMENTS_HTTP,
    [FeReqRouteEnum.API_PAYMENT_GENERATE_DEPOSIT_RECEIPT]: FeRequestHandlerEnum.PAYMENTS_HTTP,
    [FeReqRouteEnum.API_PAYMENT_GENERATE_PAY_IN_FULL_BUYER_RECEIPT]: FeRequestHandlerEnum.PAYMENTS_HTTP,
    [FeReqRouteEnum.API_PAYMENT_GENERATE_PAY_IN_FULL_SELLER_RECEIPT]: FeRequestHandlerEnum.PAYMENTS_HTTP,
    [FeReqRouteEnum.API_PAYMENT_STORE_PDF_RECEIPTS]: FeRequestHandlerEnum.PAYMENTS_HTTP,
    [FeReqRouteEnum.API_PAYMENT_GENERATE_PDF_BULK_RECEIPT]: FeRequestHandlerEnum.PAYMENTS_HTTP,
    [FeReqRouteEnum.API_PAYMENT_CREATE_PAYMENT_METHOD_SETUP_INTENT]: FeRequestHandlerEnum.PAYMENTS_HTTP,
    [FeReqRouteEnum.API_PAYMENT_CHECK_IF_USER_IS_ABLE_TO_PAY_FOR_ITEMS]: FeRequestHandlerEnum.PAYMENTS_HTTP,
    [FeReqRouteEnum.API_PAYMENT_GENERATE_CHECKOUT]: FeRequestHandlerEnum.PAYMENTS_HTTP,
    [FeReqRouteEnum.API_PAYMENT_CONFIRM_CC_HOLD]: FeRequestHandlerEnum.PAYMENTS_HTTP,
    [FeReqRouteEnum.API_PAYMENT_CONFIRM_CC_HOLD_AND_PLACE_CC_HOLD_FOR_BIDDER]: FeRequestHandlerEnum.PAYMENTS_HTTP,
    [FeReqRouteEnum.API_PAYMENT_PLACE_CC_HOLD_FOR_BIDDER]: FeRequestHandlerEnum.PAYMENTS_HTTP,
    [FeReqRouteEnum.API_PAYMENT_CREATE_ORDER]: FeRequestHandlerEnum.PAYMENTS_HTTP,
    [FeReqRouteEnum.API_PAYMENT_CREATE_ORDER_PAYMENT_INTENT]: FeRequestHandlerEnum.PAYMENTS_HTTP,
    [FeReqRouteEnum.API_PAYMENT_APPLY_ADDITIONAL_ORDER_FEE]: FeRequestHandlerEnum.PAYMENTS_HTTP,
    [FeReqRouteEnum.API_PAYMENT_CANCEL_ORDER]: FeRequestHandlerEnum.PAYMENTS_HTTP,
    [FeReqRouteEnum.API_PAYMENT_GET_ORDER_PAYMENT_INTENT_DETAILS]: FeRequestHandlerEnum.PAYMENTS_HTTP,

    [FeReqRouteEnum.API_PAYMENT_GET_STRIPE_IDENTITY_VERIFICATION_SESSION]: FeRequestHandlerEnum.PAYMENTS_HTTP,
    [FeReqRouteEnum.API_PAYMENT_SET_STRIPE_IDENTITY_VERIFICATION_AS_PROCESSING]: FeRequestHandlerEnum.PAYMENTS_HTTP,

    [FeReqRouteEnum.API_USER_CREATE_USER]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_BAN_USERS]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_GRANT_RESTRICTED_ACCESS_TO_AUCTION_SERIES_FOR_USER]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_GET_USER_ACTIVE_RESTRICTED_ACCESS_AUCTION_SERIES]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_BAN_USER_FOR_SELLERS]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_IS_USER_BANNED]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_BAN_USER_AS_SELLER]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_BAN_USERS_FOR_AUCTION_SELLER]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_UNBAN_USERS_FOR_SELLERS]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_FIND_SELLERS_FOR_BANNED_USER]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_SEND_BUYER_COMMUNICATION]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_FETCH_BUYER_COMMUNICATIONS]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_SEND_NOTIFICATION_BIDDING_OPEN]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_TOGGLE_FAVORITE_REGION]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_TOGGLE_FAVORITE_AUCTION_SERIES]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_TOGGLE_FAVORITE_AUCTION]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_TOGGLE_FAVORITE_ITEM]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_FETCH_SELLERS_FOR_PREAPPROVED_USER]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_SAVE_SELLERS_FOR_PREAPPROVED_USER]: FeRequestHandlerEnum.USERS_HTTP,

    [FeReqRouteEnum.API_USER_SEND_EMAIL_VERIFICATION_FOR_USER]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_UPDATE_EMAIL_FOR_USER]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_SAVE_USER_NOTE]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_SAVE_CONTACT_DETAILS]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_UPDATE_VERIFICATION_STATUS_BY_ADMIN_FOR_USER]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_UPDATE_USER]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_FIND_USER_BY_EMAIL]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_FIND_USER_BY_ID]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_GET_WINNING_BUYER_INFO]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_GET_USER_ACTIVITY]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_SEARCH_USERS]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_CHECK_IS_USER_BLACKLISTED]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_BAN_USER_GLOBALLY]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_GET_GLOBALLY_BANNED_USER]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_BYPASS_EMAIL_VERIFICATION_FOR_USER]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_BYPASS_PHONE_VERIFICATION_FOR_USER]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_GET_ZIP_CODE_BY_COORDINATES]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_GET_COORDINATES_BY_ZIP_CODE]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_USER_WON_ITEMS]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_MARK_VEHICLE_AS_PICKED_UP]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_CURRENT_USER_WON_ITEMS]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_CURRENT_USER_CLAIMED_ITEMS]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_GET_VEHICLES_WITH_FAILED_DEPOSIT]: FeRequestHandlerEnum.USERS_HTTP,

    [FeReqRouteEnum.API_USER_GET_ZIP_CODE_BY_IP]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_MARK_FOR_REMOVE_TRANSIENT_EVENT_STATE]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_UPDATE_STATE_FOR_ALL_USER_PERSISTED_NOTIFICATIONS]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_SAVE_PERSISTED_NOTIFICATIONS]: FeRequestHandlerEnum.USERS_HTTP,

    [FeReqRouteEnum.API_CHECKOUT_SUMMARY_DATA]: FeRequestHandlerEnum.USERS_HTTP,

    [FeReqRouteEnum.API_BID_CHECK_AUCTION_BIDDING_ENDED]: FeRequestHandlerEnum.BIDS_HTTP,
    [FeReqRouteEnum.API_BID_CANCEL_AUTO_BID]: FeRequestHandlerEnum.BIDS_HTTP,
    [FeReqRouteEnum.API_BID_REVERT_BID]: FeRequestHandlerEnum.BIDS_HTTP,
    [FeReqRouteEnum.API_BID_GET_PERSISTED_BIDS]: FeRequestHandlerEnum.BIDS_HTTP,
    [FeReqRouteEnum.API_BIDS_FETCH_ALL_ITEMS_WITH_OFFERS]: FeRequestHandlerEnum.BIDS_HTTP,
    [FeReqRouteEnum.API_BIDS_HANDLE_HIGHEST_OFFER_BY_SELLER]: FeRequestHandlerEnum.BIDS_HTTP,
    [FeReqRouteEnum.API_BIDS_REJECTY_ALL_ACTIVE_OFFERS]: FeRequestHandlerEnum.BIDS_HTTP,

    [FeReqRouteEnum.API_GOOGLE_VALIDATE_GOOGLE_DRIVE_FOLDER]: FeRequestHandlerEnum.APP_HTTP,
    [FeReqRouteEnum.API_GOOGLE_SET_GOOGLE_DRIVE_QUEUE]: FeRequestHandlerEnum.APP_HTTP,
    [FeReqRouteEnum.API_GOOGLE_GOOGLE_IMPORT_MANUAL]: FeRequestHandlerEnum.APP_HTTP,

    [FeReqRouteEnum.API_USER_ROLES_ADD_ROLE_TO_USER]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_ROLES_REMOVE_ROLE_FOR_USER]: FeRequestHandlerEnum.USERS_HTTP,
    [FeReqRouteEnum.API_USER_ROLES_FIND_ASSOCIATED_USERS_FOR_SELLER]: FeRequestHandlerEnum.USERS_HTTP,

    [FeReqRouteEnum.API_PHONE_SEND_VERIFICATION_CODE]: FeRequestHandlerEnum.PHONES_HTTP,
    [FeReqRouteEnum.API_PHONE_CHECK_VERIFICATION_CODE]: FeRequestHandlerEnum.PHONES_HTTP,
    [FeReqRouteEnum.API_PHONE_CANCEL_PHONE_VERIFICATION]: FeRequestHandlerEnum.PHONES_HTTP,
    [FeReqRouteEnum.API_PHONE_VALIDATE_PHONE_NUMBER]: FeRequestHandlerEnum.PHONES_HTTP,

    [FeReqRouteEnum.API_WHITELIST_GET_WHITELIST_PHONES]: FeRequestHandlerEnum.PHONES_HTTP,
    [FeReqRouteEnum.API_WHITELIST_CREATE_WHITELIST_PHONE]: FeRequestHandlerEnum.PHONES_HTTP,
    [FeReqRouteEnum.API_WHITELIST_REMOVE_WHITE_LIST_PHONE]: FeRequestHandlerEnum.PHONES_HTTP,

    [FeReqRouteEnum.API_AUDIT_CREATE_EVENT]: FeRequestHandlerEnum.APP_HTTP,
    [FeReqRouteEnum.API_UPDATE_GLOBAL_CONFIG]: FeRequestHandlerEnum.APP_HTTP,
};

/**
 * It knows how to build full URLs for sending requests (i.e. it will direct the request to a proper BE handler).
 */
export function createFERoutingService(config: FERoutingConfig) {
    return new RoutingService<FeRequestHandlerEnum>(
        feHandlersURLs(config),
        FALLBACK_FE_REQUEST_HANDLER,
        feHandlersByRoute
    );
}
