import {flow, Instance, types} from 'mobx-state-tree';
import {RenamePaymentMethodModalViewModel} from './RenamePaymentMethodModalViewModel';
import {VisibilityViewModel} from '@joyrideautos/ui-models/src/common/VisibilityViewModel';
import {PaymentMethodType} from '@joyrideautos/ui-models/src/types/UserInfo';
import {LoadingStatus, withStatus} from '@joyrideautos/ui-models/src/utils/LoadingStatus';
import BaseViewModel from '@joyrideautos/ui-models/src/BaseViewModel';
import {PaymentMethodSetupStatusEnum} from '@joyrideautos/auction-core/src/dtos/PaymentsDto';

export const PaymentMethodsGridViewModel = BaseViewModel.named('PaymentMethodsGridViewModel')
    .props({
        visibilityViewModel: types.optional(VisibilityViewModel, {}),
    })
    .volatile(() => ({
        status: new LoadingStatus(),
        deletingIndex: undefined as number | undefined,
        paymentMethodIdForEdit: undefined as string | undefined,
    }))
    .views((self) => {
        return {
            get userInfo() {
                return self.authUserStore.userInfo;
            },
            get paymentMethods(): PaymentMethodType[] {
                return (
                    this.userInfo?.paymentMethods.slice().sort((a, b) => (b.createdAt ?? 0) - (a.createdAt ?? 0)) ?? []
                );
            },
            get paymentMethodModalViewModel() {
                return self.getOrCreateViewModel(
                    RenamePaymentMethodModalViewModel,
                    {paymentMethodId: self.paymentMethodIdForEdit},
                    {rootStore: self.rootStore}
                );
            },
            get failedPaymentMethod(): PaymentMethodType | undefined {
                return (
                    this.paymentMethods.find((pm) => pm.setupStatus === PaymentMethodSetupStatusEnum.PENDING) ||
                    this.paymentMethods.find((pm) => pm.setupStatus === PaymentMethodSetupStatusEnum.FAILED)
                );
            },
        };
    })
    .actions((self) => ({
        resetStatus() {
            self.status.setNew();
        },
        setDeletingIndex(index?: number) {
            self.deletingIndex = index;
        },
    }))
    .actions((self) => {
        return {
            deletePaymentMethod: flow(function* (paymentMethod: PaymentMethodType) {
                if (!self.userInfo) {
                    return;
                }
                const deletingIndex = self.paymentMethods.findIndex(
                    (p) => p.paymentMethodId === paymentMethod.paymentMethodId
                );
                self.setDeletingIndex(deletingIndex);
                try {
                    yield withStatus(self.status)(() =>
                        self.rootStore.paymentService.removePaymentMethod({
                            paymentMethodId: paymentMethod.paymentMethodId,
                        })
                    );
                } catch (e: any) {
                    console.log(e.message);
                }
                self.setDeletingIndex(undefined);
            }),
            makeDefault: flow(function* (paymentMethod: PaymentMethodType) {
                if (!self.userInfo) {
                    return;
                }
                yield withStatus(self.status)(() =>
                    self.rootStore.paymentService.updatePaymentMethod({
                        paymentMethodId: paymentMethod.paymentMethodId,
                        isDefault: true,
                    })
                );
            }),
            setPaymentMethodForEdit(id: string | undefined) {
                self.paymentMethodIdForEdit = id;
            },
        };
    });

export interface PaymentMethodsGridViewModelType extends Instance<typeof PaymentMethodsGridViewModel> {}
