import {flow, Instance, types} from 'mobx-state-tree';
import {LoadingStatus} from '@joyrideautos/ui-models/src/utils/LoadingStatus';
import {declarativeValidator} from '@joyrideautos/ui-models/src/smartFields/validators/Validators';
import {InputFieldModel} from '@joyrideautos/ui-models/src/smartFields/models/InputFieldModel';
import {when} from 'mobx';
import RootStoreAwareViewModel from '@joyrideautos/web-common-components/src/models/RootStoreAwareViewModel';
import {PAYMENT_FIELDS_LENGTH} from '@joyrideautos/auction-core/src/dtos/PaymentsDto';

export const RenamePaymentMethodModalViewModel = RootStoreAwareViewModel.named('RenamePaymentMethodModalViewModel')
    .props({
        paymentMethodId: types.optional(types.string, ''),
        paymentMethodName: types.optional(types.string, ''),
        zipcode: types.optional(types.string, ''),
    })
    .volatile(() => ({
        status: new LoadingStatus(),
        paymentMethodNameValidator: declarativeValidator(
            `required|less_than:${PAYMENT_FIELDS_LENGTH.PAYMENT_METHOD_NAME}`
        ),
        replaceCardId: undefined as string | undefined,
        disposer: undefined as (() => void) | undefined,
    }))
    .volatile((self) => ({
        paymentMethodNameConnector: new InputFieldModel(self.paymentMethodNameValidator, true),
        zipcodeConnector: new InputFieldModel(self.paymentMethodNameValidator, true),
    }))
    .actions((self) => ({
        setPaymentMethodName(name?: string) {
            self.paymentMethodName = name || '';
        },
        resetForm() {
            self.status.setNew();
            this.setPaymentMethodName(undefined);
        },
    }))
    .views((self) => {
        return {
            get userInfo() {
                return self.authUserStore.userInfo;
            },
            get paymentMethod() {
                return this.userInfo && this.userInfo.findPaymentMethod(self.paymentMethodId);
            },
            get paymentMethodNameModel() {
                return self.paymentMethodNameConnector.connect(self.paymentMethodName, self.setPaymentMethodName);
            },
        };
    })
    .actions((self) => ({
        addNewPaymentMethod(forReplace: string | undefined = undefined) {
            self.replaceCardId = forReplace;
        },
    }))
    .actions((self) => {
        return {
            renamePaymentMethod: flow(function* () {
                if (!self.userInfo) {
                    return;
                }
                self.status.setInProgress();
                yield self.rootStore.paymentService.updatePaymentMethod({
                    paymentMethodId: self.paymentMethodId,
                    paymentMethodName: self.paymentMethodName,
                });
                self.status.setReady();
            }),
        };
    })
    .actions((self) => ({
        afterCreate() {
            self.disposer = when(
                () => Boolean(self.paymentMethod),
                () => {
                    self.setPaymentMethodName(self.paymentMethod!.paymentMethodName);
                }
            );
        },
        beforeDestroy() {
            self.disposer && self.disposer();
        },
    }));

export interface RenamePaymentMethodModalViewModelType extends Instance<typeof RenamePaymentMethodModalViewModel> {}
