import React, {FC} from 'react';
import {LoadingOutlined} from '@ant-design/icons';
import {Row, Col, Spin} from 'antd';
import classNames from 'classnames';
import styles from './Loading.module.less';

export interface LoadingProps {
    tip?: string;
    testId?: string;
    hideTip?: boolean;
}

export const Loading: FC<LoadingProps> = ({tip, testId, hideTip}) => {
    const _tip = hideTip ? undefined : tip || 'Loading...';
    return (
        <Row justify={'center'} align={'middle'}>
            <Col className={classNames(styles.container, {[styles.tip]: _tip})}>
                <Spin data-test-id={testId} indicator={<LoadingOutlined spin />} tip={_tip}>
                    {' '}
                </Spin>
            </Col>
        </Row>
    );
};
