export enum CurrencyConverterTypeEnum {
    CENT_TO_DOLLAR = 'CENT_TO_DOLLAR',
    DOLLAR_TO_CENT = 'DOLLAR_TO_CENT',
}

export const applyCurrencyConverter = (
    currencyConverterType: CurrencyConverterTypeEnum | undefined
): ((amount: number) => number) => {
    switch (currencyConverterType) {
        case CurrencyConverterTypeEnum.CENT_TO_DOLLAR:
            return (amount) => amount / 100;
        case CurrencyConverterTypeEnum.DOLLAR_TO_CENT:
            return (amount) => amount * 100;
        default:
            return (amount) => amount;
    }
};
