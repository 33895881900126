import {useViewModel} from '@joyrideautos/ui-models/src/hooks/useViewModel';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import {Button, Flex} from 'antd';
import {observer} from 'mobx-react-lite';
import {FC, ReactNode} from 'react';
import {StripeAddPaymentMethodFormViewModel} from './StripeAddPaymentMethodFormViewModel';
import {PlusOutlined} from '@ant-design/icons';
import {Only} from '@joyrideautos/ui-common/src/components/Only';
import StripePaymentMethodStatus from '../StripePaymentMethodStatus';

interface StripeAddPaymentMethodFormProps {
    onClose?: () => void;
    cancelAction?: ReactNode;
    isRedirectAlways?: boolean;
    warning?: ReactNode;
    withButton?: boolean;
}

export const StripeAddPaymentMethodForm: FC<StripeAddPaymentMethodFormProps> = observer(
    ({onClose, cancelAction, isRedirectAlways, warning, withButton = false}) => {
        const stripe = useStripe();
        const elements = useElements();
        const {viewModel} = useViewModel(StripeAddPaymentMethodFormViewModel, {
            stripeFormVisibility: {value: !withButton},
        });
        if (!viewModel) {
            return null;
        }
        const onCloseAction = () => (onClose ? onClose() : viewModel.stripeFormVisibility.setVisible(false));
        viewModel.setOnSuccess(() => onCloseAction);

        const cancelButton = cancelAction
            ? cancelAction
            : !!onCloseAction && (
                  <Button disabled={!stripe} htmlType="button" onClick={onCloseAction}>
                      Cancel
                  </Button>
              );
        return (
            <>
                <StripePaymentMethodStatus onFailed={() => viewModel.stripeFormVisibility.setVisible(true)} />
                <Only when={withButton && !viewModel.stripeFormVisibility.value}>
                    <Button
                        data-testid="personal-contact-info-page-add-payment-method-button"
                        ghost
                        onClick={() => viewModel.stripeFormVisibility.setVisible(true)}
                        type="primary">
                        <PlusOutlined />
                        Add Payment Method
                    </Button>
                </Only>
                <Only when={viewModel.stripeFormVisibility.value}>
                    <form onSubmit={viewModel.getSubmitHandler(stripe, elements, isRedirectAlways)}>
                        <Flex vertical gap={20}>
                            <PaymentElement
                                options={viewModel.stripePaymentElementsOptions}
                                onChange={(params) => viewModel.isReady.setValue(params.complete)}
                            />
                            {warning}
                            <Flex justify="end" gap={20}>
                                <Button
                                    type="primary"
                                    disabled={
                                        !stripe ||
                                        !viewModel.isReady.value ||
                                        viewModel.submitHandlerLoadingStatus.isInProgress
                                    }
                                    htmlType="submit"
                                    loading={viewModel.submitHandlerLoadingStatus.isInProgress}>
                                    Add
                                </Button>
                                {cancelButton}
                            </Flex>
                        </Flex>
                    </form>
                </Only>
            </>
        );
    }
);
