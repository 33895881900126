import {Instance, types} from 'mobx-state-tree';
import {PersistedItemStatus} from './item/PersistedItem';
import {PriceBreakdownViewModel} from '../presentation/biddingCard/PriceBreakdownViewModel';

export const ClaimSessionItem = types.model('ClaimSessionItem', {
    status: PersistedItemStatus,
    preConfirmedStatus: types.maybe(PersistedItemStatus),
    updatedAt: types.maybe(types.string),
    updatedBy: types.maybe(types.string),
});

export interface ClaimSessionItemType extends Instance<typeof ClaimSessionItem> {}

export const ClaimSessionBuyerInfo = types.model('ClaimSessionBuyerInfo', {
    userId: types.string,
    name: types.string,
    companyName: types.string,
    email: types.string,
    phone: types.string,
    address: types.string,
});

export const ClaimSessionPaymentInfo = types.model('ClaimSessionPaymentInfo', {
    priceBreakdownViewModel: PriceBreakdownViewModel,
});

export const ClaimSession = types
    .model('ClaimSession', {
        createdAt: types.string, // when calculating 96 hours we don't look at this date; we look at the soldAt
        gateCode: types.string,
        buyerId: types.string, // = userId of the buyer who paid for the items
        sellerId: types.string,
        // All items below will correspond to the same location, but convenient to have sellerLocationId as a separate field
        sellerLocationId: types.string,
        soldAt: types.string,
        items: types.map(ClaimSessionItem), // {[inventory item id]: ClaimSessionItem }
    })
    .views((self) => ({
        get totalPurchasedItemsCount() {
            return self.items.size;
        },
    }));

export interface ClaimSessionModelType extends Instance<typeof ClaimSession> {}
