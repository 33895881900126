import {Instance, SnapshotIn, types} from 'mobx-state-tree';
import {Announcement} from './Announcement';
import {AuctionTypeEnum, isRestrictedAuction} from '@joyrideautos/auction-core/src/types/AuctionTypes';
import {AuctionTemplate} from './AuctionTemplate';
import {AuctionAccess} from './types';
import {AuctionSeriesDto} from '@joyrideautos/auction-core/src/dtos/AuctionSeriesDto';

export const AuctionSeriesAnnouncement = types.model('AuctionSeriesAnnouncement', {
    regular: types.maybe(Announcement),
    live: types.maybe(Announcement),
});

export interface AuctionSeriesAnnouncementType extends Instance<typeof AuctionSeriesAnnouncement> {}

export const AuctionSeries = AuctionTemplate.named('AuctionSeries')
    .props({
        id: types.identifierNumber,
        key: types.string,
        regionId: types.string,
        access: types.maybe(AuctionAccess),
        text: types.maybe(types.string),
        title: types.string,
        active: types.boolean,
        ended: types.maybe(types.boolean),
        announcement: types.maybe(AuctionSeriesAnnouncement),
        sellers: types.maybe(types.map(types.boolean)),
        name: types.maybe(types.string),
        streamUrl: types.maybe(types.string),
    })
    .views((self) => ({
        get isRestricted() {
            return isRestrictedAuction(self.access);
        },
        get isSequence() {
            return self.auctionType === AuctionTypeEnum.SEQUENCE;
        },
        get isListing() {
            return self.auctionType === AuctionTypeEnum.LISTING;
        },
    }));

export interface AuctionSeriesType extends Instance<typeof AuctionSeries> {}

export interface AuctionSeriesSnapshotType extends SnapshotIn<typeof AuctionSeries> {}

export function fromAuctionSeriesDto(series: AuctionSeriesDto): AuctionSeriesSnapshotType {
    return series as unknown as AuctionSeriesSnapshotType;
}

export function toPartialAuctionSeriesDto(series: Partial<AuctionSeriesType>): Partial<AuctionSeriesDto> {
    const {key, regionId, sellers, ...rest} = series;
    if (sellers) {
        return {
            ...rest,
            sellers: Array.from(sellers.keys()).reduce(
                (result, sellerId) => ({...result, [sellerId]: sellers.get(sellerId)}),
                {}
            ),
        } as Partial<AuctionSeriesDto>;
    }

    return rest as Partial<AuctionSeriesDto>;
}
