import {FeeSchedule, SellerSettings} from '../dtos/SellerDto';
import {WinningBidFees} from '../dtos/WinningBidDto';
import {Money} from '../rules/Money';
import {WinningBidFeesInMoney} from './types';

export function getActiveFeeSchedule(settings: SellerSettings): FeeSchedule | undefined {
    const now = Date.now();

    const sortedSchedules =
        settings.feeSchedule && settings.feeSchedule.items
            ? settings.feeSchedule.items
                  .map((item) => ({...item, date: Date.parse(item.effectiveDate)}))
                  .sort((i1, i2) => i2.date - i1.date)
            : [];
    return sortedSchedules // sort descending chronological order
        .find((item) => now >= item.date);
}

export function getActiveFeeScheduleIndex(settings: SellerSettings): number {
    const now = Date.now();

    const sortedSchedules =
        settings.feeSchedule && settings.feeSchedule.items
            ? settings.feeSchedule.items
                  .map((item, index) => ({date: Date.parse(item.effectiveDate), index}))
                  .sort((i1, i2) => i2.date - i1.date)
            : [];
    const found = sortedSchedules.find((item) => now >= item.date);
    return found ? found.index : -1;
}

export function x1000(num: number) {
    return num * 1000;
}

export function x100(num: number) {
    return Math.round(num * 100_00) / 100;
}

export function div100(num?: number) {
    return (num ?? 0) / 100;
}

export function isForLastPeriodInDays(timestamp: string | undefined, periodDays: number) {
    if (timestamp) {
        const diff = new Date().getTime() - new Date(timestamp).getTime();
        const totalDays = Math.ceil(diff / (1000 * 3600 * 24));
        return totalDays < periodDays;
    } else {
        return false;
    }
}

export function takePercent(amount: number, percent: number, needRound = true) {
    const caclulatedAmont = percent > 0 ? amount / (1 + percent) : amount;
    return needRound ? Math.round(caclulatedAmont) : caclulatedAmont;
}

export function isValidPercent(perc: number) {
    return isValidPositiveNumberOrZero(perc) && perc <= 1;
}

export function isValidPositiveNumberOrZero(amount: number) {
    return amount >= 0 && !isNaN(amount) && isFinite(amount) && amount <= Number.MAX_SAFE_INTEGER;
}

export function validateAmount(amountInCents: number, name: string): void {
    if (!isValidPositiveNumberOrZero(amountInCents)) {
        throw Error(`illegal argument: wrong ${name} ${amountInCents}`);
    }
}

export function validateMoney(amount: Money | undefined, name: string): void {
    if (Number.isNaN(amount) || (amount && !isValidPositiveNumberOrZero(amount.cents))) {
        throw Error(`illegal argument: wrong ${name} ${amount?.cents ?? amount}`);
    }
}

export function validatePercent(perc: any, name: string): boolean {
    if (!isValidPercent(perc)) {
        throw Error(`Illegal argument: wrong ${name} ${perc}. Expected 0 <= ${name} <= 1`);
    }
    return true;
}

export const calculateTotal = (fees: WinningBidFees): number => {
    const {salePrice, platformFee, adminFee, buyerFee, salesTax} = fees;
    return (x1000(salePrice) + x1000(platformFee) + x1000(adminFee) + x1000(buyerFee) + x1000(salesTax || 0)) / 1000;
};

export const zeroWinningBidFees: WinningBidFeesInMoney = {
    salePrice: Money.zero,
    total: Money.zero,
    totalSellerDue: Money.zero,
    bid: Money.zero,
    platformFee: Money.zero,
    adminFee: Money.zero,
    buyerFee: Money.zero,
    salesTax: Money.zero,
    storageTax: Money.zero,
    variableFee: Money.zero,
    vehicleKeyFee: Money.zero,
    vehicleOtherFixedFee: Money.zero,
};

export function numberToMoney(amount: number | undefined): Money {
    return amount ? Money.fromDollars(amount) : Money.zero;
}

export function moneyToDollars(amount: Money | undefined): number {
    return amount ? amount.dollars : 0;
}

export function moneyToCents(amount: Money | undefined): number {
    return amount ? amount.cents : 0;
}
