import {loadCustomScriptWithPromise} from '@joyrideautos/web-common-components/src/utils/Utils';
import {FC, memo, useEffect, useRef} from 'react';
import {getAppConfig} from '@joyrideautos/ui-services/src/AppConfig';

const appConfig = getAppConfig();

declare global {
    interface Window {
        $zopim: any;
    }
}

type LiveChat = {button: {hide: () => void; show: () => void}; window: any};

class ZendeskWidgetWrapper {
    private zopim: {livechat: LiveChat} | undefined;
    private loadingPromise?: Promise<void>;

    hide() {
        if (!this.liveChat) {
            return;
        }
        this.liveChat.button.hide();
    }

    show() {
        if (!this.liveChat) {
            return;
        }
        this.liveChat.button.show();
    }

    setOffset(offset?: {vertical?: number; horizontal?: number}) {
        this.liveChat?.window.setOffsetVertical(offset?.vertical);
        this.liveChat?.window.setOffsetHorizontal(offset?.horizontal);
    }

    loadIfNeeded(onLoad?: (status: {isReady: boolean; error?: string}) => void) {
        if (!this.needToLoad) {
            return Promise.resolve();
        }

        if (!this.loadingPromise) {
            this.loadingPromise = loadCustomScriptWithPromise(this.url!, {id: 'ze-snippet', charset: 'utf-8'})
                .then(() => this.waitForLivechat())
                .then(() => {
                    this.zopim = window.$zopim;
                    onLoad && onLoad({isReady: true});
                })
                .catch((error) => {
                    onLoad && onLoad({isReady: false, error: error.message});
                });
        }
        return this.loadingPromise;
    }

    private get liveChat() {
        return this.zopim?.livechat;
    }

    private get needToLoad() {
        return this.url && typeof this.zopim === 'undefined';
    }

    private get url(): string | undefined {
        if (appConfig.commonConfig.zendeskChatAppId) {
            return `https://static.zdassets.com/ekr/snippet.js?key=${appConfig.commonConfig.zendeskChatAppId}`;
        }
        return undefined;
    }

    private waitForLivechat() {
        const name = '$zopim';
        return new Promise<void>((res, rej) => {
            // TODO: (future) handle timeout
            const wait = () => {
                setTimeout(() => {
                    if (typeof window[name] === 'undefined') {
                        wait();
                    } else {
                        res();
                    }
                }, 1000);
            };
            if (typeof window[name] === 'undefined') {
                wait();
            } else {
                res();
            }
        });
    }
}

const zendeskWrapper = new ZendeskWidgetWrapper();

export const ZendeskWidgetView: FC<{
    visible?: boolean;
    onLoad?: (status: {isReady: boolean; error?: string}) => void;
    offset?: {vertical?: number; horizontal?: number};
}> = memo(({visible, onLoad, offset}) => {
    const zopim = useRef(zendeskWrapper);
    useEffect(() => {
        zopim.current
            .loadIfNeeded(onLoad)
            .then(() => {
                if (visible ?? true) {
                    zopim.current.show();
                    zopim.current.setOffset(offset);
                } else {
                    zopim.current.hide();
                }
            })
            .catch((e) => console.log(e));
    }, [visible, onLoad, offset]);
    return null;
});
