import {types, Instance} from 'mobx-state-tree';
import {Interpreter} from 'xstate';
import {AuctionType} from '../types/auction/Auction';
import {WinningBidInfo, WinningBidType} from '../types/WinningBid';
import {autorun, when} from 'mobx';
import type {ItemType} from '../types/item/Item';
import {EventSubscriptionType, EventSubscriptionTypeEnum} from '../types/events/EventSubscription';
import {ONE_MIN, ONE_SEC} from '@joyrideautos/auction-utils/src/dateTimeUtils';
import {
    ListingAuctionStateEnum,
    ListingAuctionStateMachineContext,
    ListingAuctionStateMachineEvent,
    ListingAuctionStateMachineSchema,
    SequenceAuctionStateEnum,
    SequenceAuctionStateMachineContext,
    SequenceAuctionStateMachineEvent,
    SequenceAuctionStateMachineSchema,
    END_BIDDING_INTERMISSION_TIME_BETWEEN_SELLERS_IN_MS,
    BIDDING_CHECKING_RESULT_TIME_IN_MS,
} from './statemachines/types';
import {Timer} from './statemachines/Timer';
import {WinningBidContainerType} from '../stores/WinningBidStore';
import BaseViewModel from '../BaseViewModel';
import {DEFAULT_VEHICLE_TRANSITION_INTERVAL_SEC} from '@joyrideautos/auction-core/src/dtos/AuctionSeriesDto';

export const START_AUCTION_REMINDER = ONE_MIN;
type SequenceAuctionSMService = Interpreter<
    SequenceAuctionStateMachineContext,
    SequenceAuctionStateMachineSchema,
    SequenceAuctionStateMachineEvent
>;
type ListingAuctionSMService = Interpreter<
    ListingAuctionStateMachineContext,
    ListingAuctionStateMachineSchema,
    ListingAuctionStateMachineEvent
>;

export const BaseLiveAuctionStateModel = BaseViewModel.named('BaseLiveAuctionStateModel')
    .props({
        regionId: types.string,
        auctionId: types.string,
        needsResetupAuction: false,
    })
    .volatile(() => ({
        _auctionState: null as SequenceAuctionStateEnum | ListingAuctionStateEnum | null,
        _smService: null as SequenceAuctionSMService | ListingAuctionSMService | null,
        countDownTimer: new Timer(), // TODO: (Future) inject logger
        _currentItem: undefined as ItemType | undefined,
        _disposers: [] as (() => void)[],
    }))
    .views((self) => {
        // Cant use a specific type because of circle dependencies;
        return {
            get itemsStore() {
                return self.itemsStoreFactory.getItemsStoreForAuction({
                    regionId: self.regionId,
                    auctionId: self.auctionId,
                });
            },
            get auction(): AuctionType | undefined {
                return self.auctionsStore.findAuction(self.regionId, self.auctionId);
            },
            get winningBidContainer(): WinningBidContainerType | undefined {
                return self.winningBidStore.containerForAsync(self.regionId, self.auctionId);
            },
            getItem(key: string) {
                if (!this.itemsStore.loadingStatus.isReady) {
                    return;
                }
                return this.itemsStore.getItem(self.regionId, self.auctionId, key);
            },
            getWinningBid(key: string): WinningBidInfo | undefined {
                if (!this.winningBidContainer?.loadingStatus.isReady) {
                    return;
                }
                const dto = this.winningBidContainer?.getValue(key);
                if (dto) {
                    const item = this.getItem(key);
                    if (item) {
                        return {
                            itemKey: key,
                            itemId: item.itemId ? String(item.itemId) : '',
                            amount: dto.amountNumber,
                            uid: dto.uid,
                            ended: dto.ended || false,
                            skipped: dto.skipped,
                            start: dto.start ? self.clock.parseDate(dto.start) : Number.MAX_VALUE,
                            expiration: dto.expiration ? self.clock.parseDate(dto.expiration) : 0,
                            timestamp: dto.timestamp ? self.clock.parseDate(dto.timestamp) : undefined,
                            reserveMet: dto.reserveMet,
                        };
                    }
                }
            },
        };
    })
    .views((self) => ({
        get auctionState() {
            return self._auctionState;
        },
        get currentItem() {
            return self._currentItem;
        },
    }))
    .actions((self) => ({
        setState(state: SequenceAuctionStateEnum | ListingAuctionStateEnum) {
            self._auctionState = state;
        },
        setSmService(smService: SequenceAuctionSMService | ListingAuctionSMService) {
            self._smService = smService;
        },
        setNeedsResetupAuction(needsToResetup: boolean) {
            self.needsResetupAuction = needsToResetup;
        },
    }))
    .views((self) => ({
        get isAuctionInProgress() {
            return false;
        },
        isAcceptBids(itemKey: string) {
            return false;
        },
        get biddingItem(): ItemType | undefined {
            return undefined;
        },
        get nextBiddingItem(): ItemType | undefined {
            return undefined;
        },
        get startItemKey(): string | undefined {
            return undefined;
        },
        get numberOfItemsLeftInAuction(): number | undefined {
            return undefined;
        },
        get currentWinningBid(): WinningBidInfo | undefined {
            return undefined;
        },
        get previousWinningBid(): WinningBidType | undefined {
            return undefined;
        },
        get lastWinningBid(): WinningBidType | undefined {
            return undefined;
        },

        get startAuctionTime() {
            if (!self.auction?.settings.startByAuctionType) {
                return NaN;
            }
            return self.clock.parseDate(self.auction.settings.startByAuctionType);
        },

        get endAuctionTime(): number | undefined {
            return undefined;
        },

        get startPreBidTime() {
            if (!self.auction) {
                return NaN;
            }

            return self.clock.parseDate(self.auction.settings.start);
        },

        get extendExpirationBySeconds() {
            if (!self.auction) {
                return NaN;
            }
            return self.auction.settings.extendExpirationBySeconds || 0;
        },

        get timeUntilAuctionStart() {
            if (isNaN(this.startAuctionTime)) {
                return NaN;
            }
            const draftRemainder = this.startAuctionTime - Date.now();
            return draftRemainder > 0 ? draftRemainder : 0;
        },

        get timeUntilAuctionStartInMs() {
            if (isNaN(this.startAuctionTime)) {
                return NaN;
            }
            return this.startAuctionTime > 0 ? this.startAuctionTime : 0;
        },

        get timeUntilItemBidEndsInMs() {
            return this.currentWinningBid ? this.currentWinningBid.expiration - BIDDING_CHECKING_RESULT_TIME_IN_MS : 0;
        },

        get isLive() {
            return false;
        },

        get acceptsPreBids() {
            return false;
        },

        get isEmpty() {
            const items = self.itemsStore.getAllItems();
            return self.itemsStore.loadingStatus.isReady && items.length === 0;
        },

        get isPaused() {
            return false;
        },

        get isBeforePreBid() {
            return false;
        },

        get isLiveBidding() {
            return false;
        },

        get isSuspended() {
            return false;
        },

        get isEnded() {
            return false;
        },

        get isBeforeStart() {
            return false;
        },

        get isBeforeBidding() {
            return false;
        },

        get isBidding() {
            return false;
        },

        get biddingProgress() {
            return 0;
        },

        get isAfterBidding() {
            return false;
        },

        get alertVisibilityDependsOnState() {
            return false;
        },

        isCurrentItem(itemKey: string) {
            return false;
        },

        get startAuctionRemainder() {
            if (isNaN(this.startAuctionTime)) {
                return NaN;
            }
            const draftRemainder = this.startAuctionTime - Date.now();
            const startAuctionRemainder =
                draftRemainder > START_AUCTION_REMINDER ? START_AUCTION_REMINDER : Math.max(draftRemainder, 0);
            return startAuctionRemainder;
        },

        get startAuctionRemainderInMs() {
            if (isNaN(this.startAuctionTime)) {
                return NaN;
            }

            const now = Date.now();
            const diff = this.startAuctionTime - now;
            const auctionReminderInMillis = diff < START_AUCTION_REMINDER ? diff : 0;
            return now + auctionReminderInMillis;
        },

        hasAuctionReminder() {
            const {userInfo} = self.authUserStore;
            if (userInfo && userInfo.uid) {
                return Boolean(
                    self.eventsStore.hasSubscription(
                        (s: EventSubscriptionType) =>
                            s.type === EventSubscriptionTypeEnum.AUCTION_START && s.params!.auctionId === self.auctionId
                    )
                );
            }
            return false;
        },

        getItemCountdown(itemKey?: string) {
            return -1;
        },

        get prevItem() {
            const currentItem = self.currentItem;
            if (currentItem?.prev) {
                return self.itemsStore.getItem(self.regionId, self.auctionId, currentItem.prev);
            }
            return null;
        },

        get endBiddingIntermissionTime() {
            if (!self.auction) {
                return 0;
            }
            let time: number;
            if (self.currentItem && this.prevItem && self.currentItem.sellerId !== this.prevItem.sellerId) {
                const extendedDurationBetweenSellers =
                    self.auction?.settings.durationBetweenSellersInSeconds ||
                    END_BIDDING_INTERMISSION_TIME_BETWEEN_SELLERS_IN_MS;
                time = extendedDurationBetweenSellers;
            } else {
                time =
                    self.auction.settings.vehicleTransitionIntervalInSeconds ?? DEFAULT_VEHICLE_TRANSITION_INTERVAL_SEC;
            }
            return time;
        },
        get endBiddingIntermissionTimeInMs() {
            return this.endBiddingIntermissionTime * ONE_SEC;
        },
    }))
    .actions((self) => ({
        makeFirstItem(): ItemType | undefined {
            return undefined;
        },
        makeNextItem() {},
        setCurrentItem(itemKey: string | null) {},
        setAuctionReminder() {
            // TODO: migrate to auction/authUserStore store???
            const {userInfo} = self.authUserStore;
            if (userInfo?.uid) {
                const {auctionId, regionId, auction} = self;
                const {uid} = userInfo;
                const startTime = auction!.settings.startByAuctionTypeAsDate;
                const params = {
                    auctionId,
                    regionId,
                    startTime: startTime ? startTime.valueOf() : undefined,
                };
                self.eventsStore.addEventSubscription({
                    type: EventSubscriptionTypeEnum.AUCTION_START,
                    uid,
                    params,
                });
            }
        },
        setupAuction() {},
    }))
    .actions((self) => {
        const checkDependencies = () => {
            return Boolean(
                self.itemsStore.loadingStatus.isReady &&
                    self.auction !== undefined &&
                    self.winningBidContainer?.loadingStatus.isReady
            );
        };

        return {
            afterCreate() {
                self._disposers.push(
                    when(checkDependencies, () => {
                        self.setupAuction();
                        self._disposers.push(
                            autorun(() => {
                                if (self.needsResetupAuction && checkDependencies()) {
                                    self.setupAuction();
                                }
                            })
                        );
                    })
                );
            },
            beforeDestroy() {
                self._disposers.forEach((disposer) => disposer());
                if (self._smService) {
                    self._smService.stop();
                    console.log('Auction State Machine stopped');
                }
            },
        };
    });

export interface BaseLiveAuctionStateModelType extends Instance<typeof BaseLiveAuctionStateModel> {}
