import {Instance, types} from 'mobx-state-tree';
import {PaymentMethodsGridViewModel} from './PaymentMethodsGridViewModel';
import {PaymentMethodTypeEnum} from '@joyrideautos/auction-core/src/dtos/PaymentsDto';
import {capitalize} from '@joyrideautos/auction-utils/src/stringUtils';

export const PaymentMethodsViewModel = types
    .model('PaymentMethodsViewModel', {
        paymentMethodsGridViewModel: types.optional(PaymentMethodsGridViewModel, {}),
    })
    .views((self) => {
        return {
            get paymentMethodTypes() {
                return [PaymentMethodTypeEnum.CARD, PaymentMethodTypeEnum.ACH];
            },
            get isAlertVisible() {
                return !!self.paymentMethodsGridViewModel.failedPaymentMethod;
            },
            get alertActionUrl() {
                return self.paymentMethodsGridViewModel.failedPaymentMethod?.setupActionUrl;
            },
            get alertMessage() {
                const brand = self.paymentMethodsGridViewModel.failedPaymentMethod?.paymentMethodName;
                const last4 = self.paymentMethodsGridViewModel.failedPaymentMethod?.last_4;
                return this.alertActionUrl
                    ? `Please complete the verification process by following the link below:`
                    : `${capitalize(brand)} (*${last4}) could not be added!`;
            },
            get alertDescription() {
                return self.paymentMethodsGridViewModel.failedPaymentMethod?.setupError;
            },
        };
    });

export interface PaymentMethodsViewModelType extends Instance<typeof PaymentMethodsViewModel> {}
