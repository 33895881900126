import {Firebase} from '../firebase/types';
import {UILogger} from '@joyrideautos/ui-logger/src/Logger';
import {AppConfig} from '../AppConfig';
import {Cancelable, makeCancelable} from '@joyrideautos/auction-utils/src/cancelable';
import {DatabaseErrorEnum} from '../firebase/Database';
import {FirestoreErrorEnum} from '../firebase/Firestore';

export abstract class BaseService {
    private cancelables = new Map<string, Cancelable<any>>();
    constructor(protected firebase: Firebase, protected logger: UILogger, protected config: AppConfig) {}

    get database() {
        return this.firebase.database;
    }

    get firestore() {
        return this.firebase.firestore;
    }

    get rpcService() {
        return this.firebase.rpcService;
    }

    get storage() {
        return this.firebase.storage;
    }

    get auth() {
        return this.firebase.auth;
    }

    makeCancelable<T>(id: string, delayInMs?: number) {
        let cancelable: Cancelable<T> | undefined = this.cancelables.get(id);
        if (!cancelable) {
            cancelable = makeCancelable<T>(id, delayInMs);
            this.cancelables.set(id, cancelable);
        }
        return cancelable;
    }

    logDatabaseError(message: string, error: any) {
        if (error.code !== DatabaseErrorEnum.PERMISSION_DENIED) {
            this.logger.error(message, error.message);
        }
    }

    logFirestoreError(message: string, error: any) {
        if (error.code !== FirestoreErrorEnum.PERMISSION_DENIED) {
            this.logger.error(message, error.message);
        }
    }
}
