import {StorageTaxCalculator} from './storageTaxCalculator/StorageTaxCalculator';
import {validateMoney} from './utils';
import {BuyerFeeCalculator, WinningBidFeesInMoney} from './types';
import {SalesTaxCalculator} from './SalesTaxCalculator';
import {AdminFeeCalculator} from './AdminFeeCalculator';
import {PerVehicleFee} from './PerVehicleFee';
import {Money} from '../rules/Money';
import {PlatformFeeCalculator} from './platformFeeCalculator/types';

export abstract class FeesBreakdownCalculator {
    constructor(
        protected buyerFeeCalculator: BuyerFeeCalculator,
        protected platformFeeCalculator: PlatformFeeCalculator,
        protected storageTaxCalculator: StorageTaxCalculator,
        protected salesTaxCalculator: SalesTaxCalculator,
        protected adminFeeCalculator: AdminFeeCalculator,
        protected perVehicleFee: PerVehicleFee,
        protected options: {
            isChargeBuyerFee?: boolean;
            minBidAmount?: Money;
            isExcludeSellerFeesFromSalesTax?: boolean;
        }
    ) {}

    setBuyerFeeCalculator(buyerFeeCalculator: BuyerFeeCalculator) {
        this.buyerFeeCalculator = buyerFeeCalculator;
    }

    abstract calculate(amount: Money): WinningBidFeesInMoney;

    protected validate(amountInDollars: Money) {
        validateMoney(amountInDollars, 'bidAmountInCent');
        this.adminFeeCalculator.validate();
        if (this.options.isChargeBuyerFee) {
            this.buyerFeeCalculator.validate(amountInDollars);
        }
    }
}
