import {OrderDto} from '@joyrideautos/auction-core/src/dtos/OrderDto';
import {Unsubscribe} from '../types';
import {BaseService} from './BaseService';

export class OrdersService extends BaseService {
    subscribeToOrder(orderKey: string, subscriber: (order: OrderDto | undefined) => void) {
        return this.firebase.firestore.subscribeToDocument<any>(
            this.firebase.firestore.documentRef(`/orders/${orderKey}`),
            (order: OrderDto) => {
                subscriber(order);
            }
        );
    }

    subscribeToUserOrders(
        uid: string | undefined,
        subscriber: (orders: Record<string, OrderDto>) => void
    ): Unsubscribe {
        const userId = uid ?? this.auth.currentUser?.uid;
        if (!userId) {
            return () => {};
        }
        return this.firestore.subscribeToCollection(
            this.firestore.collectionRef<OrderDto>('/orders').where('createdBy', '==', userId),
            (orders) => {
                subscriber(orders || {});
            }
        );
    }
}
