import {IDisposer, Instance} from 'mobx-state-tree';
import {isAnonymous} from '@joyrideautos/auction-core/src/rules/UserModel';
import BaseStore from './BaseStore';
import primitives from '../Primitives';
import {reaction} from 'mobx';

const buildVersion = process.env.REACT_APP_BITBUCKET_TAG || process.env.REACT_APP_BITBUCKET_BUILD_NUMBER || '-';

export const AppStore = BaseStore.named('AppStore')
    .props({
        releaseVersion: primitives.string(),
    })
    .volatile(() => ({
        disposers: [] as IDisposer[],
        releaseVersionDisposer: null as IDisposer | null,
        releaseVersionListener: null as ((version: string) => void) | null,
    }))
    .views((self) => ({
        get fullVersion() {
            return `${buildVersion} (${self.releaseVersion.value})`;
        },
    }))
    .actions((self) => {
        return {
            setReleaseVersionListener(listener: ((version: string) => void) | null) {
                self.releaseVersionListener = listener;
            },
            unsubscribe() {
                self.releaseVersionDisposer && self.releaseVersionDisposer();
                self.releaseVersionDisposer = null;
            },
            subscribe() {
                self.releaseVersionDisposer = self.releaseVersionService.subscribeToReleaseVersion((value) => {
                    const releaseVersion = self.rootStore.localStorageStore.get('releaseVersion');
                    self.releaseVersion.setValue(value);
                    if (releaseVersion == null) {
                        // initial value - don't notify
                        self.localStorageStore.set('releaseVersion', value);
                    } else if (releaseVersion !== value) {
                        // value changed - notify
                        self.localStorageStore.set('releaseVersion', value);
                        self.releaseVersionListener?.(value);
                    }
                });
            },
            afterCreate() {
                self.disposers.push(
                    reaction(
                        () => self.rootStore.authUserStore.userInfo,
                        (userInfo) => {
                            if (!isAnonymous(userInfo)) {
                                this.subscribe();
                            } else {
                                this.unsubscribe();
                            }
                        }
                    )
                );
            },
            beforeDestroy() {
                self.disposers.forEach((disposer) => disposer());
            },
        };
    });

export interface AppStoreType extends Instance<typeof AppStore> {}

export interface HasAppStore {
    appStore: AppStoreType;
}
